import ResourcesInPageBannerService from "./service/resources/ResourcesInPageBannerService"
import ResourcesCalloutService from "./service/resources/ResourcesCalloutService"
import ResourcesFooterCalloutService from "./service/resources/ResourcesFooterCalloutService"
import ResourcesBlogListService from "./service/resources/ResourcesBlogListService"
import BlogCategoryService from "./service/blog/BlogCategoryService"

export default class Resources {
    resourcesInPageBannerService: ResourcesInPageBannerService
    resourcesCalloutService: ResourcesCalloutService
    resourcesFooterCalloutService: ResourcesFooterCalloutService
    resourcesBlogListService: ResourcesBlogListService
    blogCategoryService: BlogCategoryService

    constructor() {
        this.resourcesInPageBannerService = new ResourcesInPageBannerService()
        this.resourcesCalloutService = new ResourcesCalloutService()
        this.resourcesFooterCalloutService = new ResourcesFooterCalloutService()
        this.resourcesBlogListService = new ResourcesBlogListService()
        this.blogCategoryService = new BlogCategoryService()
    }

    async getAllData() {
        const inPageBanner = await this.resourcesInPageBannerService.process()
        const callout = await this.resourcesCalloutService.process()
        const footerCallout = await this.resourcesFooterCalloutService.process()
        const blogList = await this.resourcesBlogListService.process()
        const blogCategory = await this.blogCategoryService.process()

        return {
            inPageBanner,
            callout,
            footerCallout,
            blogList,
            blogCategory,
        }
    }
}
