/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { trackRouter } from "vue-gtag-next"
import { createRouter, createWebHistory } from "vue-router"
import Home from "../views/Home.vue"
import Sample from "../views/Sample.vue"
import ComponentList from "../views/ComponentList.vue"
import Animation from "../views/Animation.vue"
import Faq from "../views/Faq.vue"
import Company from "../views/Company.vue"
import WhyPodiem from "../views/WhyPodiem.vue"
import PrivacyPolicy from "../views/PrivacyPolicy.vue"
import Cookies from "../views/Cookies.vue"
import TermsOfUse from "../views/TermsOfUse.vue"
import ContactUs from "../views/ContactUs.vue"
import RequestADemo from "../views/RequestADemo.vue"
import Feature from "../views/Feature.vue"
import Pricing from "../views/Pricing.vue"
import NotFound from "../views/NotFound.vue"
import FeatureDetail from "../views/FeatureDetail.vue"
import Resources from "../views/Resources.vue"
import Blog from "../views/Blog.vue"

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        { path: "/:pathMatch(.*)*", name: "NotFound", component: NotFound },
        {
            path: "/",
            name: "home",
            component: Home,
            meta: {
                title: "Podiem Website: The World's Best Sales Engagement Platform!",
            },
        },
        {
            path: "/company",
            name: "company",
            component: Company,
            meta: {
                title: "Company | Podiem Website",
            },
        },
        {
            path: "/sample",
            name: "sample",
            component: Sample,
        },
        {
            path: "/animate-sample",
            name: "animate_sample",
            component: Animation,
        },
        {
            path: "/component-list",
            name: "component_list",
            component: ComponentList,
        },
        {
            path: "/faq",
            name: "faq",
            component: Faq,
            meta: {
                title: "Frequently Asked Questions | Podiem Website",
            },
        },
        {
            path: "/why-podiem",
            name: "why_podiem",
            component: WhyPodiem,
            meta: {
                title: "Why us | Podiem Website",
            },
        },
        {
            path: "/privacy-policy",
            name: "privacy_policy",
            component: PrivacyPolicy,
            meta: {
                title: "Privacy Policy | Podiem Website",
            },
        },
        {
            path: "/cookies",
            name: "cookies",
            component: Cookies,
            meta: {
                title: "Cookies | Podiem Website",
            },
        },
        {
            path: "/terms-of-use",
            name: "terms_of_use",
            component: TermsOfUse,
            meta: {
                title: "Terms of Use | Podiem Website",
            },
        },
        {
            path: "/contact-us",
            name: "terms_us",
            component: ContactUs,
            meta: {
                title: "Contact Us | Podiem Website",
            },
        },
        {
            path: "/request-demo",
            name: "request_demo",
            component: RequestADemo,
            meta: {
                title: "Request A Demo | Podiem Website",
            },
        },
        {
            path: "/feature",
            name: "feature",
            component: Feature,
            meta: {
                title: "Feature | Podiem Website",
            },
        },
        {
            path: "/feature-detail/:id/:slug",
            name: "feature_detail",
            component: FeatureDetail, // FeatureDetail
            meta: {
                title: "Feature Specific | Podiem Website",
            },
        },
        {
            path: "/pricing",
            name: "pricing",
            component: Pricing,
            meta: {
                title: "Pricing | Podiem Website",
            },
        },
        {
            path: "/resources",
            name: "resources",
            component: Resources,
            meta: {
                title: "Resources | Podiem Website",
            },
        },
        {
            path: "/blog/:id/:slug",
            name: "blog",
            component: Blog, // FeatureDetail
            meta: {
                title: "Blog | Podiem Website",
            },
        },
    ],
})

router.beforeEach((to, from) => {
    document.title = to.meta.title ?? "Podiem Website"
})

trackRouter(router)

export default router
