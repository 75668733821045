<template>
  <div>
    <div class="short-border bg-primary-1"></div>
    <slot></slot>
  </div>
</template>
<script lang="ts">
import { toRefs } from "vue";

export default {
  name: "ShortBorder",
};
</script>
<style lang=""></style>
