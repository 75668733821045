<template>
  <!-- Mobile menu, show/hide based on menu state. -->
  <div class="lg:hidden" id="mobile-menu" v-if="mobileMenuIsOpen">
    <div class="px-2 pt-2 pb-3 space-y-1 shadow">
      <div v-for="(link, index) in navMenuItem" :key="index">
        <nav-item :linkText="link.name" :linkRedirection="link.url"></nav-item>
      </div>

      <div class="px-3 py-2">
        <button-default
          buttonText="Request a demo"
          redirection="/request-demo"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed } from "vue";
import { useStore } from "vuex";
import { NAV_MENU } from "@/js/BaseConstants";
import ButtonDefault from "@/components/atom/button/ButtonDefault.vue";
import NavItem from "@/components/atom/link/NavItem.vue";

export default {
  name: "NavMenuGroupMobile",
  components: {
    ButtonDefault,
    NavItem,
  },
  setup() {
    const navMenuItem = NAV_MENU;
    const store = useStore();

    const mobileMenuIsOpen = computed(
      () => store.getters["navigation/GET_navbarMobileMenuStatus"],
    );

    return {
      navMenuItem,
      mobileMenuIsOpen,
    };
  },
};
</script>
