import QueryBuilder from "../QueryBuilder"

export default class CtaRepository {
    constructor(protected contentName: string) {}

    async fetch() {
        const results = await new QueryBuilder()
            .buildQuery(this.contentName, [
                "cta_enabled",
                "cta_text",
                "cta_redirection",
            ])
            .process()

        return results.getResultInfo()
    }
}
