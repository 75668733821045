<template>
  <div
    class="absolute inset-y-0 right-0 flex items-center pr-2 lg:static lg:inset-auto lg:ml-6 lg:pr-0"
  >
    <div class="hidden lg:block lg:ml-6">
      <div class="flex items-center space-x-4">
        <div v-for="(link, index) in navMenuItem" :key="index">
          <nav-item
            :linkText="link.name"
            :linkRedirection="link.url"
          ></nav-item>
        </div>

        <div class="py-2">
          <button-default
            buttonText="Request a demo"
            redirection="/request-demo"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed } from "vue";
import { useStore } from "vuex";
import { NAV_MENU } from "@/js/BaseConstants";
import ButtonDefault from "@/components/atom/button/ButtonDefault.vue";
import LinkDefault from "@/components/atom/link/LinkDefault.vue";
import NavItem from "@/components/atom/link/NavItem.vue";

export default {
  name: "NavMenuGroup",
  components: {
    ButtonDefault,
    LinkDefault,
    NavItem,
  },
  setup() {
    const navMenuItem = NAV_MENU;
    const store = useStore();

    const isNavbarOpaque = computed(
      () => store.getters["navigation/GET_navbarOpaqueStatus"],
    );

    return {
      navMenuItem,
      isNavbarOpaque,
    };
  },
};
</script>

<style scoped>
.gap-x-40px {
  column-gap: 40px;
}
</style>
