<template>
  <div class="callout-block w-full rounded-call-out z-10">
    <div
      v-if="bannerText"
      class="flex flex-col gap-y-14 lg:flex-row xl:gap-x-20 lg:gap-x-10 items-center justify-center"
    >
      <img
        class="overflow-hidden w-full max-w-[435px] max-h-[435px] lg:min-w-[435px] lg:min-h-[435px]"
        :src="parseSanityImage(calloutImage)"
        :alt="calloutImage.alt"
      />

      <div class="flex flex-col">
        <heading-with-highlights
          :textLocation="textLocation"
          :text="bannerText"
          :highlight="textHighlight"
        />
        <div class="space-20px"></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { toRefs } from "vue";
import HeadingWithHighlights from "../atom/text/HeadingWithHighlights.vue";
import HeaderDescription from "../atom/text/HeaderDescription.vue";
import { parseSanityImage } from "@/js/composable/parseSanityImage";
import CalloutLoading from "../atom/loading/CalloutLoading.vue";

export default {
  name: "PlainCallOutBlock",
  components: {
    HeadingWithHighlights,
    HeaderDescription,
    CalloutLoading,
  },
  props: {
    bannerText: {
      type: String,
      default: "One workspace built by sales teams",
    },
    textHighlight: {
      type: String,
      default: "One workspace",
    },
    calloutImage: {
      type: Object,
      default: {
        _type: "image",
        asset: {
          _ref: "image-1bb3b2528b88185c6639c6469c888b67c58f4c7d-472x462-png",
          _type: "reference",
        },
      },
    },
    imageAlt: {
      type: String,
      default: "Podiem Website",
    },
    textLocation: {
      type: String,
      default: "home",
    },
  },
  async setup(props: any) {
    const {
      calloutText,
      calloutHighlight,
      calloutDescription,
      calloutImage,
      textLocation,
      imageAlt,
    } = toRefs(props);

    return {
      calloutText,
      calloutHighlight,
      calloutDescription,
      calloutImage,
      textLocation,
      imageAlt,
      parseSanityImage,
    };
  },
};
</script>

<style scoped>
.callout-block {
  width: 100%;
  padding: 60px 35px;
  /* box-shadow: 0px 12px 50px rgba(0, 0, 0, 0.12); */
}

@media only screen and (min-width: 1440px) {
  .callout-block {
    max-width: 1360px;
    margin: 0 auto;
    padding: 60px 100px;
  }
}
</style>
