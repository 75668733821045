import HomePageQuery from "../infrastructure/query/HomePageQuery"
import { HomePage } from "../types"
import hasData from "../domain/specification/hasData"

export default class Home {
    homePageQuery: HomePageQuery

    constructor() {
        this.homePageQuery = new HomePageQuery()
    }

    async getAllData() {
        const { data, totalCount } = await this.homePageQuery.fetch().then(items => items)

        if (!hasData(totalCount)) {
            throw new TypeError("No data was provided")
        }

        return data as HomePage
    }
}
