import WhyPodiemThreeColumnImage from "@/core/infrastructure/repository/why_podiem/WhyPodiemThreeColumnImage"
import hasData from "@/core/domain/specification/hasData"

export default class WhyPodiemThreeColumnImageService {
    whyPodiemThreeColumnImage: WhyPodiemThreeColumnImage

    constructor() {
        this.whyPodiemThreeColumnImage = new WhyPodiemThreeColumnImage()
    }

    async process() {
        const { data, totalCount } = await this.whyPodiemThreeColumnImage.fetch()

        if (!hasData(totalCount)) {
            throw new TypeError("No data was provided")
        }

        return data
    }
}
