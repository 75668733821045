import WhyPodiemTwoColumn from "@/core/infrastructure/repository/why_podiem/WhyPodiemTwoColumn"
import hasData from "@/core/domain/specification/hasData"

export default class WhyPodiemTwoColumnService {
    whyPodiemTwoColumn: WhyPodiemTwoColumn

    constructor() {
        this.whyPodiemTwoColumn = new WhyPodiemTwoColumn()
    }

    async process() {
        const { data, totalCount } = await this.whyPodiemTwoColumn.fetch()

        if (!hasData(totalCount)) {
            throw new TypeError("No data was provided")
        }

        return data
    }
}
