import QueryBuilder from "../QueryBuilder"

export default class TwoColumnRepository {
    constructor(protected contentName: string) {}

    async fetch() {
        const results = await new QueryBuilder()
            .buildQuery(this.contentName, ["bannerText", "twoColumnSection"])
            .process()

        return results.getResultInfo()
    }
}
