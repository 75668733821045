<template>
  <button @click="redirectLink(redirection, APP_URL)" class="button-outlined">
    {{ buttonText }}
  </button>
</template>

<script lang="ts">
import { toRefs } from "vue";
import { redirectLink } from "@/js/composable/redirectLink";

export default {
  name: "ButtonOutlinedBlack",
  props: {
    buttonText: {
      type: String,
      default: "Button",
    },
    buttonSize: {
      type: String,
      default: "",
    },
    redirection: {
      type: String,
      default: "#",
    },
  },
  setup(props: any) {
    const { redirection } = toRefs(props);

    return {
      APP_URL: import.meta.env.VITE_APP_URL,
      redirection,
      redirectLink,
    };
  },
};
</script>

<style scoped>
button:disabled {
  cursor: not-allowed;
}

.button-outlined {
  @apply rounded-full border border-black text-black hover:border-primary-1 hover:text-white hover:bg-primary-1 disabled:border-disabled-1 disabled:text-disabled-text-1;
}
</style>
