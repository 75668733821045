import ResourcesCallout from "@/core/infrastructure/repository/resources/ResourcesCallout"
import hasData from "@/core/domain/specification/hasData"

export default class ResourcesCalloutService {
    resourcesCallout: ResourcesCallout

    constructor() {
        this.resourcesCallout = new ResourcesCallout()
    }

    async process() {
        const { data, totalCount } = await this.resourcesCallout.fetch()

        if (!hasData(totalCount)) {
            throw new TypeError("No data was provided")
        }

        return data
    }
}
