import FeatureDetailRepository from "@/core/infrastructure/repository/FeatureDetailRepository"
import hasData from "@/core/domain/specification/hasData"
import isEmptyString from "../domain/specification/isEmptyString"

export default class FeatureDetail {
    protected slug = ""
    protected key = ""

    setSlug(slug: string): void {
        if (isEmptyString(slug)) {
            throw new TypeError("Slug has not been set")
        }
        this.slug = slug
    }

    setKey(key: string): void {
        if (isEmptyString(key)) {
            throw new TypeError("Slug has not been set")
        }
        this.key = key
    }

    getSlug(): string {
        return this.slug
    }

    getKey(): string {
        return this.key
    }

    async process() {
        const { data, totalCount } = await new FeatureDetailRepository(
            this.getKey(),
        ).fetch()

        if (!hasData(totalCount)) {
            throw new TypeError("No data was provided")
        }

        return data
    }
}
