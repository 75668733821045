<template>
  <div>
    <div v-if="data">
      <div
        v-for="(item, key) in data"
        :key="key"
        class="bg-transparent dark:bg-gray-900 text-gray-900 dark:text-white"
      >
        <div
          @click="openList(key)"
          class="flex justify-between items-center py-3 w-full font-medium text-left rounded-t-xl border-b border-gray-200 dark:border-gray-700 dark:text-white"
        >
          <span
            class="text-gray-800 apercu-medium apercu-light text-custom-sm"
            :class="{ 'text-gray-900 apercu-medium': key === activeItem }"
          >
            {{ item.faqQuestion }}
          </span>

          <svg
            v-if="key === activeItem"
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 text-primary-1"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path stroke-linecap="round" stroke-linejoin="round" d="M18 12H6" />
          </svg>

          <svg
            v-else
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 text-primary-1"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 6v6m0 0v6m0-6h6m-6 0H6"
            />
          </svg>
        </div>

        <div
          v-show="key === activeItem"
          class="py-5 border-b border-gray-200 dark:border-gray-700"
        >
          <p class="mb-2 text-gray-600 dark:text-gray-400 text-custom-xs prose">
            <sanity-blocks :blocks="item.faqAnswer"></sanity-blocks>
          </p>
        </div>
      </div>
    </div>

    <div v-else>
      <h2
        class="flex flex-col justify-center items-center text-heading-xs uppercase tracking-widest text-gray-600"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-10 h-10"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
          />
        </svg>

        {{ errorMessage }}
      </h2>
    </div>
  </div>
</template>

<script lang="ts">
import { ref, toRefs } from "vue";
import { SanityBlocks } from "sanity-blocks-vue-component";

interface ComponentModel {
  data: object[];
}

export default {
  name: "Accordion",
  props: {
    data: {
      type: Object,
      default: [
        {
          _key: "81082dd5996d",
          _type: "faqList",
          faqAnswer: [
            {
              _key: "70f043023fd4",
              _type: "block",
              children: [
                {
                  _key: "eef11e2235510",
                  _type: "span",
                  marks: [],
                  text: "Podiem pricing is per user, per month. There are three different pricing subscriptions available depending on the features required by your business.",
                },
              ],
              markDefs: [],
              style: "normal",
            },
          ],
          faqQuestion: "How does pricing work in Podiem??",
        },
        {
          _key: "6922aa5e1a8a",
          _type: "faqList",
          faqAnswer: [
            {
              _key: "52ef47d30dd7",
              _type: "block",
              children: [
                {
                  _key: "aa43e3b3ea020",
                  _type: "span",
                  marks: [],
                  text: "No, we do not charge more for integrations.",
                },
              ],
              markDefs: [],
              style: "normal",
            },
          ],
          faqQuestion: " Is there an additional cost for integrations? ",
        },
      ],
    },
    errorMessage: {
      type: String,
      default: "No data found",
    },
  },
  components: {
    SanityBlocks,
  },
  setup(props: any) {
    const { data, errorMessage } = toRefs(props);
    const activeItem = ref(null);

    const openList = (key: any) => {
      if (key === activeItem.value) {
        activeItem.value = null;
      } else {
        activeItem.value = key;
      }
    };

    return {
      openList,
      activeItem,
      data,
      errorMessage,
    };
  },
};
</script>

<style></style>
