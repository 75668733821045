<template>
  <div v-if="homeData">
    <section class="min-h-[70vh] bg-primary-1">
      <div class="hero-image overflow-hidden">
        <suspense>
          <template #default>
            <div>
              <template v-if="homeData.homeBannerExecutive && activeTab === EXECUTIVE">
                <HeroBannerText
                  :bannerText="homeData.homeBannerExecutive.headline"
                  :textHighlight="homeData.homeBannerExecutive.highlightedText"
                  :subHeaderText="homeData.homeBannerExecutive.description"
                  :ctaText="homeData.homeBannerExecutive.cta.title"
                  :ctaRedirection="homeData.homeBannerExecutive.cta.internalLink"
                  :image="homeData.homeBannerExecutive.image"
                />
              </template>

              <template v-if="homeData.homeBannerSales && activeTab === SALES">
                <HeroBannerText
                  :bannerText="homeData.homeBannerSales.headline"
                  :textHighlight="homeData.homeBannerSales.highlightedText"
                  :subHeaderText="homeData.homeBannerSales.description"
                  :ctaText="homeData.homeBannerSales.cta.title"
                  :ctaRedirection="homeData.homeBannerSales.cta.internalLink"
                  :image="homeData.homeBannerSales.image"
                />
              </template>

              <template v-if="homeData.homeBannerXdr && activeTab === XDR">
                <HeroBannerText
                  :bannerText="homeData.homeBannerXdr.headline"
                  :textHighlight="homeData.homeBannerXdr.highlightedText"
                  :subHeaderText="homeData.homeBannerXdr.description"
                  :ctaText="homeData.homeBannerXdr.cta.title"
                  :ctaRedirection="homeData.homeBannerXdr.cta.internalLink"
                  :image="homeData.homeBannerXdr.image"
                />
              </template>
            </div> 
          </template>

          <template #fallback>
            <div>Loading...</div>
          </template>
        </suspense>
      </div>
    </section>



    <section
      class="bg-gradient-to-b from-primary-1 to-white via-primary-2 via-primary-3 pt-32"
    >
      <div class="section-container">
        <suspense>
          <div>
            <div class="mb-[50px]">
              <h2 class="text-heading-sm mb-[20px] apercu-bold lg:text-center">
                {{ homeData.featurePreamble }}
              </h2>
              <p
                class="text-white text-[38px] apercu-bold leading-tight lg:text-center"
              >
                {{ homeData.featureHeadline }}
              </p>
            </div>
            <FeatureTab />
          </div>
        </suspense>
      </div>
    </section>
    
    <template
      v-if="homeData.componentBlockExecutive && activeTab === EXECUTIVE"
    >
      <ComponentBlockBuilder :blocks="homeData.componentBlockExecutive" />
    </template>

    <template v-if="homeData.componentBlockSales && activeTab === SALES">
      <ComponentBlockBuilder :blocks="homeData.componentBlockSales" />
    </template>

    <template v-if="homeData.componentBlockXdr && activeTab === XDR">
      <ComponentBlockBuilder :blocks="homeData.componentBlockXdr" />
    </template>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { useStore } from "vuex";
import Home from "@/core/application/Home";
import { HomePage } from "@/core/types";
import HeroBannerText from "@/components/molecule/home/HeroBannerText.vue";
import FeatureTab from "@/components/molecule/FeatureTab.vue";
import ComponentBlockBuilder from "@/components/page-builder/ComponentBlockBuilder.vue";
import { XDR, SALES, EXECUTIVE } from "@/core/Constants";

const homeData = ref<HomePage | null>(null);

const home = new Home();

const loadData = async () => {
  try {
    homeData.value = await home.getAllData();
  } catch (err) {
    console.error("Failed to load data", err);
  }
};

const store = useStore();

const activeTab = computed(
  () => store.getters["features/GET_activeFeatureTab"],
);

loadData();
</script>

<style scoped></style>
