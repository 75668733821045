import QueryBuilder from "../QueryBuilder"

export default class SubscriptionCardRepository {
    constructor(protected contentName: string) {}

    async fetch() {
        const results = await new QueryBuilder()
            .rawQuery(
                `
                *[_id == '${this.contentName}'][0] {
                    cardText,
                    subscription,
                    userCount,
                    'ctaDetails': {
                        cta_enabled,
                        cta_text,
                        cta_redirection
                    },
                    'services': serviceItems
                }
            `,
            )
            .process()

        return results.getResultInfo()
    }
}
