import FeatureFooterCallout from "@/core/infrastructure/repository/feature/FeatureFooterCallout"
import hasData from "@/core/domain/specification/hasData"

export default class FeatureFooterCalloutService {
    protected featureFooterCallout: FeatureFooterCallout

    constructor() {
        this.featureFooterCallout = new FeatureFooterCallout()
    }

    async process() {
        const { data, totalCount } = await this.featureFooterCallout.fetch()

        if (!hasData(totalCount)) {
            throw new TypeError("No data was provided")
        }

        return data
    }
}
