<template>
  <div>
    <div v-if="bannerText" class="flex flex-col carousel-container">
      <div class="flex flex-col justify-center mr-[50px]">
        <heading-with-highlights
          class="mb-[30px]"
          textLocation="podiem"
          :text="bannerText"
          :highlight="textHighlight"
        />
        <template v-if="description">
          <p
            class="text-heading-sm text-white"
            v-for="(value, id) in description"
            :key="id"
          >
            <sanity-blocks :blocks="[value]"></sanity-blocks>
          </p>
        </template>
      </div>

      <carousel-item :items="carouselItems" />
    </div>
    <div v-else>
      <spinner />
    </div>
  </div>
</template>
<script lang="ts">
import { toRefs } from "vue";
import HeadingWithHighlights from "@/components/atom/text/HeadingWithHighlights.vue";
import CarouselItem from "@/components/molecule/CarouselItem.vue";
import { SanityBlocks } from "sanity-blocks-vue-component";
import Spinner from "@/components/atom/loading/Spinner.vue";

export default {
  name: "Carousel",
  components: {
    HeadingWithHighlights,
    CarouselItem,
    SanityBlocks,
    Spinner,
  },
  props: {
    bannerText: {
      type: String,
      default: "Platform for everyone",
    },
    textHighlight: {
      type: String,
      default: "everyone",
    },
    description: {
      type: Object,
      default: {},
    },
    carouselItems: {
      type: Object,
      default: {
        carousel_items: {
          contentTitle: "Sample Title",
          imagePanel: {
            _type: "image",
            asset: {
              _ref: "image-6549ea3fbfd80783dc7143a6c777645fb829dfd0-8429x4832-jpg",
              _type: "reference",
            },
          },
          simpleBlockContent: [
            {
              _key: "9b546cf28ce0",
              _type: "block",
              children: [
                {
                  _key: "b9109a6ae94a0",
                  _type: "span",
                  marks: [],
                  text: "Test Simple insights on daily activities, setting individual targets, finding gaps. Podiem automates the disruptive aspects of sales activities and allows you to track and measure individual or team performance. ",
                },
              ],
              markDefs: [],
              style: "normal",
            },
          ],
        },
        carousel_items2: {
          contentTitle: "Sample Title 2",
          imagePanel: {
            _type: "image",
            asset: {
              _ref: "image-6549ea3fbfd80783dc7143a6c777645fb829dfd0-8429x4832-jpg",
              _type: "reference",
            },
          },
          simpleBlockContent: [
            {
              _key: "9b546cf28ce0",
              _type: "block",
              children: [
                {
                  _key: "b9109a6ae94a0",
                  _type: "span",
                  marks: [],
                  text: "Test Simple insights on daily activities, setting individual targets, finding gaps. Podiem automates the disruptive aspects of sales activities and allows you to track and measure individual or team performance. ",
                },
              ],
              markDefs: [],
              style: "normal",
            },
          ],
        },
        carousel_items3: {
          contentTitle: "Sample Title 3",
          imagePanel: {
            _type: "image",
            asset: {
              _ref: "image-6549ea3fbfd80783dc7143a6c777645fb829dfd0-8429x4832-jpg",
              _type: "reference",
            },
          },
          simpleBlockContent: [
            {
              _key: "9b546cf28ce0",
              _type: "block",
              children: [
                {
                  _key: "b9109a6ae94a0",
                  _type: "span",
                  marks: [],
                  text: "Test Simple insights on daily activities, setting individual targets, finding gaps. Podiem automates the disruptive aspects of sales activities and allows you to track and measure individual or team performance. ",
                },
              ],
              markDefs: [],
              style: "normal",
            },
          ],
        },
      },
    },
  },
  setup(props: any) {
    const { bannerText, textHighlight, description, carouselItems } =
      toRefs(props);

    return {
      bannerText,
      textHighlight,
      description,
      carouselItems,
    };
  },
};
</script>
