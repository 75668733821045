import QueryBuilderResult from "../model/QueryBuilderResult"
import QueryBuilder from "../QueryBuilder"
import { componentBlockSchema } from "./fragments/componentBlockSchema"
import { ctaLink } from "./fragments/cta"

export default class HomePageQuery {
    async fetch() {
        const results = await new QueryBuilder()
            .rawQuery(`*[_type == 'homePage'][0] {
          'homeBannerExecutive': homeBannerExecutive {
            'headline': title.headline,
            'highlightedText': title.highlightedText,
            'description': description,
            'image': image,
            'cta': ${ctaLink}
          },
          'homeBannerSales': homeBannerSales {
            'headline': title.headline,
            'highlightedText': title.highlightedText,
            'description': description,
            'image': image,
            'cta': ${ctaLink}
          },
          'homeBannerXdr': homeBannerXdr {
            'headline': title.headline,
            'highlightedText': title.highlightedText,
            'description': description,
            'image': image,
            'cta': ${ctaLink}
          },
          featurePreamble,
          featureHeadline,
          'cta': homeBanner.ctaLink-> {
            title,
            linkType,
            internalLink,
            externalLink,
          },
          componentBlockXdr[]-> {
            componentSchema,
            "block": ${componentBlockSchema}
          },
          componentBlockSales[]-> {
            componentSchema,
            "block": ${componentBlockSchema}
          },
          componentBlockExecutive[]-> {
            componentSchema,
            "block": ${componentBlockSchema}
          }
        }`)
            .process()

        return results.getResultInfo()
    }
}
