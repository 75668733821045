import PricingSimpleBanner from "@/core/infrastructure/repository/pricing/PricingSimpleBanner"
import hasData from "@/core/domain/specification/hasData"

export default class PricingBannerService {
    pricingSimpleBanner: PricingSimpleBanner

    constructor() {
        this.pricingSimpleBanner = new PricingSimpleBanner()
    }

    async process() {
        const { data, totalCount } = await this.pricingSimpleBanner.fetch()

        if (!hasData(totalCount)) {
            throw new TypeError("No data was provided")
        }

        return data
    }
}
