import QueryBuilder from "../QueryBuilder"

export default class TwoColumnPlainRepository {
    constructor(protected contentName: string) {}

    async fetch() {
        const results = await new QueryBuilder()
            .buildQuery(this.contentName, [
                "bannerText",
                "'twoColumnSection': { twoColumnSection1, twoColumnSection2 }",
            ])
            .process()

        return results.getResultInfo()
    }
}
