<template>
  <h3 class="text-black apercu-medium">
    {{ columnHeader }}
  </h3>
</template>

<script lang="ts">
import { toRefs } from "vue";

export default {
  props: {
    columnHeader: {
      type: String,
      default: "Sample Column Header",
    },
  },
  setup(props: any) {
    const { columnHeader } = toRefs(props);

    return {
      columnHeader,
    };
  },
};
</script>

<style scoped>
h3 {
  font-size: 20px;
}
</style>
