<template>
  <div
    v-if="bannerText"
    class="callout-block-footer bg-gradient-to-r from-primary-1 to-primary-3 w-full rounded-call-out"
    v-motion-fade
  >
    <div
      class="flex flex-col gap-y-14 lg:flex-row xl:gap-x-20 lg:gap-x-10 items-center justify-center"
    >
      <template v-if="bannerImage">
        <div
          class="lg:order-last overflow-hidden w-full max-w-[435px] max-h-[435px] lg:min-w-[435px] lg:min-h-[435px] flex items-center"
        >
          <img
            width="640"
            height="360"
            class="w-full h-full"
            :src="parseSanityImage(bannerImage)"
            :alt="bannerImage.alt"
          />
        </div>
      </template>

      <div class="flex flex-col">
        <heading-with-highlights
          textLocation="footer"
          :text="bannerText"
          :highlight="bannerTextHighlight"
        />
        <div class="space-20px"></div>
        <template v-if="ctaDetails.cta_enabled">
          <button-default
            :buttonText="ctaDetails.cta_text"
            :redirection="ctaDetails.cta_redirection"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { toRefs } from "vue";
import { useStore } from "vuex";
import { parseSanityImage } from "@/js/composable/parseSanityImage";
import HeadingWithHighlights from "../atom/text/HeadingWithHighlights.vue";
import HeaderDescription from "../atom/text/HeaderDescription.vue";
import ButtonDefault from "../atom/button/ButtonDefault.vue";
import FooterCalloutLoading from "../atom/loading/FooterCalloutLoading.vue";

export default {
  name: "FooterCallOutBlock",
  components: {
    HeadingWithHighlights,
    HeaderDescription,
    ButtonDefault,
    FooterCalloutLoading,
  },
  props: {
    bannerText: {
      type: String,
      default: "Get ready to connect with more prospects",
    },
    bannerTextHighlight: {
      type: String,
      default: "more prospects",
    },
    bannerImage: {
      type: Object,
      default: {
        _type: "image",
        asset: {
          _ref: "image-1bb3b2528b88185c6639c6469c888b67c58f4c7d-472x462-png",
          _type: "reference",
        },
      },
    },
    ctaDetails: {
      type: Object,
      default: {
        cta_enabled: true,
        cta_text: "Get started",
        cta_redirection: "redirection",
      },
    },
  },
  setup(props: any) {
    const store = useStore();
    const { bannerText, bannerTextHighlight, bannerImage, ctaDetails } =
      toRefs(props);

    return {
      bannerText,
      bannerTextHighlight,
      bannerImage,
      ctaDetails,
      parseSanityImage,
    };
  },
};
</script>

<style>
.callout-block-footer {
  width: 100%;
  padding: 60px 35px;
}

@media only screen and (min-width: 1440px) {
  .callout-block-footer {
    max-width: 1360px;
    margin: 0 auto;
    padding: 60px 100px;
  }
}
</style>
