import DemoFooterCallout from "@/core/infrastructure/repository/demo/DemoFooterCallout"
import hasData from "@/core/domain/specification/hasData"

export default class DemoFooterCalloutService {
    protected demoFooterCallout: DemoFooterCallout

    constructor() {
        this.demoFooterCallout = new DemoFooterCallout()
    }

    async process() {
        const { data, totalCount } = await this.demoFooterCallout.fetch()

        if (!hasData(totalCount)) {
            throw new TypeError("No data was provided")
        }

        return data
    }
}
