import FeatureCallout from "@/core/infrastructure/repository/feature/FeatureCallout"
import hasData from "@/core/domain/specification/hasData"

export default class FeatureCalloutService {
    protected featureCallout: FeatureCallout

    constructor() {
        this.featureCallout = new FeatureCallout()
    }

    async process() {
        const { data, totalCount } = await this.featureCallout.fetch()

        if (!hasData(totalCount)) {
            throw new TypeError("No data was provided")
        }

        return data
    }
}
