// import HomeCallout from "@/core/infrastructure/repository/home/HomeCallout"
import CompanyCallout from "@/core/infrastructure/repository/company/CompanyCallout"
import hasData from "@/core/domain/specification/hasData"

export default class CompanyCalloutService {
    companyCallout: CompanyCallout

    constructor() {
        this.companyCallout = new CompanyCallout()
    }

    async process() {
        const { data, totalCount } = await this.companyCallout.fetch()

        if (!hasData(totalCount)) {
            throw new TypeError("No data was provided")
        }

        return data
    }
}
