import ResourcesBlogList from "@/core/infrastructure/repository/resources/ResourcesBlogList"
import hasData from "@/core/domain/specification/hasData"

export default class ResourcesBlogListService {
    resourcesBlogList: ResourcesBlogList

    constructor() {
        this.resourcesBlogList = new ResourcesBlogList()
    }

    async process() {
        const { data, totalCount } = await this.resourcesBlogList.fetch()

        if (!hasData(totalCount)) {
            throw new TypeError("No data was provided")
        }

        return data
    }
}
