import QueryBuilder from "../QueryBuilder"

export default class FeatureDetailRepository {
    static contentName = "iconTable"
    constructor(protected key: string) {}

    async fetch() {
        const results = await new QueryBuilder()
            .rawQuery(
                `*[_type == 'iconTable'][0] {
                ...iconList[${this.key}]
              }`,
            )
            .process()

        return results.getResultInfo()
    }
}
