<template>
  <a
    :href="linkRedirection"
    :class="[{ 'disabled-link': isDisabled }, additionalStyle]"
    class="w-fit hover:text-primary-2 disabled:text-disabled-text-1 dark:text-white"
  >
    {{ linkText }}
  </a>
</template>

<script lang="ts">
export default {
  name: "LinkDefault",
  props: {
    linkText: {
      type: String,
    },
    linkRedirection: {
      type: String,
      default: "javascript:void(0)",
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    forNav: {
      type: Boolean,
      default: false,
    },
  },
  setup(props: any) {
    const isForNav = props.forNav;
    const additionalStyle = isForNav ? "apercu-light" : "text-primary-1";

    return {
      isForNav,
      additionalStyle,
    };
  },
};
</script>

<style scoped>
a.disabled-link {
  color: #939597;
  cursor: not-allowed;
  pointer-events: none;
}
</style>
