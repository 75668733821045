import PricingBannerService from "./service/pricing/PricingBannerService"
import PricingSubscriptionService from "./service/pricing/PricingSubscriptionService"
import PricingCtaService from "./service/pricing/PricingCtaService"
import PricingThreeColumnService from "./service/pricing/PricingThreeColumnService"
import FaqItemService from "./service/faq/FaqItemService"
import PricingTestimonialService from "./service/pricing/PricingTestimonialService"
import PricingCalloutService from "./service/pricing/PricingCalloutService"

export default class Pricing {
    pricingBannerService: PricingBannerService
    subscriptionService: PricingSubscriptionService
    pricingCtaService: PricingCtaService
    pricingThreeColumnService: PricingThreeColumnService
    faqItem: FaqItemService
    pricingTestimonialService: PricingTestimonialService
    pricingCalloutService: PricingCalloutService

    constructor() {
        this.pricingBannerService = new PricingBannerService()
        this.subscriptionService = new PricingSubscriptionService()
        this.pricingCtaService = new PricingCtaService()
        this.pricingThreeColumnService = new PricingThreeColumnService()
        this.faqItem = new FaqItemService()
        this.pricingTestimonialService = new PricingTestimonialService()
        this.pricingCalloutService = new PricingCalloutService()
    }

    async getAllData() {
        const inPageBanner = await this.pricingBannerService.process()
        const subscription = await this.subscriptionService.process()
        const ctaDetails = await this.pricingCtaService.process()
        const pricingThreeColumnService =
      await this.pricingThreeColumnService.process()
        const faqItem = await this.faqItem.process()
        const pricingTestimonialService =
      await this.pricingTestimonialService.process()
        const pricingCalloutService = await this.pricingCalloutService.process()

        return {
            inPageBanner,
            subscription,
            ctaDetails,
            threeColumn: pricingThreeColumnService,
            faqItem,
            testimonial: pricingTestimonialService,
            callout: pricingCalloutService,
        }
    }
}
