/* eslint-disable @typescript-eslint/no-unsafe-call */
const state = () => ({
  category: Array<object[]>,
  newCategory: {},
  selectedCategory: "all",
});

const actions = {
  setCategory({ commit }: any, data: object) {
    commit("SET_CATEGORY", data);
  },
  addCategory({ commit }: any, data: object) {
    commit("ADD_CATEGORY", data);
  },
  setSelectedCategory({ commit }: any, data: object) {
    commit("SET_SELECTED_CATEGORY", data);
  },
};

const mutations = {
  SET_CATEGORY(state: { category: object }, payload: object) {
    state.category = payload;
  },
  ADD_CATEGORY(state: { category: [] }, payload: any) {
    state.category.push(payload);
  },
  SET_SELECTED_CATEGORY(state: { selectedCategory: string }, payload: string) {
    state.selectedCategory = payload;
  },
};

const getters = {
  GET_category: (state: { category: object }) => state.category,
  GET_selectedCategory: (state: { selectedCategory: string }) =>
    state.selectedCategory,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
