<template>
  <div class="mb-4">
    <div
      class="w-full flex justify-center items-center mb-[20px] md:mb-[100px]"
    >
      <ul class="flex flex-wrap -mb-px" role="tablist">
        <li
          v-for="(value, key) in tabItems"
          class="mr-2"
          role="presentation"
          :key="key"
        >
          <a
            class="inline-block cursor-pointer border-b-2 hover:text-primary-1 hover:border-primary-1 rounded-t-lg py-4 px-4 text-custom-sm font-medium text-center"
            :class="{
              'text-primary-1 border-b-2 border-primary-1': key === activeMenu,
            }"
            @click="switchTab(key)"
          >
            {{ value.name }}
          </a>
        </li>
      </ul>
    </div>

    <div
      class="tab-content w-full md:px-10 lg:px-[30px]"
      v-for="(value, key) in tabItems"
      :key="key"
      :class="[{ hidden: key !== activeMenu }, { block: key === activeMenu }]"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { ref, PropType, computed } from "vue";
import { useStore } from "vuex";
import { TAB_ITEMS } from "../../js/BaseConstants";

interface ComponentModel {
  activeMenu: number;
  items: Array<object>;
}

export default {
  props: {
    activeMenu: {
      type: Number as unknown as PropType<ComponentModel>,
      default: 0,
    },
    items: {
      type: Object as PropType<ComponentModel>,
      default: [],
    },
  },
  setup(props: ComponentModel) {
    const store = useStore();
    const tabItems = props.items;

    const switchTab = (key: number) => {
      const tabInfo = TAB_ITEMS[key];
      store.dispatch("faq/setActiveTabInfo", {
        tabIndex: key,
        schemaId: tabInfo.schema_id,
      });
    };

    return {
      tabItems,
      activeMenu: computed(() => store.getters["faq/GET_activeTab"]),
      switchTab,
    };
  },
};
</script>

<style>
.tab-content a {
  text-decoration: underline;
  color: #008afe;
}
</style>
