<template>
  <button
    @click="toggleMobileNav()"
    type="button"
    class="inline-flex items-center justify-center p-2 rounded-md text-gray-400"
    aria-controls="mobile-menu"
    aria-expanded="false"
  >
    <span class="sr-only">Open main menu</span>

    <!-- Menu Icon -->
    <svg
      v-if="!mobileMenuIsOpen"
      class="block h-6 w-6"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="2"
      stroke="currentColor"
      aria-hidden="true"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M4 6h16M4 12h16M4 18h16"
      />
    </svg>

    <!-- Close icon -->
    <svg
      v-else
      class="h-6 w-6"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="2"
      stroke="currentColor"
      aria-hidden="true"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M6 18L18 6M6 6l12 12"
      />
    </svg>
  </button>
</template>

<script lang="ts">
import { computed } from "vue";
import { useStore } from "vuex";
export default {
  name: "NavMobileIcon",
  setup() {
    const store = useStore();
    const mobileMenuIsOpen = computed(
      () => store.getters["navigation/GET_navbarMobileMenuStatus"],
    );

    const toggleMobileNav = () => {
      store.dispatch(
        "navigation/toggleMobileMenuStatus",
        !mobileMenuIsOpen.value,
      );
    };

    return {
      mobileMenuIsOpen,
      toggleMobileNav,
    };
  },
};
</script>

<style></style>
