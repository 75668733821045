<template>
  <div class="mt-[80px] lg:mt-0 lg:h-[745px]" v-motion-fade>
    <canvas
      ref="confettiContainer"
      :width="windowWidth"
      height="1800"
      class="z-0"
    ></canvas>
    <div class="z-1 -mt-[1700px]">
      <div v-if="data && data.headline" class="home-carousel-container">
        <div class="lg:h-full flex items-center lg:mr-[40px]">
          <HeadingWithHighlights
            textLocation="home"
            :text="data.headline"
            :highlight="data.highlightedText"
          />
        </div>

        <HomeCarouselItem :items="data.statistic" />
      </div>
      <div v-else class="w-full">
        <Spinner />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, getCurrentInstance, nextTick } from "vue";
import HeadingWithHighlights from "@/components/atom/text/HeadingWithHighlights.vue";
import HomeCarouselItem from "@/components/molecule/home/HomeCarouselItem.vue";
import Spinner from "@/components/atom/loading/Spinner.vue";

import { type StatisticBlockType } from '@/core/types';

const props = defineProps<{
  data: StatisticBlockType;
}>();

const confettiContainer = ref<null | HTMLCanvasElement>(null);
const windowWidth = window.innerWidth;

onMounted(async () => {
  const app = getCurrentInstance();

  await nextTick();
  app?.appContext.config.globalProperties.$confetti.start({
    particles: [
      {
        type: "rect",
        colors: ["#008AFE", "#5CDDD4", "#9EEDE3", "#F6F9FC", "#444444"],
        dropRate: 5,
      },
    ],
    particlesPerFrame: 0.25,
    canvasElement: confettiContainer.value,
  });
});
</script>