<template>
  <div
    class="w-full h-screen px-5 flex place-items-center place-content-center bg-gradient-to-r from-primary-1 via-primary-2 to-primary-3 animate-gradient-xy"
  >
    <div
      class="flex flex-col justify-center items-center gap-y-10"
      v-motion
      :initial="{ opacity: 0 }"
      :enter="{ opacity: 1 }"
      :delay="500"
    >
      <podiem-text-icon />

      <div class="flex flex-col justify-center items-center text-white">
        <h1 class="text-6xl lg:text-[6rem] mb-2 leading-none font-bold">
          Ooops!
        </h1>
        <p class="text-lg apercu-thin text-center lg:text-xl">
          The page you are looking for is currently unavailable.
        </p>
      </div>

      <button-default buttonText="Go to Home page" redirection="/" />
    </div>
  </div>
</template>
<script lang="ts">
import { useStore } from "vuex";
import PodiemTextIcon from "@/components/icons/PodiemTextIcon.vue";
import ButtonDefault from "@/components/atom/button/ButtonDefault.vue";

export default {
  name: "NotFound",
  components: {
    PodiemTextIcon,
    ButtonDefault,
  },
  setup() {
    const store = useStore();
    store.dispatch("navigation/toggleNavbarVisibilityStatus", false);
    store.dispatch("navigation/toggleFooterVisibilityStatus", false);
  },
};
</script>
<style scoped>
.background-animate {
  background-size: 400%;

  -webkit-animation: AnimationName 10s ease infinite;
  -moz-animation: AnimationName 10s ease infinite;
  animation: AnimationName 10s ease infinite;
}

@keyframes AnimationName {
  0%,
  100% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}
</style>
