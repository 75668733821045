import OurStoryTwoColumnRepository from "@/core/infrastructure/repository/company/OurStoryTwoColumnRepository"
import hasData from "@/core/domain/specification/hasData"

export default class OurStoryTwoColumnService {
    ourStoryTwoColumnRepository: OurStoryTwoColumnRepository

    constructor() {
        this.ourStoryTwoColumnRepository = new OurStoryTwoColumnRepository()
    }

    async process() {
        const { data, totalCount } = await this.ourStoryTwoColumnRepository.fetch()

        if (!hasData(totalCount)) {
            throw new TypeError("No data was provided")
        }

        return data
    }
}
