import { createStore } from "vuex";
import sanity from "../../client";

const state = () => ({
  posts: [],
  user_info: null,
});

const mutations = {
  SET_POSTS(state: { posts: [] }, posts: []) {
    state.posts = posts;
  },
};

const actions = {
  setPosts({ commit }, payload) {
    commit("SET_POSTS", payload);
  },
};

const getters = {
  posts: (state) => state.posts,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
