<template>
  <div v-motion-fade>
    <div v-if="bannerLabel" class="three-column-section w-full">
      <small-heading :text="bannerLabel"></small-heading>
      <div class="space-20px"></div>

      <div class="w-full mb-10">
        <heading-with-highlights
          :text="bannerText"
          :highlight="textHighlight"
        />
      </div>

      <div class="flex flex-col lg:flex-row lg:gap-x-10 gap-y-10 mb-10">
        <div v-for="(item, id) in columnItems" :key="id" class="flex-1">
          <div class="short-border bg-primary-1"></div>
          <column-heading
            :columnHeader="item.contentTitle"
            class="mb-[20px]"
          ></column-heading>
          <column-content>
            {{ item.contentText }}
          </column-content>
        </div>
      </div>

      <template v-if="ctaDetails.cta_enabled">
        <button-outlined
          :buttonText="ctaDetails.cta_text"
          :redirection="ctaDetails.cta_redirection"
        />
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { toRefs } from "vue";
import SmallHeading from "../atom/text/SmallHeading.vue";
import HeadingWithHighlights from "../atom/text/HeadingWithHighlights.vue";
import ColumnHeading from "../atom/text/ColumnHeading.vue";
import ColumnContent from "../atom/text/ColumnContent.vue";
import ButtonOutlined from "../atom/button/ButtonOutlined.vue";
import FullTextLoading from "../atom/loading/FullTextLoading.vue";

export default {
  name: "ThreeColumn",
  components: {
    SmallHeading,
    HeadingWithHighlights,
    ColumnHeading,
    ColumnContent,
    ButtonOutlined,
    FullTextLoading,
  },
  props: {
    bannerLabel: {
      type: String,
      default: "Sample Label",
    },
    bannerText: {
      type: String,
      default: "Boost sales efficiency and empower your inside sales teams",
    },
    textHighlight: {
      type: String,
      default: "empower",
    },
    columnItems: {
      type: Object,
      default: [
        {
          contentTitle:
            "Build excitement for selling using gamification features",
          contentText:
            "Uplift productivity and boost morale with friendly competitions to keep inside sales teams hitting their KPIs.",
        },
        {
          contentTitle:
            "Build excitement for selling using gamification features",
          contentText:
            "Uplift productivity and boost morale with friendly competitions to keep inside sales teams hitting their KPIs.",
        },
        {
          contentTitle:
            "Build excitement for selling using gamification features",
          contentText:
            "Uplift productivity and boost morale with friendly competitions to keep inside sales teams hitting their KPIs.",
        },
      ],
    },
    ctaDetails: {
      type: Object,
      default: {
        cta_enabled: true,
        cta_redirection: "#",
        cta_text: "Why Podiem for your business",
      },
    },
  },
  async setup(props: any) {
    const { bannerLabel, bannerText, textHighlight, columnItems, ctaDetails } =
      toRefs(props);

    return {
      bannerLabel,
      bannerText,
      textHighlight,
      columnItems,
      ctaDetails,
    };
  },
};
</script>

<style scoped>
.three-column-section {
  width: 100%;
}

@media only screen and (min-width: 1440px) {
  .three-column-section {
    /* max-width: 1130px; */
    max-width: 1400px;
    margin: 0 auto;
    /* padding: 60px 100px; */
  }
}
</style>
