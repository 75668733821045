import FeatureInPageBannerService from "./service/feature/FeatureInPageBannerService"
import FeatureCalloutService from "./service/feature/FeatureCalloutService"
import FeatureThreeColumnService from "./service/feature/FeatureThreeColumnService"
import FeatureCallout2Service from "./service/feature/FeatureCallout2Service"
import FeatureCarouselService from "./service/feature/FeatureCarouselService"
import FeatureIconTableService from "./service/feature/FeatureIconTableService"
import FeatureSimpleCalloutService from "./service/feature/FeatureSimpleCalloutService"
import FeatureFooterCalloutService from "./service/feature/FeatureFooterCalloutService"

export default class Feature {
    inPageBanner: FeatureInPageBannerService
    calloutService: FeatureCalloutService
    threeColumn: FeatureThreeColumnService
    calloutService2: FeatureCallout2Service
    carousel: FeatureCarouselService
    iconTable: FeatureIconTableService
    simpleCallout: FeatureSimpleCalloutService
    footerCallout: FeatureFooterCalloutService

    constructor() {
        this.inPageBanner = new FeatureInPageBannerService()
        this.calloutService = new FeatureCalloutService()
        this.threeColumn = new FeatureThreeColumnService()
        this.calloutService2 = new FeatureCallout2Service()
        this.carousel = new FeatureCarouselService()
        this.iconTable = new FeatureIconTableService()
        this.simpleCallout = new FeatureSimpleCalloutService()
        this.footerCallout = new FeatureFooterCalloutService()
    }

    async getAllData() {
        const inPageBanner = await this.inPageBanner.process()
        const callout1 = await this.calloutService.process()
        const threeColumn = await this.threeColumn.process()
        const callout2 = await this.calloutService2.process()
        const carousel = await this.carousel.process()
        const iconTable = await this.iconTable.process()
        const simpleCallout = await this.simpleCallout.process()
        const footerCallout = await this.footerCallout.process()

        return {
            inPageBanner,
            callout1,
            threeColumn,
            callout2,
            carousel,
            iconTable,
            simpleCallout,
            footerCallout,
        }
    }
}
