import CompanyInPageBannerService from "./service/company/CompanyInPageBannerService"
import CompanyCalloutService from "./service/company/CompanyCalloutService"
import OurStoryTwoColumnService from "./service/company/OurStoryTwoColumnService"
import WhoWeAreTwoColumnPlainService from "./service/company/WhoWeAreTwoColumnPlainService"
import CompanyImagePanelService from "./service/company/CompanyImagePanelService"
import CompanySimpleCalloutService from "./service/company/CompanySimpleCalloutService"
import CompanyFooterCalloutService from "./service/company/CompanyFooterCalloutService"

export default class Company {
    inPageBannerService: CompanyInPageBannerService
    companyCalloutService: CompanyCalloutService
    twoColumnService: OurStoryTwoColumnService
    twoColumnPlainService: WhoWeAreTwoColumnPlainService
    companyImagePanelService: CompanyImagePanelService
    companySimpleCalloutService: CompanySimpleCalloutService
    companyFooterCalloutService: CompanyFooterCalloutService

    constructor() {
        this.inPageBannerService = new CompanyInPageBannerService()
        this.companyCalloutService = new CompanyCalloutService()
        this.twoColumnService = new OurStoryTwoColumnService()
        this.twoColumnPlainService = new WhoWeAreTwoColumnPlainService()
        this.companyImagePanelService = new CompanyImagePanelService()
        this.companySimpleCalloutService = new CompanySimpleCalloutService()
        this.companyFooterCalloutService = new CompanyFooterCalloutService()
    }

    async getAllData() {
        const inPageBanner = await this.inPageBannerService.process()
        const callout = await this.companyCalloutService.process()
        const twoColumn = await this.twoColumnService.process()
        const twoColumnPlain = await this.twoColumnPlainService.process()
        const companyImagePanel = await this.companyImagePanelService.process()
        const companySimpleCallout =
      await this.companySimpleCalloutService.process()
        const companyFooterCallout =
      await this.companyFooterCalloutService.process()

        return {
            inPageBanner,
            callout,
            twoColumn,
            twoColumnPlain,
            companyImagePanel,
            companySimpleCallout,
            companyFooterCallout,
        }
    }
}
