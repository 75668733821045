import FaqSimpleBanner from "@/core/infrastructure/repository/faq/FaqSimpleBanner"
import hasData from "@/core/domain/specification/hasData"

export default class FaqBannerService {
    faqSimpleBanner: FaqSimpleBanner

    constructor() {
        this.faqSimpleBanner = new FaqSimpleBanner()
    }

    async process() {
        const { data, totalCount } = await this.faqSimpleBanner.fetch()

        if (!hasData(totalCount)) {
            throw new TypeError("No data was provided")
        }

        return data
    }
}
