import QueryBuilder from "../QueryBuilder"

export default class ThreeColumnRepository {
    constructor(protected contentName: string) {}

    async fetch() {
        const results = await new QueryBuilder()
            .buildQuery(this.contentName, [
                "bannerText",
                "text_highlight",
                "banner_label",
                "'cta_details': cta_content",
                "'column_items': threeColumnSection",
            ])
            .process()

        return results.getResultInfo()
    }
}
