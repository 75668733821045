import ContactInPageBannerSimple from "@/core/infrastructure/repository/contact/ContactInPageBannerSimple"
import hasData from "@/core/domain/specification/hasData"

export default class ContactInPageBannerSimpleService {
    protected contactInPageBannerSimple: ContactInPageBannerSimple

    constructor() {
        this.contactInPageBannerSimple = new ContactInPageBannerSimple()
    }

    async process() {
        const { data, totalCount } = await this.contactInPageBannerSimple.fetch()

        if (!hasData(totalCount)) {
            throw new TypeError("No data was provided")
        }

        return data
    }
}
