export const MAX_CONTENT_WIDTH = "1440px"

export const NAV_MENU = [
    { name: "Why Podiem", url: "/why-podiem" },
    // { 'name': 'Features', 'url': '/feature' },
    { name: "Pricing", url: "/pricing" },
    { name: "Company", url: "/company" },
    { name: "Contact", url: "/contact-us" },
    { name: "Resources", url: "/resources" },
    { name: "Sign In", url: "https://app.podiem.io" },
]

export const TEMPLATE_SETTINGS = {
    nav_height: "88px",
    main_content_padding_top: "88px",
}

export const SCREEN_BREAKPOINT = {
    xs: 375,
    sm: 480,
    md: 768,
    lg: 1024,
    xl: 1440,
}

export const TAB_ITEMS = [
    {
        name: "Pricing",
        schema_id: "faq_pricing",
        url: "#",
    },
    {
        name: "Technology",
        schema_id: "faq_technology",
        url: "#",
    },
    // {
    //     'name': 'Integrations',
    //     'schema_id': 'faq_integration',
    //     'url': '#',
    // },
    // {
    //     'name': 'Training',
    //     'schema_id': 'faq_training',
    //     'url': '#',
    // },
    {
        name: "General",
        schema_id: "faq_general",
        url: "#",
    },
]

export const IMAGE_SOURCE = "./src/assets/images/"
export const CALLOUT_IMAGE_SOURCE = "./src/assets/images/callout/"

export const CALLOUT_TYPES = {
    NORMAL: "normal",
    FOOTER: "footer",
}

export const HOME_HERO_IMAGE = {
    XS: "./src/assets/images/home_hero_xs.png",
    MD: "./src/assets/images/home_hero_md.png",
    LG: "./src/assets/images/home_hero_wave.png",
}

// export const FEATURE_CATEGORY = {
//     'XDR_STAFF': 1,
//     'SALES': 2,
//     'ACCOUNT_MANAGER': 3,
// }

export const FEATURE_CATEGORY = [
    {
        name: "EXECUTIVES",
        title: "Executives",
        id: "accountManager",
    },
    {
        name: "SALES",
        title: "Sales & Marketing Leaders",
        id: "sales",
    },
    {
        name: "XDRS",
        title: "XDRs",
        id: "xdrStaff",
    },
]
