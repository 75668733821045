<template>
  <div>
    <section class="in-page-banner2 xl:-mt-[40px] h-auto">
      <div
        class="section-container md:pt-[30px] lg:pt-[60px] xl:py-[100px] fhd:pt-[170px]"
      >
        <simple-banner-title
          :pageTitle="resourceBanner.bannerText"
          :customClass="'mb-[20px]'"
        />

        <template v-if="resourceBanner.simpleBlockContent">
          <div class="flex flex-col items-center justify-center">
            <p
              v-for="(value, id) in resourceBanner.simpleBlockContent"
              :key="id"
              class="m-0 text-neutral-2 leading-none text-center mb-[5px] prose"
            >
              <sanity-blocks :blocks="[value]"></sanity-blocks>
            </p>
          </div>
        </template>

        <div class="mb-[80px]"></div>

        <div class="mb-16">
          <div class="blog-nav grid grid-cols-1 lg:grid-cols-2 gap-4 h-[48px]">
            <div
              v-if="blogCategory"
              class="flex flex-row justify-between lg:justify-start lg:space-x-[45px] items-center"
            >
              <div
                class="border-b-4 cursor-pointer hover:text-primary-1 hover:border-primary-1 transition-all duration-200 border-transparent"
                @click="selectCategory('all')"
                :class="{
                  'border-primary-1 text-primary-1 apercu-bold':
                    selectedCategory === 'all',
                }"
              >
                All
              </div>
              <div
                class="border-b-4 cursor-pointer hover:text-primary-1 hover:border-primary-1 transition-all duration-200 border-transparent"
                v-for="value in blogCategory"
                @click="selectCategory(value.slug)"
                :class="{
                  'border-primary-1 text-primary-1 apercu-bold':
                    selectedCategory === value.slug,
                }"
              >
                {{ value.title }}
              </div>
            </div>

            <div class="flex flex-row relative">
              <div class="">
                <input
                  class="w-full rounded-full border px-4 absolute top-0 right-0 lg:w-[250px] xl:w-[350px] pr-[65px]"
                  type="text"
                  placeholder="Search articles"
                  @input="searchArticle($event.target!.value)"
                />

                <button
                  class="flex justify-center items-center rounded-full w-[35px] h-[40px] top-1 right-1 disabled:bg-disabled-1 disabled:text-disabled-text-1 bg-black absolute"
                >
                  <span>
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.7043 16.2848L14.3054 12.8958C15.402 11.4988 15.9971 9.77351 15.9948 7.99743C15.9948 6.41569 15.5258 4.86947 14.647 3.5543C13.7683 2.23913 12.5192 1.21408 11.0579 0.608771C9.59657 0.00346515 7.98855 -0.15491 6.43721 0.153672C4.88586 0.462254 3.46085 1.22393 2.34239 2.34239C1.22393 3.46085 0.462254 4.88586 0.153672 6.43721C-0.15491 7.98855 0.00346515 9.59657 0.608771 11.0579C1.21408 12.5192 2.23913 13.7683 3.5543 14.647C4.86947 15.5258 6.41569 15.9948 7.99743 15.9948C9.77351 15.9971 11.4988 15.402 12.8958 14.3054L16.2848 17.7043C16.3777 17.798 16.4883 17.8724 16.6101 17.9231C16.7319 17.9739 16.8626 18 16.9945 18C17.1265 18 17.2572 17.9739 17.379 17.9231C17.5008 17.8724 17.6114 17.798 17.7043 17.7043C17.798 17.6114 17.8724 17.5008 17.9231 17.379C17.9739 17.2572 18 17.1265 18 16.9945C18 16.8626 17.9739 16.7319 17.9231 16.6101C17.8724 16.4883 17.798 16.3777 17.7043 16.2848ZM1.99936 7.99743C1.99936 6.81112 2.35114 5.65146 3.01022 4.66508C3.66929 3.6787 4.60606 2.90991 5.70207 2.45593C6.79807 2.00196 8.00408 1.88317 9.16759 2.11461C10.3311 2.34605 11.3999 2.91731 12.2387 3.75615C13.0775 4.595 13.6488 5.66375 13.8802 6.82726C14.1117 7.99077 13.9929 9.19678 13.5389 10.2928C13.0849 11.3888 12.3162 12.3256 11.3298 12.9846C10.3434 13.6437 9.18373 13.9955 7.99743 13.9955C6.40664 13.9955 4.88101 13.3636 3.75615 12.2387C2.6313 11.1138 1.99936 9.58821 1.99936 7.99743Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div>
          <template v-if="loadingArticles">
            <div class="grid place-items-center">
              <fulfilling-bouncing-circle-spinner
                :animation-duration="4000"
                :size="60"
                color="#008AFE"
              />
            </div>
          </template>

          <template v-else>
            <template v-if="blogArticles.length >= 1">
              <div
                class="grid grid-cols-1 gap-y-[30px] lg:grid-cols-2 sxl:grid-cols-3 gap-x-[30px] md:gap-y-[100px]"
              >
                <div
                  v-for="(blog, key) in blogArticles"
                  :key="key"
                  class="bg-white rounded-3xl p-[40px] shadow-lg transition-all duration-100 border border-gray-100"
                >
                <div class="image-wrapper mb-[15px] relative flex items-center justify-center">
                  <div 
                    class="blur-background absolute inset-0"
                    :style="{ backgroundImage: `url(${parseSanityImage(blog.image).url()})` }"
                  ></div>
                  <img
                    :src="parseSanityImage(blog.image).url()"
                    :alt="blog.image.alt"
                    class="object-contain relative z-10"
                  />
                </div>

                  <div class="content mb-[25px]">
                    <p
                      class="text-primary-1 apercu-light text-custom-xs mb-[15px] leading-none"
                    >
                      {{ blog.readingTime }}
                    </p>
                    <div
                      class=" overflow-hidden mb-[15px] min-h-[60px]"
                    >
                      <p class="text-custom-sm apercu-medium text-gray-700">
                        {{ blog.bannerText }}
                      </p>
                    </div>
                  </div>

                  <div>
                    <button
                      class="border border-primary-1 rounded-full text-primary-1 hover:text-white hover:bg-primary-1 py-2 px-[25px] text-[16px]"
                      @click="goToArticle(blog.bannerText, blog._key)"
                    >
                      Read more
                    </button>
                  </div>
                </div>
              </div>
            </template>

            <template v-else>
              <p class="text-center">No results found</p>
            </template>
          </template>
        </div>

        <!-- <suspense>
          <call-out-block
            textLocation="normal"
            :calloutText="callout.calloutText"
            :calloutHighlight="callout.calloutHighlight"
            :calloutDescription="callout.callOutDescription"
            :calloutImage="callout.calloutImage"
            :isCTAEnabled="callout.ctaEnabled"
            :ctaText="callout.ctaText"
            :ctaRedirection="callout.ctaRedirection"
          />
        </suspense> -->
      </div>
    </section>

    <section class="bg-neutral-5">
      <div class="footer-callout-container py-[40px]">
        <suspense>
          <footer-call-out-block
            :bannerText="footerCallout.bannerText"
            :bannerTextHighlight="footerCallout.textHighlight"
            :bannerImage="footerCallout.calloutImage"
            :ctaDetails="footerCallout.ctaDetails"
          />
        </suspense>
      </div>
    </section>
  </div>
</template>
<script lang="ts">
import _ from "lodash";
import { ref, reactive, computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { SanityBlocks } from "sanity-blocks-vue-component";
import { parseSanityImage } from "@/js/composable/parseSanityImage";
import SimpleBannerTitle from "@/components/atom/text/SimpleBannerTitle.vue";
import CallOutBlock from "@/components/molecule/CallOutBlock.vue";
import FooterCallOutBlock from "@/components/molecule/FooterCallOutBlock.vue";
import { FulfillingBouncingCircleSpinner } from "epic-spinners";

import Resources from "@/core/application/Resources";
import ResourcesPageModel from "@/core/domain/model/ResourcesPageModel";
import ResourcesFilterCategory from "@/core/application/service/resources/ResourcesFilterCategory";

export default {
  name: "Resources",
  components: {
    SimpleBannerTitle,
    CallOutBlock,
    FooterCallOutBlock,
    SanityBlocks,
    FulfillingBouncingCircleSpinner,
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    const resourceBanner = reactive({
      bannerText: "",
      subheaderText: "",
      simpleBlockContent: {},
    });

    const callout = reactive({
      calloutText: "",
      calloutHighlight: "",
      callOutDescription: "",
      calloutImage: {},
      ctaEnabled: false,
      ctaText: "",
      ctaRedirection: "",
    });

    const footerCallout = reactive({
      bannerText: "",
      textHighlight: "",
      calloutImage: {},
      ctaDetails: {},
    });

    const allBlogs = ref({});
    const blogList = ref({});
    const blogCateg = ref({});
    const loadingArticles = ref(true);

    const resources = new Resources();
    const loadResources = () => {
      resources.getAllData().then((data: ResourcesPageModel) => {
        resourceBanner.bannerText = data.inPageBanner.bannerText!;
        resourceBanner.subheaderText = data.inPageBanner.subheaderText!;
        resourceBanner.simpleBlockContent = data.inPageBanner.simpleBlockContent!;

        callout.calloutText = data.callout.bannerText!;
        callout.calloutHighlight = data.callout.text_highlight!;
        callout.callOutDescription = data.callout.subheaderText!;
        callout.calloutImage = data.callout.image!;
        callout.ctaEnabled = data.callout.cta_enabled!;
        callout.ctaText = data.callout.cta_text!;
        callout.ctaRedirection = data.callout.cta_redirection!;

        footerCallout.bannerText = data.footerCallout.bannerText!;
        footerCallout.textHighlight = data.footerCallout.text_highlight!;
        footerCallout.calloutImage = data.footerCallout.image!;
        footerCallout.ctaDetails = data.footerCallout.cta_details!;

        allBlogs.value = data.blogList!;
        blogList.value = data.blogList!;
        blogCateg.value = data.blogCategory;

        store.dispatch("blog_category/setCategory", data.blogCategory);
        store.dispatch("blog_article/setArticles", blogList.value);

        loadingArticles.value = false;
      });
    };

    onMounted(() => {
      loadResources();
    });

    window.addEventListener('popstate', () => {
      loadResources();
    });

    router.beforeEach((to, from, next) => {
      if (from.fullPath !== to.fullPath) {
        loadResources();
      }
      next();
    });

    const goToArticle = (details: string, key: number) => {
      const slug = details.toLowerCase().replace(/\s+/g, "-");
      const url = "/blog/" + key + "/" + slug;

      router.push(url);
    };

    const blogCategory = computed(
      () => store.getters["blog_category/GET_category"],
    );
    const blogArticles = computed(
      () => store.getters["blog_article/GET_articles"],
    );

    const selectedCategory = ref("all");

    const loadArticles = () => {
      loadingArticles.value = true;
    };

    const selectCategory = (category: string) => {
      selectedCategory.value = category;

      store.dispatch("blog_article/setArticles", blogList.value);
      const categoryFiltered = new ResourcesFilterCategory()
        .setCategory(category)
        .setArticles(blogArticles.value)
        .setKeywords(textSearch.value)
        .filter();

      store.dispatch("blog_article/setArticles", categoryFiltered);
    };

    const textSearch = ref("");
    const textSearchLoading = ref(false);
    const searchArticle = (keyword: string) => {
      textSearchLoading.value = true;
      filterArticle(keyword);
    };
    const filterArticle = _.debounce(async (keyword: string) => {
      textSearch.value = keyword;
      store.dispatch("blog_article/setArticles", blogList.value);
      const textFiltered = new ResourcesFilterCategory()
        .setCategory(selectedCategory.value)
        .setArticles(blogArticles.value)
        .setKeywords(keyword)
        .filter();

      store.dispatch("blog_article/setArticles", textFiltered);
      textSearchLoading.value = false;
    }, 500);

    return {
      resourceBanner,
      callout,
      footerCallout,
      parseSanityImage,
      goToArticle,
      blogCategory,
      selectedCategory,
      selectCategory,
      blogCateg,
      blogArticles,
      loadingArticles,
      searchArticle,
      textSearchLoading,
    };
  },
};
</script>
<style scoped>
.blog-text-content {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.image-wrapper {
  height: 300px;
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-position: center;
}

.blur-background {
  background-size: cover;
  background-position: center;
  filter: blur(10px); /* Apply blur to the background */
  width: 100%;
  height: 100%;
  z-index: 0;
}

.image-wrapper img {
  position: relative;
  z-index: 10; /* Ensures image stays above the blurred background */
}

/* .image {
  height: 300px;
  overflow: hidden;
  position: relative;
  background-size: cover;
  background-position: center;
} */

.image > img {
  height: 100%;
  margin-inline: auto;
}

</style>
