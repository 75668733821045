import FeatureCarousel from "@/core/infrastructure/repository/feature/FeatureCarousel"
import hasData from "@/core/domain/specification/hasData"

export default class FeatureCarouselService {
    protected featureCarousel: FeatureCarousel

    constructor() {
        this.featureCarousel = new FeatureCarousel()
    }

    async process() {
        const { data, totalCount } = await this.featureCarousel.fetch()

        if (!hasData(totalCount)) {
            throw new TypeError("No data was provided")
        }

        return data
    }
}
