import QueryBuilder from "../QueryBuilder"

export default class BannerPlainTextRepository {
    constructor(public contentName: string) {}

    async fetch() {
        const results = await new QueryBuilder()
            .buildQuery(this.contentName, ["bannerText", "subheaderText"])
            .process()

        return results.getResultInfo()
    }
}
