export const SUCCESS_CODE = 200

export const CONTACT_FORM_API_URL =
  "https://dev.back.datalist.io/podiem/register"
export const SUBSCRIBE_FORM_API_URL =
  "https://dev.back.datalist.io/podiem/subscribe"

export const CONTACT_FORM_API_SETTINGS = {
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "POST, OPTIONS",
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
    },
}

export const EXECUTIVE = 0
export const SALES = 1
export const XDR = 2
