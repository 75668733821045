import ResourcesInPageBanner from "@/core/infrastructure/repository/resources/ResourcesInPageBanner"
import hasData from "@/core/domain/specification/hasData"

export default class ResourcesInPageBannerService {
    protected resourcesInPageBanner: ResourcesInPageBanner

    constructor() {
        this.resourcesInPageBanner = new ResourcesInPageBanner()
    }

    async process() {
        const { data, totalCount } = await this.resourcesInPageBanner.fetch()

        if (!hasData(totalCount)) {
            throw new TypeError("No data was provided")
        }

        return data
    }
}
