<template>
  <div class="z-10">
    <transition
      mode="in-out"
      enter-active-class="animate__animated animate__fadeInUp"
      leave-active-class="animate__animated animate__fadeOutDown"
    >
      <div
        v-if="isOpenCustomConsent"
        class="bg-white pb-3 max-h-[400px] overflow-auto pt-[20px] px-[20px]"
      >
        <div class="consent-header">
          <h3 class="text-xl font-bold mb-[5px]">
            Customize Consent Preferences
          </h3>
          <p class="apercu-light text-sm">
            We use cookies to help you navigate efficiently and perform certain
            functions. You will find detailed information about all cookies
            under each consent category below. The cookies that are categorized
            as "Necessary" are stored on your browser as they are essential for
            enabling the basic functionalities of the site. We also use
            third-party cookies that help us analyze how you use this website,
            store your preferences, and provide the content that is most
            relevant to you. These cookies will only be stored in your browser
            with your prior consent. You can choose to enable or disable some or
            all of these cookies but disabling some of them may affect your
            browsing experience.
          </p>
        </div>
        <div class="consent-body">
          <!-- <div class="consent-necessary">
                        <div>
                            <svg 
                                @click="toggleNecessaryContent()"
                                width="7" 
                                height="12" 
                                viewBox="0 0 7 12" 
                                fill="none" 
                                xmlns="http://www.w3.org/2000/svg" 
                                class="mt-1 mr-2 cursor-pointer"
                                :class="{ 'rotate-90': cookieDropdown.isOpenNecessary }"
                            >
                                <path d="M0.325994 1.04138C0.238772 1.11951 
                                0.189941 1.2244 0.189941 1.33363C0.189941 1.44285 0.238772 1.54774 0.325994 1.62588L5.19899 6L0.325994 10.3733C0.238772 10.4514 0.189941 10.5563 0.189941 10.6655C0.189941 10.7747 0.238772 10.8796 0.325994 10.9578C0.368391 10.9959 0.41908 11.0261 0.475071 11.0468C0.531063 11.0675 0.59122 11.0781 0.651994 11.0781C0.712768 11.0781 0.772926 11.0675 0.828918 11.0468C0.884909 11.0261 0.935598 10.9959 0.977994 10.9578L6.15999 6.30538C6.25101 6.22367 6.30195 6.11409 6.30195 6C6.30195 5.88591 6.25101 5.77633 6.15999 5.69463L0.977994 1.04225C0.935598 1.00415 0.884909 0.973868 0.828918 0.95319C0.772926 0.932513 0.712768 0.92186 0.651994 0.92186C0.59122 0.92186 0.531063 0.932513 0.475071 0.95319C0.41908 0.973868 0.368391 1.00415 0.325994 1.04225V1.04138Z" fill="black" />
                            </svg>
                        </div>
                        <div class="w-full">
                            <div class="flex flex-row justify-between mb-1">
                                <div
                                    @click="toggleNecessaryContent()"
                                    class="title"
                                >
                                    Necessary
                                </div>
                                <div class="status text-green-500 font-bold">
                                    Always Active
                                </div>
                            </div>
                            <div v-if="cookieDropdown.isOpenNecessary" class="content apercu-light">
                                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nesciunt, a.
                            </div>
                        </div>
                    </div> -->

          <div class="consent-analytics">
            <div>
              <svg
                @click="toggleAnalyticsContent()"
                width="7"
                height="12"
                viewBox="0 0 7 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="mt-1 mr-2 cursor-pointer"
                :class="{ 'rotate-90': cookieDropdown.isOpenAnalytics }"
              >
                <path
                  d="M0.325994 1.04138C0.238772 1.11951 0.189941 1.2244 0.189941 1.33363C0.189941 1.44285 0.238772 1.54774 0.325994 1.62588L5.19899 6L0.325994 10.3733C0.238772 10.4514 0.189941 10.5563 0.189941 10.6655C0.189941 10.7747 0.238772 10.8796 0.325994 10.9578C0.368391 10.9959 0.41908 11.0261 0.475071 11.0468C0.531063 11.0675 0.59122 11.0781 0.651994 11.0781C0.712768 11.0781 0.772926 11.0675 0.828918 11.0468C0.884909 11.0261 0.935598 10.9959 0.977994 10.9578L6.15999 6.30538C6.25101 6.22367 6.30195 6.11409 6.30195 6C6.30195 5.88591 6.25101 5.77633 6.15999 5.69463L0.977994 1.04225C0.935598 1.00415 0.884909 0.973868 0.828918 0.95319C0.772926 0.932513 0.712768 0.92186 0.651994 0.92186C0.59122 0.92186 0.531063 0.932513 0.475071 0.95319C0.41908 0.973868 0.368391 1.00415 0.325994 1.04225V1.04138Z"
                  fill="black"
                />
              </svg>
            </div>
            <div class="w-full">
              <div class="flex justify-between mb-1">
                <div
                  @click="toggleAnalyticsContent()"
                  class="title cursor-pointer"
                >
                  Analytics
                </div>
                <div class="status text-green-500 font-bold">
                  <label class="switch">
                    <input
                      :checked="checkboxSwitch"
                      type="checkbox"
                      @click="toggleAnalyticsCheckbox()"
                    />
                    <div class="slider round"></div>
                  </label>
                </div>
              </div>
              <div
                v-if="cookieDropdown.isOpenAnalytics"
                class="content apercu-light"
              >
                <table class="mb-3 cookie-table">
                  <tbody>
                    <tr>
                      <td class="font-bold pr-5">Cookie</td>
                      <td>_ga</td>
                    </tr>
                    <tr>
                      <td class="font-bold pr-5">Duration</td>
                      <td>2 years</td>
                    </tr>
                    <tr>
                      <td class="font-bold pr-5">Description</td>
                      <td>
                        The _ga cookie, installed by Google Analytics,
                        calculates visitor, session, campaign data, and also
                        keeps track of site usage for the site's analytics
                        report. The cookie stores information anonymously and
                        assigns a randomly generated number to recognize unique
                        visitors.
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table class="mb-3 cookie-table">
                  <tbody>
                    <tr>
                      <td class="font-bold pr-5">Cookie</td>
                      <td>_ga_68YJVRFGDV</td>
                    </tr>
                    <tr>
                      <td class="font-bold pr-5">Duration</td>
                      <td>2 years</td>
                    </tr>
                    <tr>
                      <td class="font-bold pr-5">Description</td>
                      <td>
                        The _ga_[container-id] cookie, installed by Google
                        Analytics to persist session state.
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script lang="ts">
import { ref, reactive, computed } from "vue";
import { useStore } from "vuex";

export default {
  name: "ConsentBannerCustomize",
  setup() {
    const store = useStore();
    const checkboxSwitch = computed(
      () => store.getters["consent_banner/GET_isOpenAnalyticsCheckboxStatus"],
    );
    const isOpenCustomConsent = computed(
      () => store.getters["consent_banner/GET_isOpenCustomConsentStatus"],
    );
    const cookieDropdown = reactive({
      isOpenNecessary: false,
      isOpenAnalytics: false,
    });

    const toggleNecessaryContent = () => {
      cookieDropdown.isOpenNecessary = !cookieDropdown.isOpenNecessary;
    };

    const toggleAnalyticsContent = () => {
      cookieDropdown.isOpenAnalytics = !cookieDropdown.isOpenAnalytics;
    };

    const toggleAnalyticsCheckbox = () => {
      store.dispatch("consent_banner/toggleAnalyticsCheckboxStatus");
    };

    return {
      checkboxSwitch,
      isOpenCustomConsent,
      cookieDropdown,
      toggleNecessaryContent,
      toggleAnalyticsContent,
      toggleAnalyticsCheckbox,
    };
  },
};
</script>
<style scoped>
.consent-header {
  @apply mb-[15px] pb-[15px] border-b border-gray-300;
}

.consent-body {
  @apply pl-[10px];
}

.consent-title {
  @apply font-bold;
}

.consent-necessary,
.consent-analytics {
  @apply flex flex-row mb-[15px];
}

.content {
  @apply bg-gray-100 text-gray-700 text-sm px-2 py-1;
}

/* Toggle switch */
.switch {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 20px;
  margin-bottom: 0;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 3px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #007bff;
}

input:focus + .slider {
  box-shadow: 0 0 1px #101010;
}

input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.cookie-table td {
  font-size: 13px;
  vertical-align: baseline;
}
</style>
