<template>
  <div
    class="pricing-card w-full lg:w-1/3 lg:max-w-1/3 md:mx-auto"
    v-if="subscriptionDetails.subscriptionItems"
    v-for="(details, id) in subscriptionDetails.subscriptionItems"
    :key="id"
  >
    <div class="subscription-details bg-primary-3 rounded-t-2xl p-[40px]">
      <div class="text-content">
        <h3 class="text-heading-md">{{ details.cardText }}</h3>
        <div class="flex flex-row items-start gap-3 mb-[20px]">
          <h2 class="text-heading-xl apercu-medium">
            {{ details.subscription.price }}
          </h2>
          <p class="text-heading-xs pt-[25px]">
            {{ details.subscription.currency }}
          </p>
          <p class="text-heading-xs pt-[25px]">
            / {{ details.subscription.subscription }}
          </p>
        </div>
        <p class="text-heading-xs mb-[20px]">{{ details.userCount }}</p>
      </div>

      <template v-if="details.ctaDetails.cta_enabled">
        <button-default
          :buttonText="details.ctaDetails.cta_text"
          :redirection="details.ctaDetails.cta_redirection"
        />
      </template>
    </div>

    <div
      class="services shadow-custom-2 p-[40px] rounded-b-2xl flex flex-col gap-y-[20px]"
    >
      <div
        class="flex flex-row gap-2 items-center"
        v-for="(info, key) in details.services"
        :key="key"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
          :class="[
            { 'text-primary-1': info.isActive },
            { 'text-gray-300': !info.isActive },
          ]"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        <p
          class="text-heading-xs apercu-light"
          :class="{ 'text-gray-300': !info.isActive }"
        >
          {{ info.text }}
        </p>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { toRefs, ref } from "vue";
import ButtonDefault from "@/components/atom/button/ButtonDefault.vue";

export default {
  name: "SubscriptionCard",
  components: {
    ButtonDefault,
  },
  props: {
    subscriptionDetails: {
      type: Object,
      default: {},
    },
  },
  setup(props: any) {
    const { subscriptionDetails } = toRefs(props);
    return {
      subscriptionDetails,
    };
  },
};
</script>
<style lang=""></style>
