import QueryBuilder from "../QueryBuilder"

export default class SiteSettingRepository {
    async fetch() {
        const results = await new QueryBuilder()
            .rawQuery("*[_id == 'siteSettings'][0]")
            .process()

        return results.getResultInfo()
    }
}
