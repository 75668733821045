<template>
  <button
    @click="redirectLink(redirection, APP_URL)"
    class="bg-primary-4 text-white rounded-full hover:bg-primary-2 disabled:bg-disabled-1 disabled:text-disabled-text-1"
    :class="additionalClass()"
  >
    {{ buttonText }}
  </button>
</template>

<script lang="ts">
import { toRefs } from "vue";
import { redirectLink } from "@/js/composable/redirectLink";

export default {
  name: "ButtonDefault",
  props: {
    buttonText: {
      type: String,
      default: "Button",
    },
    buttonSize: {
      type: String,
      default: "",
    },
    redirection: {
      type: String,
      default: "javascript:void(0)",
    },
  },
  setup(props: any) {
    const { redirection, buttonSize } = toRefs(props);

    const additionalClass = () => {
      if (buttonSize.value === "fluid") {
        return "w-full";
      }
      return "w-fit";
    };

    return {
      APP_URL: import.meta.env.VITE_APP_URL,
      redirection,
      redirectLink,
      additionalClass,
    };
  },
};
</script>

<style scoped>
button:disabled {
  cursor: not-allowed;
}
</style>
