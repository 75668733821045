<template>
  <div
    id="scroll-wrapper"
    ref="scrollWrapper"
    class="scroll-wrapper w-full overflow-x-auto"
  >
    <div class="scroll-container border flex flex-row">
      <div class="w-20 h-20 bg-green-100"></div>
      <div class="w-20 h-20 bg-green-200"></div>
      <div class="w-20 h-20 bg-green-300"></div>
      <div class="w-20 h-20 bg-green-400"></div>
      <div class="w-20 h-20 bg-green-500"></div>
      <div class="w-20 h-20 bg-green-600"></div>
      <div class="w-20 h-20 bg-green-700"></div>
      <div class="w-20 h-20 bg-green-800"></div>
      <div class="w-20 h-20 bg-green-900"></div>

      <div class="w-20 h-20 bg-green-100"></div>
      <div class="w-20 h-20 bg-green-200"></div>
      <div class="w-20 h-20 bg-green-300"></div>
      <div class="w-20 h-20 bg-green-400"></div>
      <div class="w-20 h-20 bg-green-500"></div>
      <div class="w-20 h-20 bg-green-600"></div>
      <div class="w-20 h-20 bg-green-700"></div>
      <div class="w-20 h-20 bg-green-800"></div>
      <div class="w-20 h-20 bg-green-900"></div>

      <div class="w-20 h-20 bg-green-100"></div>
      <div class="w-20 h-20 bg-green-200"></div>
      <div class="w-20 h-20 bg-green-300"></div>
      <div class="w-20 h-20 bg-green-400"></div>
      <div class="w-20 h-20 bg-green-500"></div>
      <div class="w-20 h-20 bg-green-600"></div>
      <div class="w-20 h-20 bg-green-700"></div>
      <div class="w-20 h-20 bg-green-800"></div>
      <div class="w-20 h-20 bg-green-900"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { onMounted, onUnmounted, ref } from "vue";

export default {
  setup() {
    const scrollWrapper = ref(null);

    const horizontalScroll = (element) => {
      if (element.deltaY > 0) {
        scrollWrapper.value.scrollLeft += 100;
      } else {
        scrollWrapper.value.scrollLeft -= 100;
      }
    };

    const disableScrolling = () => {
      var x = window.scrollX;
      var y = window.scrollY;

      window.onscroll = () => {
        window.scrollTo(x, y);
      };
    };

    const enableScrolling = () => {
      window.onscroll = null;
    };

    onMounted(() => {
      scrollWrapper.value.addEventListener("mouseenter", () => {
        window.addEventListener("wheel", horizontalScroll);

        disableScrolling();
      });

      scrollWrapper.value.addEventListener("mouseleave", () => {
        enableScrolling();
        window.removeEventListener("wheel", horizontalScroll);
      });
    });

    onUnmounted(() => {
      window.removeEventListener("wheel", horizontalScroll);
    });

    return {
      scrollWrapper,
    };
  },
};
</script>

<style>
.scroll-container {
  width: 2000px;
}

/* .scroll-wrapper::-webkit-scrollbar {
  display: none;
} */
/* 
.horizontal-scroll-wrapper {
  width: 100px;
  height: 500px;
  overflow-y: auto;
  overflow-x: hidden;

  transform: rotate(-90deg);
  transform-origin: right top;

}

.horizontal-scroll-wrapper > div {
  width: 100px;
  height: 100px;

  transform: rotate(90deg);
  transform-origin: right top;
} */
</style>
