<template>
  <base-layout>
    <template #header>
      <nav-menu></nav-menu>
    </template>

    <template #default>
      <router-view v-slot="{ Component }">
        <transition
          appear
          enter-active-class="animate__animated animate__fadeIn"
        >
          <component :is="Component"></component>
        </transition>
      </router-view>
    </template>

    <template #footer>
      <footer-menu></footer-menu>
    </template>
  </base-layout>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";

import BaseLayout from "./components/template/BaseLayout.vue";
import NavMenu from "./components/organism/NavMenu.vue";
import FooterMenu from "./components/organism/Footer.vue";
// import BaseLayout1 from './components/template/BaseLayout.vue'

export default defineComponent({
  components: {
    BaseLayout,
    NavMenu,
    FooterMenu,
    // BaseLayout1,
  },
  setup() {
    const store = useStore();
    return {
      darkModeOn: computed(() => store.getters["navigation/GET_isDarkModeOn"]),
    };
  },
});
</script>

<style></style>
