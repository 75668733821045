import QueryBuilder from "../QueryBuilder"

export default class SimpleBannerRepository {
    constructor(public contentName: string) {}

    async fetch() {
        const results = await new QueryBuilder()
            .buildQuery(this.contentName, ["bannerText", "simpleBlockContent"])
            .process()

        return results.getResultInfo()
    }
}
