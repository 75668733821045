/* eslint-disable @typescript-eslint/no-unsafe-call */
const state = () => ({
  selectedFeatureId: "",
  featureDetailData: {},
});

const actions = {
  setSelectedFeatureId({ commit }: any, data: string) {
    commit("SET_SELECTED_FEATURE_ID", data);
  },
  setfeatureDetailData({ commit }: any, data: object) {
    commit("SET_FEATURE_DETAIL_DATA", data);
  },
};

const mutations = {
  SET_SELECTED_FEATURE_ID(
    state: { selectedFeatureId: string },
    payload: string,
  ) {
    state.selectedFeatureId = payload;
  },
  SET_FEATURE_DETAIL_DATA(
    state: { featureDetailData: object },
    payload: object,
  ) {
    state.featureDetailData = payload;
  },
};

const getters = {
  GET_selectedFeatureId: (state: { selectedFeatureId: string }) =>
    state.selectedFeatureId,
  GET_featureDetailData: (state: { featureDetailData: object }) =>
    state.featureDetailData,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
