import DemoThreeColumnImageSimple from "@/core/infrastructure/repository/demo/DemoThreeColumnImageSimple"
import hasData from "@/core/domain/specification/hasData"

export default class DemoThreeColumnImageSimpleService {
    protected demoThreeColumnImageSimple: DemoThreeColumnImageSimple

    constructor() {
        this.demoThreeColumnImageSimple = new DemoThreeColumnImageSimple()
    }

    async process() {
        const { data, totalCount } = await this.demoThreeColumnImageSimple.fetch()

        if (!hasData(totalCount)) {
            throw new TypeError("No data was provided")
        }

        return data
    }
}
