<template>
  <div>
    <section class="mt-[88px] lg:mt-0 bg-white in-page-banner h-screen">
      <div class="section-container">
        <suspense>
          <in-page-banner
            :bannerLabel="inPageBanner.bannerLabel"
            :bannerText="inPageBanner.bannerText"
            :textHighlight="inPageBanner.textHighlight"
            :bannerDescription="inPageBanner.subheaderText"
            :bannerImage="inPageBanner.imagePanel"
          />
        </suspense>
      </div>
    </section>

    <section class="w-full">
      <div class="section-content-mt-[1px]">
        <div class="section-container callout pb-[50px]">
          <suspense>
            <call-out-block
              textLocation="normal"
              :calloutText="callout.calloutText"
              :calloutHighlight="callout.calloutHighlight"
              :calloutDescription="callout.callOutDescription"
              :calloutImage="callout.calloutImage"
              :isCTAEnabled="callout.ctaEnabled"
              :ctaText="callout.ctaText"
              :ctaRedirection="callout.ctaRedirection"
            />
          </suspense>
        </div>
      </div>
    </section>

    <section class="bg-neutral-5">
      <img
        src="/public/images/reversed_content_wave_top_xl_white.png"
        class="w-full"
        alt="Podiem Website"
      />
      <div class="section-container py-[80px]">
        <suspense>
          <two-column
            :bannerText="twoColumn.bannerText"
            :columnItems="twoColumn.twoColumnItems"
          />
        </suspense>
      </div>
    </section>

    <section class="bg-white">
      <div class="section-container py-[80px]">
        <suspense>
          <two-column-plain
            :bannerText="twoColumnPlain.bannerText"
            :columnItems="twoColumnPlain.twoColumnItems"
          />
        </suspense>
      </div>
    </section>

    <!-- <section class="bg-white"> 
            <div class="section-container py-[80px]">
                <template v-if="companyImagePanel.imagePanel">
                    <suspense>
                        <img
                            :src="parseSanityImage(companyImagePanel.imagePanel)" 
                            :alt="companyImagePanel.imagePanel!.alt"
                        /> 
                    </suspense>
                </template>
            </div>
        </section> -->

    <section class="bg-neutral-5">
      <img
        src="/public/images/white_top_wave.png"
        class="w-full"
        alt="Podiem Website"
      />

      <div class="section-container py-[40px]">
        <suspense>
          <plain-call-out-block
            textLocation="normal"
            :bannerText="simpleCallout.bannerText"
            :textHighlight="simpleCallout.textHighlight"
            :calloutImage="simpleCallout.imagePanel"
          />
        </suspense>
      </div>
    </section>

    <section class="bg-neutral-5">
      <div class="footer-callout-container py-[40px]">
        <suspense>
          <footer-call-out-block
            :bannerText="footerCallout.bannerText"
            :bannerTextHighlight="footerCallout.textHighlight"
            :bannerImage="footerCallout.calloutImage"
            :ctaDetails="footerCallout.ctaDetails"
          />
        </suspense>
      </div>
    </section>
  </div>
</template>
<script lang="ts">
import { reactive, onMounted } from "vue";
import InPageBanner from "@/components/molecule/InPageBanner.vue";
import TwoColumn from "@/components/molecule/TwoColumn.vue";
import TwoColumnPlain from "@/components/molecule/TwoColumnPlain.vue";
import FooterCallOutBlock from "@/components/molecule/FooterCallOutBlock.vue";
import PlainCallOutBlock from "@/components/molecule/PlainCallOutBlock.vue";
import { parseSanityImage } from "@/js/composable/parseSanityImage";
import CallOutBlock from "@/components/molecule/CallOutBlock.vue";

import Company from "@/core/application/Company";
import { CompanyPageModel } from "@/core/domain/model/CompanyPageModel";

export default {
  name: "Company",
  components: {
    CallOutBlock,
    FooterCallOutBlock,
    PlainCallOutBlock,
    InPageBanner,
    TwoColumn,
    TwoColumnPlain,
  },
  setup() {
    const inPageBanner = reactive({
      bannerLabel: "",
      bannerText: "",
      subheaderText: "",
      textHighlight: "",
      imagePanel: {},
    });

    const callout = reactive({
      calloutText: "",
      calloutHighlight: "",
      callOutDescription: "",
      calloutImage: {},
      ctaEnabled: false,
      ctaText: "",
      ctaRedirection: "",
    });

    const twoColumn = reactive({
      bannerText: "",
      twoColumnItems: {},
    });

    const twoColumnPlain = reactive({
      bannerText: "",
      twoColumnItems: {},
    });

    const companyImagePanel = reactive({
      imagePanel: {
        _type: "image",
        asset: {
          _ref: "image-1bb3b2528b88185c6639c6469c888b67c58f4c7d-472x462-png",
          _type: "reference",
        },
        alt: "",
      },
      imageAlt: "",
    });

    const simpleCallout = reactive({
      bannerText: "",
      textHighlight: "",
      imagePanel: {},
    });

    const footerCallout = reactive({
      bannerText: "",
      textHighlight: "",
      calloutImage: {},
      ctaDetails: {},
    });

    const company = new Company();
    const getAllData = company.getAllData();
    getAllData.then((data: CompanyPageModel) => {
      inPageBanner.bannerLabel = data.inPageBanner.banner_label!;
      inPageBanner.bannerText = data.inPageBanner.bannerText!;
      inPageBanner.subheaderText = data.inPageBanner.subheaderText!;
      inPageBanner.textHighlight = data.inPageBanner.text_highlight!;
      inPageBanner.imagePanel = data.inPageBanner.imagePanel!;

      callout.calloutText = data.callout.bannerText!;
      callout.calloutHighlight = data.callout.text_highlight!;
      callout.callOutDescription = data.callout.subheaderText!;
      callout.calloutImage = data.callout.image!;
      callout.ctaEnabled = data.callout.cta_enabled!;
      callout.ctaText = data.callout.cta_text!;
      callout.ctaRedirection = data.callout.cta_redirection!;

      twoColumn.bannerText = data.twoColumn.bannerText!;
      twoColumn.twoColumnItems = data.twoColumn.twoColumnSection!;

      twoColumnPlain.bannerText = data.twoColumnPlain.bannerText!;
      twoColumnPlain.twoColumnItems = data.twoColumnPlain.twoColumnSection!;

      companyImagePanel.imagePanel = data.companyImagePanel.imagePanel!;
      companyImagePanel.imageAlt = data.companyImagePanel.image_alt!;

      simpleCallout.bannerText = data.companySimpleCallout.bannerText!;
      simpleCallout.textHighlight = data.companySimpleCallout.text_highlight!;
      simpleCallout.imagePanel = data.companySimpleCallout.imagePanel!;

      footerCallout.bannerText = data.companyFooterCallout.bannerText!;
      footerCallout.textHighlight = data.companyFooterCallout.text_highlight!;
      footerCallout.calloutImage = data.companyFooterCallout.image!;
      footerCallout.ctaDetails = data.companyFooterCallout.cta_details!;
    });

    return {
      inPageBanner,
      callout,
      twoColumn,
      twoColumnPlain,
      simpleCallout,
      footerCallout,
      companyImagePanel,
      parseSanityImage,
    };
  },
};
</script>
