<template lang="">
  <h3 class="text-primary-1 text-heading-sm mb-[20px]">
    {{ text }}
  </h3>
</template>
<script lang="ts">
export default {
  name: "InPageBannerLabel",
  props: {
    text: {
      type: String,
      default: "Sample Label",
    },
  },
};
</script>
<style lang=""></style>
