import QueryBuilder from "../QueryBuilder"

export default class BlogArticleRepository {
    constructor(protected contentName: string) {}

    async fetch() {
        const query = `*[_type == 'blogResource'][0].blogs[] {
            _key,
            bannerText,
            'highlightedText': text_highlight,
            'image': imagePanel,
            'blogCategory': articleInfo.category->slug.current,
            'articleInfo': articleInfo {
                datePosted,
                publishedBy,
                readingTime,
                blogContent,
                tags[]-> {
                title,
                'slug': slug.current
                }
            }
        }`;
        
        const results = await new QueryBuilder()
            .rawQuery(query)
            .process()

        return results.getResultInfo()
    }
}
