<template>
  <div
    v-if="calloutText"
    class="callout-block bg-white/80 w-full rounded-call-out z-10"
    v-motion-fade
  >
    <div
      class="flex flex-col gap-y-14 lg:flex-row xl:gap-x-20 lg:gap-x-10 items-center justify-center"
    >
      <div
        class="overflow-hidden w-full max-w-[435px] max-h-[435px] lg:min-w-[435px] lg:min-h-[435px] flex items-center"
      >
        <img
          width="640"
          height="360"
          class="w-full h-full"
          :src="parseSanityImage(calloutImage)"
          :alt="calloutImage.alt"
        />
      </div>

      <div class="flex flex-col">
        <heading-with-highlights
          :textSize="textSize"
          :textLocation="textLocation"
          :text="calloutText"
          :highlight="calloutHighlight"
        />
        <div class="space-20px"></div>
        <header-description
          :textContent="calloutDescription"
        ></header-description>

        <template v-if="isCTAEnabled">
          <div class="space-20px"></div>
          <button-default :buttonText="ctaText" :redirection="ctaRedirection" />
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { toRefs } from "vue";
import HeadingWithHighlights from "../atom/text/HeadingWithHighlights.vue";
import HeaderDescription from "../atom/text/HeaderDescription.vue";
import { parseSanityImage } from "@/js/composable/parseSanityImage";
import CalloutLoading from "../atom/loading/CalloutLoading.vue";
import ButtonDefault from "../atom/button/ButtonDefault.vue";

export default {
  name: "CallOutBlock",
  components: {
    HeadingWithHighlights,
    HeaderDescription,
    CalloutLoading,
    ButtonDefault,
  },
  props: {
    calloutText: {
      type: String,
      default: "One workspace built by sales teams",
    },
    calloutHighlight: {
      type: String,
      default: "One workspace",
    },
    calloutDescription: {
      type: String,
      default:
        "A gamified platform with smarts and visibility for XDRS (SDRs, LDRs, BDRs) to reach sales targets.",
    },
    calloutImage: {
      type: Object,
      default: {
        _type: "image",
        asset: {
          _ref: "image-1bb3b2528b88185c6639c6469c888b67c58f4c7d-472x462-png",
          _type: "reference",
        },
      },
    },
    imageAlt: {
      type: String,
      default: "Podiem Website",
    },
    textLocation: {
      type: String,
      default: "home",
    },
    isCTAEnabled: {
      type: Boolean,
      default: false,
    },
    textSize: {
      type: String,
      default: "xl",
    },
    ctaText: {
      type: String,
      default: "CTA Sample",
    },
    ctaRedirection: {
      type: String,
      default: "#",
    },
  },
  async setup(props: any) {
    const {
      calloutText,
      calloutHighlight,
      calloutDescription,
      calloutImage,
      textLocation,
      imageAlt,
      isCTAEnabled,
      textSize,
      ctaText,
      ctaRedirection,
    } = toRefs(props);

    return {
      calloutText,
      calloutHighlight,
      calloutDescription,
      calloutImage,
      textLocation,
      imageAlt,
      parseSanityImage,
      isCTAEnabled,
      textSize,
      ctaText,
      ctaRedirection,
    };
  },
};
</script>

<style scoped>
.callout-block {
  width: 100%;
  padding: 60px 35px;
  box-shadow: 0px 12px 50px rgba(0, 0, 0, 0.12);
}

@media only screen and (min-width: 1440px) {
  .callout-block {
    max-width: 1400px;
    margin: 0 auto;
    padding: 60px 100px;
  }
}
</style>
