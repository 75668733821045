import FaqFooterCallout from "@/core/infrastructure/repository/faq/FaqFooterCallout"
import hasData from "@/core/domain/specification/hasData"

export default class FaqFooterCalloutService {
    faqFooterCallout: FaqFooterCallout

    constructor() {
        this.faqFooterCallout = new FaqFooterCallout()
    }

    async process() {
        const { data, totalCount } = await this.faqFooterCallout.fetch()

        if (!hasData(totalCount)) {
            throw new TypeError("No data was provided")
        }

        return data
    }
}
