<template>
  <div>
    <section class="in-page-banner2 xl:-mt-[40px] fhd:h-[40vh]">
      <div
        class="section-container md:pt-[30px] lg:pt-[60px] xl:pt-[100px] fhd:pt-[170px]"
      >
        <simple-banner-title
          :pageTitle="faqBanner.bannerText"
          :customClass="'mb-[20px]'"
        />

        <template v-if="faqBanner.simpleBlockContent">
          <div class="flex flex-col items-center justify-center">
            <p
              v-for="(value, id) in faqBanner.simpleBlockContent"
              :key="id"
              class="m-0 text-neutral-2 leading-none text-center mb-[5px] prose"
            >
              <sanity-blocks :blocks="[value]"></sanity-blocks>
            </p>
          </div>
        </template>
      </div>

      <div class="mb-[80px]"></div>

      <div class="tabs-container mx-auto px-[25px] md:px-0 pb-[100px]">
        <transition enter-active-class="animate__animated animate__fadeIn">
          <tabs :items="tabItems">
            <template v-if="loading">
              <full-text-loading :lines="4"></full-text-loading>
            </template>
            <template v-else>
              <accordion :data="faqItems"></accordion>
            </template>
          </tabs>
        </transition>
      </div>
    </section>

    <!-- <section class="w-full md:-mt-[80px] lg:-mt-[50px]">
            <img src="@/assets/images/gray_top_wave.png" class="w-full" />
            <div class="bg-neutral-5">
                
            </div>
        </section> -->

    <section v-if="footerCalloutInfo.bannerText" class="bg-neutral-5">
      <div class="footer-callout-container">
        <footer-call-out-block
          :bannerText="footerCalloutInfo.bannerText"
          :bannerTextHighlight="footerCalloutInfo.textHighlight"
          :bannerImage="footerCalloutInfo.bannerImage"
          :ctaDetails="footerCalloutInfo.ctaDetails"
        />
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import { ref, computed, onMounted, watch, reactive } from "vue";
import { useStore } from "vuex";
import { TAB_ITEMS } from "../js/BaseConstants";
import { SanityBlocks } from "sanity-blocks-vue-component";
import SimpleBannerTitle from "@/components/atom/text/SimpleBannerTitle.vue";
import SimpleBannerDescription from "@/components/atom/text/SimpleBannerDescription.vue";
import Tabs from "@/components/molecule/Tabs.vue";
import Accordion from "@/components/molecule/Accordion.vue";
import FullTextLoading from "@/components/atom/loading/FullTextLoading.vue";
import FooterCallOutBlock from "@/components/molecule/FooterCallOutBlock.vue";
import Faq from "@/core/application/Faq";
import FaqItemService from "@/core/application/service/faq/FaqItemService";
import FaqPageModel from "@/core/domain/model/FaqPageModel";

export default {
  name: "Faq",
  components: {
    SimpleBannerTitle,
    SimpleBannerDescription,
    Tabs,
    Accordion,
    FullTextLoading,
    FooterCallOutBlock,
    SanityBlocks,
  },
  setup() {
    const store = useStore();
    const loading = ref(true);
    const faqItems = computed(() => store.getters["faq/GET_faqItems"]);

    const faqBanner = reactive({
      bannerText: "",
      subheaderText: "",
      simpleBlockContent: {},
    });

    const footerCalloutInfo = reactive({
      bannerText: "",
      textHighlight: "",
      bannerImage: {},
      ctaDetails: {},
    });

    const faq = new Faq();
    const getAllData = faq.getAllData();
    getAllData.then((data: FaqPageModel) => {
      console.info(data);
      faqBanner.bannerText = data.faqBanner.bannerText!;
      faqBanner.subheaderText = data.faqBanner.subheaderText!;
      faqBanner.simpleBlockContent = data.faqBanner.simpleBlockContent!;

      store.dispatch("faq/setFaqItems", data.faqItem);

      footerCalloutInfo.bannerText = data.faqFooterCallout.bannerText!;
      footerCalloutInfo.textHighlight = data.faqFooterCallout.text_highlight!;
      footerCalloutInfo.bannerImage = data.faqFooterCallout.image!;
      footerCalloutInfo.ctaDetails = data.faqFooterCallout.cta_details!;
    });

    onMounted(async () => {
      setTimeout(() => {
        loading.value = false;
      }, 1000);
      watch(
        () => store.getters["faq/GET_activeSchemaId"],
        (value) => {
          loading.value = true;
          const faqService = new FaqItemService(value);
          const faqItems = faqService.process();
          faqItems.then((items: any) => {
            store.dispatch("faq/setFaqItems", items);
            setTimeout(() => {
              loading.value = false;
            }, 1000);
          });
        },
      );
    });

    return {
      tabItems: TAB_ITEMS,
      loading,
      faqBanner,
      footerCalloutInfo,
      faqItems,
    };
  },
};
</script>

<style scoped>
.tabs-container {
  width: 100%;
}

@media only screen and (min-width: 1024px) {
  .tabs-container {
    padding-inline: 100px;
    max-width: 1130px;
    /* width: 900px;
        margin: 0 auto; */
  }
}

@media only screen and (min-width: 1440px) {
  .tabs-container {
    width: 1130px;
    margin: 0 auto;
    padding-inline: 0;
  }
}
</style>
