import WhoWeAreTwoColumnPlainRepository from "@/core/infrastructure/repository/company/WhoWeAreTwoColumnPlainRepository"
import hasData from "@/core/domain/specification/hasData"

export default class WhoWeAreTwoColumnPlainService {
    whoWeAreTwoColumnPlainRepository: WhoWeAreTwoColumnPlainRepository

    constructor() {
        this.whoWeAreTwoColumnPlainRepository =
      new WhoWeAreTwoColumnPlainRepository()
    }

    async process() {
        const { data, totalCount } =
      await this.whoWeAreTwoColumnPlainRepository.fetch()

        if (!hasData(totalCount)) {
            throw new TypeError("No data was provided")
        }

        return data
    }
}
