<template>
  <button
    class="flex justify-center items-center rounded-full disabled:bg-disabled-1 disabled:text-disabled-text-1"
    :class="additionalClass"
  >
    <slot></slot>
  </button>
</template>

<script lang="ts">
import { toRefs, ref } from "vue";
export default {
  name: "ButtonIcon",
  props: {
    themes: {
      type: String,
      default: "normal",
    },
  },
  setup(props: any) {
    const { themes } = toRefs(props);
    const additionalClass = ref("bg-primary-1 text-white hover:bg-secondary");

    if (themes.value === "light") {
      additionalClass.value = "bg-white text-gray-700 hover:bg-gray-200";
    }

    return {
      additionalClass,
    };
  },
};
</script>

<style scoped>
button {
  width: 40px;
  height: 40px;
  padding: 11px;
}

button:disabled {
  cursor: not-allowed;
}

:disabled::v-deep(path) {
  fill: #939597;
}
</style>
