<template>
  <div>
    <div class="relative">
      <select
        :id="id"
        :disabled="disabled"
        :placeholder="placeholderText"
        class="w-full bg-neutral-4 rounded-custom-input form-select focus:bg-white block hover:border-primary-1 hover:cursor-pointer"
        @input="updateValue($event)"
        v-model="selection"
        :class="[
          { 'focus:ring-0 border-error-1 hover:border-error-1': hasError },
          { 'border-neutral-4': !hasError },
          { 'pointer-events-none': disabled },
          { 'has-selected': selection !== '' },
        ]"
      >
        <option value="" hidden>{{ placeholderText }}</option>
        <option v-for="value in data" :value="value">
          {{ value }}
        </option>
      </select>
      <div
        @click="animatePlaceholder(id)"
        v-if="selection !== ''"
        class="select-selected p-0 m-0 leading-none absolute top-3 left-3 transition-all duration-100 text-neutral-2"
      >
        {{ placeholderText }}
      </div>
    </div>
    <div v-if="hasError" class="flex flex-row items-center mt-1 gap-x-1 pl-2">
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.663 1.67065C7.26851 0.509525 8.7311 0.509524 9.33661 1.67065L14.9752 12.4833C15.5987 13.6788 14.8502 15.1998 13.6384 15.1998H2.36118C1.1494 15.1998 0.400923 13.6788 1.02438 12.4833L6.663 1.67065ZM7.1998 9.1998V5.1998H8.7998V9.1998H7.1998ZM7.9998 12.7998C7.33706 12.7998 6.7998 12.2625 6.7998 11.5998C6.7998 10.9371 7.33706 10.3998 7.9998 10.3998C8.66255 10.3998 9.1998 10.9371 9.1998 11.5998C9.1998 12.2625 8.66255 12.7998 7.9998 12.7998Z"
          fill="#EB5757"
        />
      </svg>
      <p class="error-message text-error-1">{{ errorMessage }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import { toRefs, ref } from "vue";

const updateInput = "update:modelValue";
export default {
  name: "SelectField",
  props: {
    id: {
      type: String,
    },
    placeholderText: {
      type: String,
      default: "Label",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: "Please Enter valid entry",
    },
    data: {
      type: Array,
      default: [],
    },
  },
  emits: [updateInput],
  setup(props: any, { emit }: any) {
    const { data, placeholderText } = toRefs(props);

    const updateValue = (event: any) => {
      const target = event.target as HTMLInputElement;
      emit(updateInput, target.value);
    };

    const selection = ref("");

    const animatePlaceholder = (inputId: string = "") => {
      const input = document.getElementById(inputId);
      console.info(input?.focus());
      // input?.click()
    };

    return {
      data,
      updateValue,
      selection,
      animatePlaceholder,
      placeholderText,
    };
  },
};
</script>

<style scoped>
span {
  font-size: 16px;
}

select {
  padding-top: 12px;
  padding-inline: 12px;
  font-size: 16px;
  transition: all 0.2s ease;
  padding-right: 14px;
  color: #848b90;
}

select.has-selected {
  color: black !important;
  padding-top: 16px;
}

select::placeholder {
  color: transparent;
}

select option {
  color: black;
}
select option:first-child {
  color: #848b90;
}

select + div {
  top: 18px;
  /*  left: 10px; */
  opacity: 1;
  font-size: 1rem;
}

.select-selected {
  top: 5px !important;
  left: 10px !important;
  font-size: 0.8rem !important;
}

/* select:focus + div,
select:not(:placeholder-shown) + div
 {
    top: 3px;
    left: 10px;
    font-size: .8rem;
}

select:focus + div,
select:not(:placeholder-shown) + div
 {
    top: 3px;
    left: 10px;
    font-size: .8rem;
} */

.error-message {
  font-size: 14px;
}
</style>
