/*
<template>
  <dev-layout title="Components">
    <div class="mb-20" id="typography">
      <h2
        class="border-b border-b-gray-200 pb-2 mb-8 text-heading-sm dark:border-b-gray-500 dark:bg-custom-dark dark:text-white bg-gray-100 sticky top-24"
      >
        Typography
      </h2>
      <div class="components">
        <h2 class="mb-2">Size</h2>
        <div
          class="border bg-white dark:bg-gray-800 rounded-lg border-gray-100 shadow p-5 flex flex-col gap-5 mb-5"
        >
          <h1 class="text-heading-xs font-normal">Podiem</h1>
          <h1 class="text-heading-sm font-normal">Podiem</h1>
          <h1 class="text-heading-md font-normal">Podiem</h1>
          <h1 class="text-heading-lg font-normal">Podiem</h1>
          <h1 class="text-heading-xl font-normal">Podiem</h1>
        </div>

        <h2 class="mb-2">Weight</h2>
        <div
          class="border bg-white dark:bg-gray-800 rounded-lg border-gray-100 shadow p-5 flex flex-col gap-5 mb-5"
        >
          <h1 class="text-heading-xl apercu-light">Podiem</h1>
          <h1 class="text-heading-xl apercu-regular">Podiem</h1>
          <h1 class="text-heading-xl apercu-medium">Podiem</h1>
          <h1 class="text-heading-xl apercu-bold">Podiem</h1>

          <p class="apercu-light" title="font-weight: normal">
            ABCDEFGHIJKLMNOPQRSTUVWXYZ <br />
            abcdefghijklmnopqrstuvwxyz <br />
            0123456789 !@#$%^&*()
          </p>

          <p class="apercu-regular" title="font-weight: normal">
            ABCDEFGHIJKLMNOPQRSTUVWXYZ <br />
            abcdefghijklmnopqrstuvwxyz <br />
            0123456789 !@#$%^&*()
          </p>

          <p class="apercu-medium" title="font-weight: normal">
            ABCDEFGHIJKLMNOPQRSTUVWXYZ <br />
            abcdefghijklmnopqrstuvwxyz <br />
            0123456789 !@#$%^&*()
          </p>

          <p class="apercu-bold" title="font-weight: bold">
            ABCDEFGHIJKLMNOPQRSTUVWXYZ <br />
            abcdefghijklmnopqrstuvwxyz <br />
            0123456789 !@#$%^&*()
          </p>
        </div>
      </div>
    </div>

    <div class="mb-20" id="shadow">
      <h2
        class="border-b border-b-gray-200 pb-2 mb-8 text-heading-sm dark:border-b-gray-500 dark:bg-custom-dark dark:text-white sticky top-24 bg-gray-100"
      >
        Shadow
      </h2>
      <div class="components">
        <div
          class="border bg-white dark:bg-gray-800 rounded-lg border-gray-100 shadow p-5 flex flex-col gap-5 mb-5"
        >
          <div
            class="w-full p-3 text-gray-800 h-24 shadow-custom-1 dark:shadow-custom-dark-1"
          >
            Drop Shadow 01
          </div>
          <div
            class="w-full p-3 text-gray-800 h-24 shadow-custom-2 dark:shadow-custom-dark-2"
          >
            Drop Shadow 02
          </div>
        </div>
      </div>
    </div>

    <div class="mb-20" id="bradius">
      <h2
        class="border-b border-b-gray-200 pb-2 mb-8 text-heading-sm dark:border-b-gray-500 dark:bg-custom-dark dark:text-white sticky top-24 bg-gray-100"
      >
        Border Radius
      </h2>
      <div class="components">
        <div
          class="border bg-white dark:bg-gray-800 rounded-lg border-gray-100 shadow p-5 flex flex-col gap-5 mb-5"
        >
          <div class="flex flex-col justify-start items-start w-1/2">
            <div class="mb-2">
              <label for="checkbox" class="italic text-sm"
                >border-radius: 3px</label
              ><br />
              <input
                class="rounded-custom-checkbox"
                id="checkbox"
                type="checkbox"
              />
            </div>
            <div>
              <label for="input" class="italic text-sm"
                >border-radius: 4px</label
              ><br />
              <input
                class="border rounded-custom-input mb-2"
                id="input"
                type="text"
              /><br />
              <button class="border py-1 px-3 rounded-custom-button">
                Button
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mb-20" id="buttons">
      <h2
        class="border-b border-b-gray-200 pb-2 mb-8 text-heading-sm dark:border-b-gray-500 dark:bg-custom-dark dark:text-white sticky top-24 bg-gray-100"
      >
        Buttons
      </h2>
      <div class="components">
        <h2 class="mb-2">Default</h2>
        <div
          class="border bg-white dark:bg-gray-800 rounded-lg border-gray-100 shadow p-5 flex flex-col gap-5 mb-5"
        >
          <button-default
            aria-disabled="false"
            buttonText="Active Button"
          ></button-default>
          <button-default
            aria-disabled="true"
            disabled
            buttonText="Disabled Button"
          ></button-default>
        </div>

        <h2 class="mb-2">With Icon</h2>
        <div
          class="border bg-white dark:bg-gray-800 rounded-lg border-gray-100 shadow p-5 flex flex-col gap-5 mb-5"
        >
          <button-with-icon aria-disabled="false" buttonText="Button with Icon">
            <svg
              width="14"
              height="16"
              viewBox="0 0 14 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0.5 16V0L13.5 8.22857L0.5 16Z" fill="white" />
            </svg>
          </button-with-icon>

          <button-with-icon
            disabled
            aria-disabled="true"
            buttonText="Disabled button with Icon"
          >
            <svg
              width="14"
              height="16"
              viewBox="0 0 14 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0.5 16V0L13.5 8.22857L0.5 16Z" fill="white" />
            </svg>
          </button-with-icon>
        </div>

        <h2 class="mb-2">Outline</h2>
        <div
          class="border bg-white dark:bg-gray-800 rounded-lg border-gray-100 shadow p-5 flex flex-col gap-5 mb-5"
        >
          <button-outlined
            aria-disabled="false"
            buttonText="Button"
          ></button-outlined>
          <button-outlined
            disabled
            aria-disabled="true"
            buttonText="Button"
          ></button-outlined>
        </div>

        <h2 class="mb-2">Icon</h2>
        <div
          class="border bg-white dark:bg-gray-800 rounded-lg border-gray-100 shadow p-5 flex flex-col gap-5 mb-5"
        >
          <button-icon aria-disabled="false">
            <svg
              width="14"
              height="16"
              viewBox="0 0 14 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0.5 16V0L13.5 8.22857L0.5 16Z" fill="white" />
            </svg>
          </button-icon>

          <button-icon disabled aria-disabled="true">
            <svg
              width="14"
              height="16"
              viewBox="0 0 14 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0.5 16V0L13.5 8.22857L0.5 16Z" fill="white" />
            </svg>
          </button-icon>
        </div>

        <h2 class="mb-2">Link</h2>
        <div
          class="border bg-white dark:bg-gray-800 rounded-lg border-gray-100 shadow p-5 flex flex-col gap-5 mb-5"
        >
          <link-default linkText="Link"></link-default>
          <link-default linkText="Link" :isDisabled="true"></link-default>
        </div>

        <h2 class="mb-2">Link with Icon</h2>
        <div
          class="border bg-white dark:bg-gray-800 rounded-lg border-gray-100 shadow p-5 flex flex-col gap-5 mb-5"
        >
          <link-with-icon linkText="Link">
            <svg
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="24" height="24" rx="12" fill="transparent" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.1929 17.8984L9.06152 16.7671L13.9958 11.8328L9.06152 6.89844L10.1929 5.76707L16.2586 11.8328L10.1929 17.8984Z"
                fill="#008AFE"
              />
            </svg>
          </link-with-icon>
          <link-with-icon linkText="Link" :isDisabled="true">
            <svg
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="24" height="24" rx="12" fill="transparent" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.1929 17.8984L9.06152 16.7671L13.9958 11.8328L9.06152 6.89844L10.1929 5.76707L16.2586 11.8328L10.1929 17.8984Z"
                fill="#008AFE"
              />
            </svg>
          </link-with-icon>
        </div>
      </div>
    </div>

    <div class="mb-20" id="inputs">
      <h2
        class="border-b border-b-gray-200 pb-2 mb-8 text-heading-sm dark:border-b-gray-500 dark:bg-custom-dark dark:text-white sticky top-24 bg-gray-100"
      >
        Inputs
      </h2>
      <div class="components">
        <div
          class="border bg-white dark:bg-gray-800 rounded-lg border-gray-100 shadow p-5 flex flex-col gap-5 mb-5"
        >
          <input-field placeholderText="Default Input"></input-field>
          <input-field
            :hasError="true"
            placeholderText="Error Input"
          ></input-field>
          <input-field
            :disabled="true"
            placeholderText="Disabled Input"
          ></input-field>
        </div>
      </div>
    </div>

    <div class="mb-20" id="selects">
      <h2
        class="border-b border-b-gray-200 pb-2 mb-8 text-heading-sm dark:border-b-gray-500 dark:bg-custom-dark dark:text-white sticky top-24 bg-gray-100"
      >
        Select
      </h2>
      <div class="components">
        <div
          class="border bg-white dark:bg-gray-800 rounded-lg border-gray-100 shadow p-5 flex flex-col gap-5 mb-5"
        >
          <select-field placeholderText="Default dropdown"></select-field>
          <select-field
            :has-error="true"
            placeholderText="Has errors"
            errorMessage="You have selected incorrect option"
          ></select-field>
          <select-field
            :disabled="true"
            placeholderText="Disabled dropdown"
          ></select-field>
        </div>
      </div>
    </div>

    <div class="mb-20" id="headings">
      <h2
        class="border-b border-b-gray-200 pb-2 mb-8 text-heading-sm dark:border-b-gray-500 dark:bg-custom-dark dark:text-white sticky top-24 bg-gray-100"
      >
        Heading
      </h2>
      <div class="components">
        <div
          class="border bg-white dark:bg-gray-800 rounded-lg border-gray-100 shadow p-5 flex flex-col gap-5 mb-5"
        >
          <heading-with-highlights>
            Boost sales efficiency and
            <span class="highlight">empower</span> your inside sales teams
          </heading-with-highlights>

          <heading-with-highlights>
            <span class="highlight">One workspace</span> built by sales teams
          </heading-with-highlights>
        </div>
      </div>
    </div>

    <div class="mb-20" id="tabs">
      <h2
        class="border-b border-b-gray-200 pb-2 mb-8 text-heading-sm dark:border-b-gray-500 dark:bg-custom-dark dark:text-white sticky top-24 bg-gray-100"
      >
        Tabs
      </h2>
      <div class="components">
        <div
          class="border bg-white dark:bg-gray-800 rounded-lg border-gray-100 shadow p-5 flex flex-col gap-5 mb-5"
        >
          <tabs></tabs>
          <tabs :activeMenu="2"></tabs>
        </div>
      </div>
    </div>

    <div class="mb-20" id="accordion">
      <h2
        class="border-b border-b-gray-200 pb-2 mb-8 text-heading-sm dark:border-b-gray-500 dark:bg-custom-dark dark:text-white sticky top-24 bg-gray-100"
      >
        Accordion
      </h2>
      <div class="components">
        <div
          class="border bg-white dark:bg-gray-800 rounded-lg border-gray-100 shadow p-5 flex flex-col gap-5 mb-5"
        >
          <accordion></accordion>
        </div>
      </div>
    </div>

    <div class="mb-20" id="horizontal-scroll">
      <h2
        class="border-b border-b-gray-200 pb-2 mb-8 text-heading-sm dark:border-b-gray-500 dark:bg-custom-dark dark:text-white sticky top-24 bg-gray-100"
      >
        Horizontal Scroll
      </h2>
      <div class="components">
        <div
          class="border bg-white dark:bg-gray-800 rounded-lg border-gray-100 shadow p-5 flex flex-col gap-5 mb-5"
        >
          <horizontal-scroll></horizontal-scroll>
        </div>
      </div>
    </div>

    <div class="mb-20" id="callout">
      <h2
        class="border-b border-b-gray-200 pb-2 mb-8 text-heading-sm dark:border-b-gray-500 dark:bg-custom-dark dark:text-white sticky top-24 bg-gray-100"
      >
        Callout Block
      </h2>
      <div class="components">
        <div
          class="border multiple-bg-color dark:bg-gray-800 rounded-lg border-gray-100 shadow p-5 flex flex-col gap-5 mb-5"
        >
          <call-out-block :imageSource="imageSource"></call-out-block>
          <footer-call-out-block
            :imageSource="imageSource2"
          ></footer-call-out-block>
        </div>
      </div>
    </div>

    <div class="mb-20" id="threecolumns">
      <h2
        class="border-b border-b-gray-200 pb-2 mb-8 text-heading-sm dark:border-b-gray-500 dark:bg-custom-dark dark:text-white sticky top-24 bg-gray-100"
      >
        Three Columns
      </h2>
      <div class="components">
        <div
          class="border bg-white dark:bg-gray-800 rounded-lg border-gray-100 shadow p-5 flex flex-col gap-5 mb-5"
        >
          <three-column></three-column>
        </div>
      </div>
    </div>
  </dev-layout>
</template>

<script lang="ts">
import { onUnmounted } from "vue";
import { useStore } from "vuex";
import { useDark, useToggle } from "@vueuse/core";
import DevLayout from "../components/template/DevLayout.vue";

import ButtonDefault from "../components/atom/button/ButtonDefault.vue";
import ButtonOutlined from "../components/atom/button/ButtonOutlined.vue";
import ButtonWithIcon from "../components/atom/button/ButtonWithIcon.vue";
import ButtonIcon from "../components/atom/button/ButtonIcon.vue";

import LinkDefault from "../components/atom/link/LinkDefault.vue";
import LinkWithIcon from "../components/atom/link/LinkWithIcon.vue";
import LinkIcon from "../components/atom/link/LinkIcon.vue";

import InputField from "../components/atom/input/InputField.vue";
import SelectField from "../components/atom/input/SelectField.vue";

import HeadingWithHighlights from "../components/atom/text/HeadingWithHighlights.vue";

import Tabs from "../components/molecule/Tabs.vue";
import Accordion from "../components/molecule/Accordion.vue";
import HorizontalScroll from "../components/molecule/HorizontalScroll.vue";

import CallOutBlock from "../components/molecule/CallOutBlock.vue";
import FooterCallOutBlock from "../components/molecule/FooterCallOutBlock.vue";

import ThreeColumn from "../components/molecule/ThreeColumn.vue";

import { CALLOUT_IMAGE_SOURCE } from "../js/BaseConstants";

export default {
  name: "ComponentList",
  components: {
    DevLayout,
    ButtonDefault,
    ButtonOutlined,
    ButtonWithIcon,
    ButtonIcon,
    LinkDefault,
    LinkWithIcon,
    LinkIcon,
    InputField,
    SelectField,
    HeadingWithHighlights,
    Tabs,
    Accordion,
    HorizontalScroll,
    CallOutBlock,
    FooterCallOutBlock,
    ThreeColumn,
  },
  setup() {
    return {
      imageSource: CALLOUT_IMAGE_SOURCE + "home_callout_1-min.png",
      imageSource2: CALLOUT_IMAGE_SOURCE + "footer_callout_1.png",
    };
    // const isDark = useDark();
    // const toggleDark = useToggle();

    // // const store = useStore();

    // // store.dispatch('navigation/toggleNavbarOpaqueStatus', true);

    // // onUnmounted(() => {
    // //     store.dispatch('navigation/toggleNavbarOpaqueStatus', false);
    // // })
  },
};
</script>

<style scoped></style>
*/
