import PricingBasicSubscription from "@/core/infrastructure/repository/pricing/PricingBasicSubscription"
import PricingProfessionalSubscription from "@/core/infrastructure/repository/pricing/PricingProfessionalSubscription"
import PricingCustomSubscription from "@/core/infrastructure/repository/pricing/PricingCustomSubscription"
import hasData from "@/core/domain/specification/hasData"

export default class PricingSubscriptionService {
    pricingBasicSubscription: PricingBasicSubscription
    pricingProfessionalSubscription: PricingProfessionalSubscription
    pricingCustomSubscription: PricingCustomSubscription

    constructor() {
        this.pricingBasicSubscription = new PricingBasicSubscription()
        this.pricingProfessionalSubscription =
      new PricingProfessionalSubscription()
        this.pricingCustomSubscription = new PricingCustomSubscription()
    }

    async process() {
        const basic = await this.pricingBasicSubscription.fetch()
        const professional = await this.pricingProfessionalSubscription.fetch()
        const custom = await this.pricingCustomSubscription.fetch()

        if (
            !hasData(basic.totalCount) &&
      !hasData(professional.totalCount) &&
      !hasData(custom.totalCount)
        ) {
            throw new TypeError("No data was provided")
        }

        const subscription = [basic.data, professional.data, custom.data]

        return subscription
    }
}
