import BlogArticleModel from "@/core/domain/model/BlogArticleModel"

export default class ResourcesFilterCategory {
    protected selectedCategory = ""
    protected articles: BlogArticleModel[] = []
    protected keywords = ""

    setCategory(selectedCategory: string) {
        this.selectedCategory = selectedCategory
        return this
    }

    setArticles(articles: BlogArticleModel[]) {
        this.articles = articles
        return this
    }

    setKeywords(keywords: string) {
        this.keywords = keywords
        return this
    }

    filter() {
        const categoryId = this.selectedCategory
        const articles = this.articles
        const keywords = this.keywords

        const filtered = articles
            .map((article, key) => {
                const bannerText = article.bannerText.toLowerCase()
                const categoryMatched = categoryId === article.blogCategory
                const keywordsMatched = bannerText.includes(keywords.toLowerCase())

                if (categoryId === "all") {
                    if (keywordsMatched) {
                        return articles[key]
                    }
                } else {
                    if (categoryMatched && keywordsMatched) {
                        return articles[key]
                    }
                }

                return null
            })
            .filter(Boolean)

        return filtered
    }
}
