<template>
  <a
    :href="linkRedirection"
    :class="{ 'disabled-link': isDisabled }"
    class="flex flex-row items-center gap-x-2 w-fit text-primary-1 disabled:text-disabled-text-1 dark:text-white"
  >
    {{ linkText }}
    <slot></slot>
  </a>
</template>

<script lang="ts">
export default {
  name: "LinkWithIcon",
  props: {
    linkText: {
      type: String,
    },
    linkRedirection: {
      type: String,
      default: "javascript:void(0)",
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.dark a::v-deep(rect) {
  fill: transparent;
}

.dark a::v-deep(path) {
  fill: white;
}

.dark a:hover::v-deep(rect) {
  fill: white;
}

.dark a:hover::v-deep(path) {
  fill: #008afe;
}

:hover::v-deep(rect) {
  fill: #008afe;
}

:hover::v-deep(path) {
  fill: white;
}

.disabled-link {
  color: #939597;
  cursor: not-allowed;
  pointer-events: none;
}

.disabled-link::v-deep(path) {
  fill: #939597;
}

.dark .disabled-link {
  color: #939597;
  cursor: not-allowed;
  pointer-events: none;
}

.dark .disabled-link::v-deep(path) {
  fill: #939597;
}
</style>
