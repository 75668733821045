<template>
  <div
    v-if="isNavbarVisible"
    class="w-full custom-nav flex items-center z-50 transition-all duration-500"
    :class="data.additionalClass"
  >
    <nav>
      <div
        class="max-w-7xl mx-auto px-5 sm:px-6 md:px-[80px] xl:px-0"
      >
        <div class="relative flex items-center justify-between h-16 mobile-nav">
          <!-- Brand -->
          <brand></brand>

          <div class="absolute inset-y-0 right-0 flex items-center lg:hidden">
            <nav-mobile-icon></nav-mobile-icon>
          </div>

          <!-- Large Menu -->
          <nav-menu-group></nav-menu-group>
        </div>
      </div>

      <!-- Mobile Menu -->
      <nav-menu-group-mobile></nav-menu-group-mobile>
    </nav>
  </div>
</template>

<script lang="ts">
import { ref, reactive, onUnmounted, computed } from "vue";
import { useStore } from "vuex";

import PodiemTextIcon from "@/components/icons/PodiemTextIcon.vue";
import PodiemTextIconBlue from "@/components/icons/PodiemTextIconBlue.vue";
import Brand from "@/components/molecule/Brand.vue";
import NavMobileIcon from "@/components/atom/button/NavMobileIcon.vue";
import NavMenuGroup from "@/components/molecule/NavMenuGroup.vue";
import NavMenuGroupMobile from "@/components/molecule/NavMenuGroupMobile.vue";

import * as utils from "@/js/utils";

export default {
  name: "NavMenu",
  props: {
    navOption: {
      type: String,
      default: "opaque",
    },
  },
  components: {
    Brand,
    NavMobileIcon,
    NavMenuGroup,
    NavMenuGroupMobile,
    PodiemTextIcon,
    PodiemTextIconBlue,
  },
  setup() {
    const store = useStore();
    const data = reactive({
      scrollPosition: 0,
      additionalClass: "",
    });

    const isNavbarOpaque = computed(
      () => store.getters["navigation/GET_navbarOpaqueStatus"],
    );
    const isNavbarVisible = computed(
      () => store.getters["navigation/GET_navbarVisibilityStatus"],
    );

    if (utils.isSmallScreen) {
      data.additionalClass = "bg-white shadow";
    }

    const updateScroll = () => {
      data.scrollPosition = window.scrollY;

      if (window.scrollY > 100) {
        store.dispatch("navigation/toggleNavbarOpaqueStatus", true);
        data.additionalClass = "bg-white shadow";
      } else {
        store.dispatch("navigation/toggleNavbarOpaqueStatus", false);
        data.additionalClass = "";

        if (utils.isSmallScreen) {
          data.additionalClass = "bg-white shadow";
        }
      }
    };
    window.addEventListener("scroll", updateScroll);

    onUnmounted(() => {
      window.removeEventListener("scroll", updateScroll);
    });

    return {
      data,
      isNavbarOpaque,
      isNavbarVisible,
    };
  },
};
</script>

<style scoped>
.custom-nav {
  min-height: 88px;
}

.mobile-nav {
  height: 88px;
}

nav {
  /* min-width: 1024px; */
  width: 1440px;
  margin: 0 auto;
  height: 100%;
}

.nav-opaque {
  background-color: white;
}
</style>
