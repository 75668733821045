import QueryBuilder from "../../QueryBuilder"

export default class BlogCategoryRepository {
    async fetch() {
        const results = await new QueryBuilder()
            .rawQuery(`*[_type == 'blogCategory'] { title, 'slug': slug.current }`)
            .process()

        return results.getResultInfo()
    }
}
