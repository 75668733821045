<template>
  <div v-motion-slide-visible-once-bottom>
    <div v-if="bannerText" class="three-column-section w-full">
      <div v-if="!isSimple" class="w-full mb-10">
        <heading-with-highlights
          :text="bannerText"
          :highlight="textHighlight"
        />
        <div class="space-20px"></div>
        <header-description :textContent="description" />
      </div>

      <div class="flex flex-col lg:flex-row lg:gap-x-10 gap-y-10 mb-10">
        <div v-for="(item, id) in columnItems" :key="id" class="flex-1">
          <template v-if="item.imagePanel">
            <img
              class="rounded-full overflow-hidden w-full max-w-[152px] max-h-[152px] mb-[20px]"
              :src="parseSanityImage(item.imagePanel)"
              :alt="item.imagePanel.alt"
            />
          </template>
          <column-heading :columnHeader="item.contentTitle" class="mb-[20px]" />

          <template v-if="item.contentText">
            <template v-if="!isSimple">
              <column-content>
                {{ item.contentText }}
              </column-content>
            </template>
            <template v-else>
              <column-content
                v-for="(value, key) in item.contentText"
                :key="key"
              >
                <sanity-blocks :blocks="[value]"></sanity-blocks>
              </column-content>
            </template>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { toRefs } from "vue";
import SmallHeading from "../atom/text/SmallHeading.vue";
import HeadingWithHighlights from "../atom/text/HeadingWithHighlights.vue";
import ColumnHeading from "../atom/text/ColumnHeading.vue";
import ColumnContent from "../atom/text/ColumnContent.vue";
import ButtonOutlined from "../atom/button/ButtonOutlined.vue";
import FullTextLoading from "../atom/loading/FullTextLoading.vue";
import HeaderDescription from "../atom/text/HeaderDescription.vue";
import { parseSanityImage } from "@/js/composable/parseSanityImage";
import { SanityBlocks } from "sanity-blocks-vue-component";

export default {
  name: "ThreeColumnImage",
  components: {
    SmallHeading,
    HeadingWithHighlights,
    ColumnHeading,
    ColumnContent,
    ButtonOutlined,
    FullTextLoading,
    HeaderDescription,
    SanityBlocks,
  },
  props: {
    bannerText: {
      type: String,
      default: "Boost sales efficiency and empower your inside sales teams",
    },
    textHighlight: {
      type: String,
      default: "empower",
    },
    description: {
      type: String,
      default: "Sample description",
    },
    columnItems: {
      type: Object,
      default: [
        {
          cta_redirection: "#",
          cta_text: "Book now",
          cta_enabled: true,
          contentTitle:
            "Build excitement for selling using gamification features",
          contentText:
            "Uplift productivity and boost morale with friendly competitions to keep inside sales teams hitting their KPIs.",
          imagePanel: {
            _type: "image",
            asset: {
              _ref: "image-1bb3b2528b88185c6639c6469c888b67c58f4c7d-472x462-png",
              _type: "reference",
            },
          },
        },
        {
          contentTitle:
            "Build excitement for selling using gamification features",
          contentText:
            "Uplift productivity and boost morale with friendly competitions to keep inside sales teams hitting their KPIs.",
          imagePanel: {
            _type: "image",
            asset: {
              _ref: "image-1bb3b2528b88185c6639c6469c888b67c58f4c7d-472x462-png",
              _type: "reference",
            },
          },
        },
        {
          contentTitle:
            "Build excitement for selling using gamification features",
          contentText:
            "Uplift productivity and boost morale with friendly competitions to keep inside sales teams hitting their KPIs.",
          imagePanel: {
            _type: "image",
            asset: {
              _ref: "image-1bb3b2528b88185c6639c6469c888b67c58f4c7d-472x462-png",
              _type: "reference",
            },
          },
        },
      ],
    },
    columnType: {
      type: String,
      default: "normal",
    },
  },
  async setup(props: any) {
    const { description, bannerText, textHighlight, columnItems, columnType } =
      toRefs(props);

    const isSimple = columnType.value === "simple";

    return {
      description,
      bannerText,
      textHighlight,
      columnItems,
      parseSanityImage,
      isSimple,
    };
  },
};
</script>

<style scoped>
.three-column-section {
  width: 100%;
}

@media only screen and (min-width: 1440px) {
  .three-column-section {
    /* max-width: 1130px; */
    max-width: 1400px;
    margin: 0 auto;
    /* padding: 60px 100px; */
  }
}
</style>
