/* eslint-disable @typescript-eslint/no-unsafe-call */
import { FooterCalloutInfoResult } from "@/core/domain/model/FooterCalloutInfoResult";
import { FooterCalloutModel } from "@/store/model/FooterCalloutModel";

const state = (): FooterCalloutModel => ({
  bannerText: "",
  highlightedText: "",
  image: {},
  ctaDetails: {},
});

const mutations = {
  SET_BANNER_TEXT(state: any, payload: string) {
    state.bannerText = payload;
  },
  SET_HIGHLIGHTED_TEXT(state: any, payload: string) {
    state.highlightedText = payload;
  },
  SET_IMAGE(state: any, payload: object) {
    state.image = payload;
  },
  SET_CTA_DETAILS(state: any, ctaDetails: object) {
    state.ctaDetails = ctaDetails;
  },
};

const actions = {
  setFooterCalloutInfo({ commit }: any, data: FooterCalloutInfoResult) {
    commit("SET_BANNER_TEXT", data.bannerText);
    commit("SET_HIGHLIGHTED_TEXT", data.text_highlight);
    commit("SET_IMAGE", data.image);
    commit("SET_CTA_DETAILS", data.cta_details);
  },
};

const getters = {
  GET_bannerText: (state: FooterCalloutModel) => state.bannerText,
  GET_highlightedText: (state: FooterCalloutModel) => state.highlightedText,
  GET_bannerImage: (state: FooterCalloutModel) => state.image,
  GET_ctaDetails: (state: FooterCalloutModel) => state.ctaDetails,
  GET_calloutFooterInfo: (state: FooterCalloutModel) => {
    return { ...state };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
