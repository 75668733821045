<template>
  <div v-if="bannerText" v-cloak v-motion-fade>
    <div
      class="py-[30px] lg:py-[100px] flex flex-col lg:flex-row items-center justify-center"
    >
      <div
        class="mb-[30px] lg:shrink-[2] lg:pr-[50px] lg:mb-0"
        v-motion
        :initial="{ opacity: 0, x: -100 }"
        :enter="{ opacity: 1, x: 0, scale: 1 }"
        :delay="200"
      >
        <in-page-banner-label :text="bannerLabel" />
        <heading-with-highlights
          :text="bannerText"
          :highlight="textHighlight"
        />
        <div class="space-20px"></div>
        <in-page-banner-description :text="bannerDescription" />
      </div>

      <div
        class="lg:pl-5"
        v-motion
        :initial="{ opacity: 0, x: 100 }"
        :enter="{ opacity: 1, x: 0, scale: 1 }"
        :delay="400"
      >
        <template v-if="bannerImage">
          <img
            :src="parseSanityImage(bannerImage)"
            :alt="bannerImage.alt"
            class="md:max-w-[500px] rounded-call-out"
          />
        </template>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { toRefs, PropType } from "vue";
import { parseSanityImage } from "@/js/composable/parseSanityImage";
import InPageBannerLabel from "../atom/text/InPageBannerLabel.vue";
import HeadingWithHighlights from "../atom/text/HeadingWithHighlights.vue";
import InPageBannerDescription from "../atom/text/InPageBannerDescription.vue";
import InPageBannerLoading from "../atom/loading/InPageBannerLoading.vue";

interface imagePanel {
  _type: string;
  asset?: object;
}

export default {
  name: "InPageBanner",
  components: {
    InPageBannerLabel,
    HeadingWithHighlights,
    InPageBannerDescription,
    InPageBannerLoading,
  },
  props: {
    bannerLabel: {
      type: String,
      default: "Sample Label",
    },
    bannerText: {
      type: String,
      default: "Sample Banner Text",
    },
    textHighlight: {
      type: String,
      default: "Banner Text",
    },
    bannerDescription: {
      type: String,
      default:
        "The only Sales Engagement Platform with the most advanced triple dialler capabilities, optimized to reach prospects globally.",
    },
    bannerImage: {
      type: Object as PropType<imagePanel>,
      default: {
        _type: "image",
        asset: {
          _ref: "image-1bb3b2528b88185c6639c6469c888b67c58f4c7d-472x462-png",
          _type: "reference",
        },
      },
    },
  },
  async setup(props: any) {
    const {
      bannerLabel,
      bannerText,
      textHighlight,
      bannerDescription,
      bannerImage,
    } = toRefs(props);

    return {
      bannerLabel,
      bannerText,
      textHighlight,
      bannerDescription,
      bannerImage,
      parseSanityImage,
    };
  },
};
</script>
<style lang=""></style>
