<template>
  <p class="text-neutral-2 apercu-light prose">
    <slot></slot>
  </p>
</template>

<script lang="ts">
export default {
  name: "ColumnContent",
};
</script>

<style scoped>
p {
  font-size: 16px;
}
</style>
