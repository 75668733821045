<template>
  <p class="text-neutral-2 apercu-regular">
    {{ textContent }}
  </p>
</template>

<script lang="ts">
import { toRefs } from "vue";

export default {
  props: {
    textContent: {
      type: String,
      default:
        "SDRS are playing, businesses are winning. Gamify, Orchestrate, and Dial.",
    },
  },
  setup(props: any) {
    const { textContent } = toRefs(props);

    return {
      textContent,
    };
  },
};
</script>

<style scoped>
p {
  font-size: 16px;
}
</style>
