<template>
  <div>
    <section class="in-page-banner h-screen">
      <div class="section-container">
        <div
          v-if="inPageBanner.bannerLabel"
          class="py-[30px] lg:py-[100px] flex flex-col lg:flex-row items-center justify-center"
        >
          <div
            class="mb-[30px] lg:shrink-[2] lg:pr-[50px] lg:mb-0"
            v-motion
            :initial="{ opacity: 0, x: -50 }"
            :enter="{ opacity: 1, x: 0, scale: 1 }"
            :delay="200"
          >
            <in-page-banner-label :text="inPageBanner.bannerLabel" />
            <heading-with-highlights
              :text="inPageBanner.bannerText"
              :highlight="inPageBanner.textHighlight"
            />
            <div class="space-20px"></div>
            <in-page-banner-description :text="inPageBanner.subheaderText" />
          </div>

          <div class="lg:pl-5 w-full lg:w-[500px] xl:w-[650px]">
            <contact-form formOrigin="Demo Page" />
          </div>
        </div>
      </div>
    </section>

    <!-- <section class=" bg-neutral-5 py-[60px] xl:pt-[120px]">
            <div class="section-container">
                <suspense>
                    <three-column-image
                        columnType="simple"
                        :columnItems="threeColumnImage.columnItems"
                    />
                </suspense>
            </div>
        </section> -->

    <section class="bg-neutral-5 py-[50px]">
      <div class="footer-callout-container">
        <suspense>
          <footer-call-out-block
            :bannerText="footerCallout.bannerText"
            :bannerTextHighlight="footerCallout.textHighlight"
            :bannerImage="footerCallout.calloutImage"
            :ctaDetails="footerCallout.ctaDetails"
          />
        </suspense>
      </div>
    </section>
  </div>
</template>
<script lang="ts">
import { reactive } from "vue";
import ButtonDefault from "@/components/atom/button/ButtonDefault.vue";
import ContactForm from "@/components/molecule/ContactForm.vue";
import FooterCallOutBlock from "@/components/molecule/FooterCallOutBlock.vue";
import HeadingWithHighlights from "@/components/atom/text/HeadingWithHighlights.vue";
import InPageBannerDescription from "@/components/atom/text/InPageBannerDescription.vue";
import InPageBannerLabel from "@/components/atom/text/InPageBannerLabel.vue";
import InPageBannerLoading from "@/components/atom/loading/InPageBannerLoading.vue";
import InputField from "@/components/atom/input/InputField.vue";
import TextareaField from "@/components/atom/input/TextareaField.vue";
import ThreeColumnImage from "@/components/molecule/ThreeColumnImage.vue";

import Demo from "@/core/application/Demo";
import DemoPageModel from "@/core/domain/model/DemoPageModel";

export default {
  name: "RequestADemo",
  components: {
    ButtonDefault,
    ContactForm,
    FooterCallOutBlock,
    HeadingWithHighlights,
    InPageBannerDescription,
    InPageBannerLabel,
    InPageBannerLoading,
    InputField,
    TextareaField,
    ThreeColumnImage,
  },
  props: {
    bannerLabel: {
      type: String,
      default: "Sample Label",
    },
    bannerText: {
      type: String,
      default: "Sample Banner",
    },
    textHighlight: {
      type: String,
      default: "Banner",
    },
    bannerDescription: {
      type: String,
      default:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
    },
  },
  setup() {
    const inPageBanner = reactive({
      bannerText: "",
      bannerLabel: "",
      subheaderText: "",
      textHighlight: "",
    });

    const threeColumnImage = reactive({
      columnItems: {},
    });

    const footerCallout = reactive({
      bannerText: "",
      textHighlight: "",
      calloutImage: {},
      ctaDetails: {},
    });

    const demo = new Demo();
    demo.getAllData().then((data: DemoPageModel) => {
      inPageBanner.bannerText = data.inPageBannerSimple.bannerText!;
      inPageBanner.bannerLabel = data.inPageBannerSimple.banner_label!;
      inPageBanner.textHighlight = data.inPageBannerSimple.text_highlight!;
      inPageBanner.subheaderText = data.inPageBannerSimple.subheaderText!;

      threeColumnImage.columnItems = data.threeColumnImageSimple.column_items!;

      footerCallout.bannerText = data.footerCallout.bannerText!;
      footerCallout.textHighlight = data.footerCallout.text_highlight!;
      footerCallout.calloutImage = data.footerCallout.image!;
      footerCallout.ctaDetails = data.footerCallout.cta_details!;
    });

    return {
      inPageBanner,
      threeColumnImage,
      footerCallout,
    };
  },
};
</script>
<style></style>
