<template>
  <div
    class="bg-white shaddow-lg p-[20px] static z-50 border-t border-gray-300"
  >
    <div class="flex flex-col lg:flex-row justify-between items-center">
      <div class="mb-3 lg:mb-0 lg:w-1/2 xl:w-2/3">
        <p class="mb-0 apercu-light">
          We use cookies to give you the best possible experience while on our
          website. To learn more about the different cookies we are using,
          please check our
          <link-default linkText="Cookie Policy" linkRedirection="/cookies" />.
        </p>
      </div>
      <div class="flex flex-col lg:flex-row gap-2 w-full lg:w-auto">
        <div class="flex flex-row gap-2">
          <button-outlined-with-icon
            @click="toggleCustomConsent()"
            class="w-full flex-1"
            buttonText="Customize"
          >
            <svg
              :class="{ 'rotate-180': isOpenCustomConsentStatus }"
              width="8"
              height="7"
              viewBox="0 0 8 7"
              class="fill-black transition duration-75"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.649221 6.05884H7.32491C7.3925 6.05864 7.45875 6.04147 7.51653 6.00915C7.57431 5.97684 7.62143 5.93061 7.65283 5.87545C7.68423 5.82029 7.6987 5.75829 7.6947 5.69611C7.69071 5.63393 7.66838 5.57393 7.63013 5.52258L4.29229 1.07936C4.15396 0.895137 3.82091 0.895137 3.68221 1.07936L0.344365 5.52258C0.305729 5.57383 0.283072 5.63385 0.278856 5.69614C0.27464 5.75842 0.289025 5.82057 0.320449 5.87585C0.351873 5.93113 0.399134 5.97741 0.457096 6.00967C0.515059 6.04193 0.581507 6.05894 0.649221 6.05884Z"
              />
            </svg>
          </button-outlined-with-icon>
          <button-outlined-black
            @click="reject()"
            class="flex-1"
            buttonText="Reject All"
          />
        </div>

        <button-default
          @click="acceptCookies()"
          class="flex-1"
          :buttonText="acceptButtonText"
          buttonSize="fluid"
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { computed, watch, ref } from "vue";
import { useStore } from "vuex";
import { useState } from "vue-gtag-next";
import ButtonDefault from "@/components/atom/button/ButtonDefault.vue";
import ButtonOutlinedBlack from "@/components/atom/button/ButtonOutlinedBlack.vue";
import ButtonOutlinedWithIcon from "@/components/atom/button/ButtonOutlinedWithIcon.vue";
import ConsentBannerCustomize from "@/components/molecule/consent_banner/ConsentBannerCustomize.vue";
import LinkDefault from "@/components/atom/link/LinkDefault.vue";

export default {
  name: "ConsentCookie",
  components: {
    ButtonDefault,
    ButtonOutlinedWithIcon,
    ButtonOutlinedBlack,
    ConsentBannerCustomize,
    LinkDefault,
  },
  setup() {
    const store = useStore();
    const { isEnabled } = useState();
    const isOpenCustomConsentStatus = computed(
      () => store.getters["consent_banner/GET_isOpenCustomConsentStatus"],
    );
    const checkboxSwitch = computed(
      () => store.getters["consent_banner/GET_isOpenAnalyticsCheckboxStatus"],
    );
    const acceptButtonText = ref("Accept All");

    watch(
      () => store.getters["consent_banner/GET_isOpenCustomConsentStatus"],
      (value) => {
        console.info(value);
        acceptButtonText.value = value ? "Save Preferences" : "Accept All";
      },
    );

    const acceptCookies = () => {
      localStorage.setItem("cookie-consent", "yes");
      if (checkboxSwitch.value) {
        enable();
      } else {
        disable();
      }
      store.dispatch("consent_banner/closeConsentBanner");
    };

    const reject = () => {
      disable();
      localStorage.setItem("cookie-consent", "no");
      store.dispatch("consent_banner/closeConsentBanner");
    };

    const enable = () => {
      isEnabled!.value = true;
      localStorage.setItem("cookie-analytics", "yes");
    };

    const disable = () => {
      isEnabled!.value = false;
      localStorage.setItem("cookie-analytics", "no");
    };

    const toggleCustomConsent = () => {
      store.dispatch("consent_banner/toggleCustomConsentStatus");
    };

    return {
      enable,
      toggleCustomConsent,
      isOpenCustomConsentStatus,
      checkboxSwitch,
      acceptCookies,
      disable,
      acceptButtonText,
      reject,
    };
  },
};
</script>
<style lang=""></style>
