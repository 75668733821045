import WhyPodiemInPageBannerService from "./service/why_podiem/WhyPodiemInPageBannerService"
import WhyPodiemThreeColumnImageService from "./service/why_podiem/WhyPodiemThreeColumnImageService"
import WhyPodiemTwoColumnService from "./service/why_podiem/WhyPodiemTwoColumnService"
import WhyPodiemCalloutService from "./service/why_podiem/WhyPodiemCalloutService"
import WhyPodiemCarouselService from "./service/why_podiem/WhyPodiemCarouselService"
import WhyPodiemFooterCalloutService from "./service/why_podiem/WhyPodiemFooterCalloutService"

export default class WhyPodiem {
    whyPodiemInPageBanner: WhyPodiemInPageBannerService
    whyPodiemThreeColumnImage: WhyPodiemThreeColumnImageService
    whyPodiemTwoColumnService: WhyPodiemTwoColumnService
    whyPodiemCallout: WhyPodiemCalloutService
    whyPodiemCarousel: WhyPodiemCarouselService
    whyPodiemFooterCallout: WhyPodiemFooterCalloutService

    constructor() {
        this.whyPodiemInPageBanner = new WhyPodiemInPageBannerService()
        this.whyPodiemThreeColumnImage = new WhyPodiemThreeColumnImageService()
        this.whyPodiemTwoColumnService = new WhyPodiemTwoColumnService()
        this.whyPodiemCallout = new WhyPodiemCalloutService()
        this.whyPodiemCarousel = new WhyPodiemCarouselService()
        this.whyPodiemFooterCallout = new WhyPodiemFooterCalloutService()
    }

    async getAllData() {
        const inPageBanner = await this.whyPodiemInPageBanner.process()
        const threeColumn = await this.whyPodiemThreeColumnImage.process()
        const twoColumn = await this.whyPodiemTwoColumnService.process()
        const callout = await this.whyPodiemCallout.process()
        const carousel = await this.whyPodiemCarousel.process()
        const footerCallout = await this.whyPodiemFooterCallout.process()

        return {
            inPageBanner,
            threeColumn,
            twoColumn,
            callout,
            carousel,
            footerCallout,
        }
    }
}
