/* eslint-disable @typescript-eslint/no-unsafe-call */
import SiteSettingModel from "@/core/domain/model/SiteSettingModel";

const state = () => ({
  siteSettings: {},
  businessPhoneNumber: "",
  copyrightText: "",
  facebookUrl: "",
  instagramUrl: "",
  linkedinUrl: "",
  pageLoading: true,
});

const actions = {
  setSiteSettingInfo({ commit }: any, data: SiteSettingModel) {
    commit("SET_SITE_SETTING_INFO", data);
    commit("SET_BUSINESS_PHONE_NUMBER", data.business_phone_number);
    commit("SET_COPYRIGHT_TEXT", data.copyright_text);
    commit("SET_FACEBOOK_URL", data.facebook_url);
    commit("SET_INSTAGRAM_URL", data.instagram_url);
    commit("SET_LINKEDIN_URL", data.linkedin_url);
  },

  setPageLoading({ commit }: any, status: boolean) {
    commit("SET_PAGE_LOADING", status);
  },
};

const mutations = {
  SET_SITE_SETTING_INFO(state: { siteSetting: object }, payload: object) {
    state.siteSetting = payload;
  },
  SET_BUSINESS_PHONE_NUMBER(
    state: { businessPhoneNumber: string },
    payload: string,
  ) {
    state.businessPhoneNumber = payload;
  },
  SET_COPYRIGHT_TEXT(state: { copyrightText: string }, payload: string) {
    state.copyrightText = payload;
  },
  SET_FACEBOOK_URL(state: { facebookUrl: string }, payload: string) {
    state.facebookUrl = payload;
  },
  SET_INSTAGRAM_URL(state: { instagramUrl: string }, payload: string) {
    state.instagramUrl = payload;
  },
  SET_LINKEDIN_URL(state: { linkedinUrl: string }, payload: string) {
    state.linkedinUrl = payload;
  },
  SET_PAGE_LOADING(state: { pageLoading: boolean }, payload: boolean) {
    state.pageLoading = payload;
  },
};

const getters = {
  GET_siteSettings: (state: { siteSetting: object }) => state.siteSetting,
  GET_businessPhoneNumber: (state: { businessPhoneNumber: string }) =>
    state.businessPhoneNumber,
  GET_copyrightText: (state: { copyrightText: string }) => state.copyrightText,
  GET_facebookUrl: (state: { facebookUrl: string }) => state.facebookUrl,
  GET_instagramUrl: (state: { instagramUrl: string }) => state.instagramUrl,
  GET_linkedinUrl: (state: { linkedinUrl: string }) => state.linkedinUrl,
  GET_isPageLoading: (state: { pageLoading: string }) => state.pageLoading,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
