<template>
  <div>
    <heading-with-highlights
      class="mb-[60px]"
      :text="bannerText"
      :highlight="textHighlight"
    />
    <div class="two-column flex flex-col md:flex-row md:gap-x-[30px]">
      <div v-for="(item, id) in columnItems" :key="id" class="md:flex-1">
        <div class="short-border bg-primary-1"></div>
        <template v-if="item.twoColumnContentText">
          <column-content
            v-for="(value, key) in item.twoColumnContentText"
            :key="key"
          >
            <sanity-blocks :blocks="[value]"></sanity-blocks>
          </column-content>
        </template>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { toRefs } from "vue";
import { SanityBlocks } from "sanity-blocks-vue-component";
import HeadingWithHighlights from "@/components/atom/text/HeadingWithHighlights.vue";
import ColumnContent from "@/components/atom/text/ColumnContent.vue";

export default {
  name: "TwoColumnPlain",
  components: {
    HeadingWithHighlights,
    ColumnContent,
    SanityBlocks,
  },
  props: {
    bannerText: {
      type: String,
    },
    textHighlight: {
      type: String,
      default: "",
    },
    columnItems: {
      type: Object,
    },
  },
  setup(props: any) {
    const { bannerText, textHighlight, columnItems } = toRefs(props);

    return {
      bannerText,
      textHighlight,
      columnItems,
    };
  },
};
</script>
<style lang=""></style>
