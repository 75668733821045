<template>
  <button class="button-outlined-with-icon">
    {{ buttonText }}
    <slot></slot>
  </button>
</template>

<script lang="ts">
export default {
  name: "ButtonOutlinedWithIcon",
  props: {
    buttonText: {
      type: String,
      default: "Button",
    },
    buttonSize: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped>
button:disabled {
  cursor: not-allowed;
}

:disabled::v-deep(path) {
  fill: #939597;
}

.button-outlined-with-icon {
  @apply flex flex-row items-center gap-x-2 justify-center border border-black text-black rounded-full  hover:border-primary-1 hover:text-white hover:bg-primary-1 disabled:bg-disabled-1 disabled:text-disabled-text-1;
}
</style>
