<template>
  <div>
    <section class="in-page-banner2 xl:-mt-[40px]">
      <div class="section-container md:pt-[30px]">
        <div
          class="py-[30px] lg:pt-[130px] lg:pb-[100px] flex flex-col lg:flex-row items-center justify-center"
        >
          <div class="mb-[30px] lg:shrink-[2] lg:pr-[50px] lg:mb-0">
            <in-page-banner-label :text="articleCategory" />
            <heading-with-highlights
              :text="inPageBanner.bannerText"
              :highlight="inPageBanner.textHighlight"
            />

            <div class="space-20px"></div>
            <div class="w-12 border-4 border-b border-primary-1"></div>
            <div class="space-20px"></div>

            <in-page-banner-description :text="inPageBanner.subheaderText" />
            <div class="space-20px"></div>
          </div>

          <div
            class="lg:pl-5"
            v-motion
            :initial="{ opacity: 0, x: 100 }"
            :enter="{ opacity: 1, x: 0, scale: 1 }"
            :delay="400"
          >
            <template v-if="inPageBanner.imagePanel">
              <img
                :src="parseSanityImage(inPageBanner.imagePanel)"
                :alt="inPageBanner.imagePanel.alt"
                class="md:max-w-[500px]"
              />
            </template>
          </div>
        </div>

        <div class="mx-auto py-[80px] lg:max-w-[760px]">
          <div
            v-if="blogContent"
            class="max-w-none w-full lg:px-10 prose"
          >
            <div
              class="mb-[50px] flex flex-row items-center justify-between gap-x-3"
            >
              <p class="text-gray-800 leading-none m-0">
                Published by: {{ author }}
              </p>
              <p class="m-0">{{ formatDate(publishingDate) }}</p>
            </div>

            <!-- <sanity-blocks :blocks="[...blogContent]" :serializers="serializers"></sanity-blocks> -->
            <div class="font-montserrat " v-html="portableText"></div>
          </div>
        </div>
      </div>
    </section>

   
  </div>
</template>
<script lang="ts">
import dayjs from "dayjs";
import { toHTML } from "@portabletext/to-html";
import { ref, reactive } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { SanityBlocks } from "sanity-blocks-vue-component";
import { parseSanityImage } from "@/js/composable/parseSanityImage";
import ButtonDefault from "@/components/atom/button/ButtonDefault.vue";
import InPageBannerLabel from "@/components/atom/text/InPageBannerLabel.vue";
import HeadingWithHighlights from "@/components/atom/text/HeadingWithHighlights.vue";
import InPageBannerDescription from "@/components/atom/text/InPageBannerDescription.vue";
import ButtonOutlined from "@/components/atom/button/ButtonOutlined.vue";
import Image from "@/components/atom/serializer/Image.vue";
import InPageBanner from "@/components/molecule/InPageBanner.vue";
import FooterCallOutBlock from "@/components/molecule/FooterCallOutBlock.vue";

import ArticleService from "@/core/application/service/ArticleService";
import BlogCategoryService from "@/core/application/service/blog/BlogCategoryService";
import { stringLiteral } from "@babel/types";

export default {
  name: "Blog",
  components: {
    ButtonDefault,
    ButtonOutlined,
    InPageBanner,
    FooterCallOutBlock,
    SanityBlocks,
    InPageBannerLabel,
    HeadingWithHighlights,
    InPageBannerDescription,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const key = route.params.id.toString();

    const inPageBanner = reactive({
      bannerLabel: "",
      bannerText: "",
      subheaderText: "",
      textHighlight: "",
      imagePanel: {},
    });

    const author = ref("");
    const publishingDate = ref("");

    const blogContent = ref({});

    const footerCallout = reactive({
      bannerText: "",
      textHighlight: "",
      calloutImage: {},
      ctaDetails: {},
    });

    const blogCategory = ref([]);
    new BlogCategoryService().process().then((data: any) => {
      blogCategory.value = data.category;
    });

    const articleKey = ref("");
    const articleCategory = ref("");
    const featuredArticles = ref([]);
    const portableText = ref("");
    const tags = ref("");

    const article = new ArticleService();
    article.setKey(key);
    const loadData = () => {
      article.process().then((data: any) => {
        console.info(data.blogs[0].articleInfo.blogContent)
        inPageBanner.bannerLabel = data.blogs[0].articleInfo.readingTime!; // change this
        inPageBanner.bannerText = data.blogs[0].bannerText!;
        inPageBanner.subheaderText = data.blogs[0].articleInfo.readingTime!;
        inPageBanner.textHighlight = data.blogs[0].text_highlight!;
        inPageBanner.imagePanel = data.blogs[0].imagePanel!;

        author.value = data.blogs[0].articleInfo.publishedBy!;
        publishingDate.value = data.blogs[0].articleInfo.datePosted!;

        blogContent.value = data.blogs[0].articleInfo.blogContent!;

        articleKey.value = data.blogs[0]._key!;

        // footerCallout.bannerText = data.blogs[0].footerCallout.bannerText!;
        // footerCallout.textHighlight =
        //   data.blogs[0].footerCallout.text_highlight!;
        // footerCallout.calloutImage = data.blogs[0].footerCallout.imagePanel!;
        // footerCallout.ctaDetails = {
        //   cta_enabled: data.blogs[0].footerCallout.cta_enabled!,
        //   cta_text: data.blogs[0].footerCallout.cta_text!,
        //   cta_redirection: data.blogs[0].footerCallout.cta_redirection!,
        // };

        const myPortableTextComponents = {
          types: {
            image: ({ value }: any) =>
              "<img src=" + parseSanityImage(value.asset) + " />",
          },
          block: {
            h1: ({ children }: any) => `<h1 style="font-size: 2.25rem; font-weight: bold;">${children}</h1>`,
            h2: ({ children }: any) => `<h2 style="font-size: 1.875rem; font-weight: bold;">${children}</h2>`,
            h3: ({ children }: any) => `<h3 style="font-size: 1.5rem; font-weight: bold;">${children}</h3>`,
            h4: ({ children }: any) => `<h4 style="font-size: 1.25rem; font-weight: bold;">${children}</h4>`,
          },
        };

        portableText.value = toHTML(data.blogs[0].articleInfo.blogContent!, {
          components: myPortableTextComponents,
        });

        // article
        //   .retrieveFeaturedArticle(
        //     data.blogs[0].articleInfo.blogCategoryOption!._ref,
        //   )
        //   .then((data: any) => {
        //     featuredArticles.value = data.blogs;
        //   });

        // article
        //   .retrieveSpecificCategory(
        //     data.blogs[0].articleInfo.blogCategoryOption!._ref,
        //   )
        //   .then((data: any) => {
        //     articleCategory.value = data.blog_category;
        //   });

        tags.value = data.blogs[0].articleInfo.tags!;
      });
    };

    loadData();

    const serializers = {
      types: {
        image: Image,
      },
    };

    const goToArticle = (details: string, key: number) => {
      const slug = details.toLowerCase().replace(/\s+/g, "-");
      const url = "/blog/" + key + "/" + slug;

      router.push(url);
      setTimeout(() => {
        location.reload();
      }, 200);
    };

    const moveToResources = () => {
      location.href = "/resources";
    };

    const formatDate = (dateString: string) => {
      const date = dayjs(dateString);
      return date.format("MMMM D, YYYY");
    };

    const formatTags = (tags: string) => {
      return tags.replaceAll(",", " ");
    };

    return {
      inPageBanner,
      blogCategory,
      blogContent,
      serializers,
      footerCallout,
      featuredArticles,
      parseSanityImage,
      articleKey,
      articleCategory,
      goToArticle,
      moveToResources,
      author,
      publishingDate,
      formatDate,
      portableText,
      tags,
      formatTags,
    };
  },
};
</script>
<style scoped>
.image {
  height: 300px;
  overflow: hidden;
}

.image > img {
  height: 100%;
  margin-inline: auto;
  object-fit: cover;
}
</style>
