/* eslint-disable @typescript-eslint/no-unsafe-call */
import { HomeBannerModel } from "@/store/model/HomeBannerModel";

const state = () => ({
  bannerText: "",
  subHeaderText: "",
  textHighlight: "",
  ctaDetails: {},
  bannerImage: {
    _type: "image",
    asset: {
      _ref: "image-e2221e5fca8d6dc4ea7434a0b8e2a3e110f71d8e-891x790-png",
      _type: "reference",
    },
  },
});

const actions = {
  setHomeBannerInformation({ commit }: any, bannerInfo: HomeBannerModel) {
    commit("SET_BANNER_TEXT", bannerInfo.bannerText);
    commit("SET_SUBHEADER_TEXT", bannerInfo.subheaderText);
    commit("SET_TEXT_HIGHLIGHT", bannerInfo.text_highlight);
    commit("SET_CTA_DETAILS", {
      cta_text: bannerInfo.cta_text,
      cta_redirection: bannerInfo.cta_redirection,
    });
  },
  setBannerImage({ commit }: any, data: object) {
    commit("SET_BANNER_IMAGE", data);
  },
};

const mutations = {
  SET_BANNER_TEXT(state: { bannerText: string }, payload: string) {
    state.bannerText = payload;
  },
  SET_SUBHEADER_TEXT(state: { subHeaderText: string }, payload: string) {
    state.subHeaderText = payload;
  },
  SET_TEXT_HIGHLIGHT(state: { textHighlight: string }, payload: string) {
    state.textHighlight = payload;
  },
  SET_CTA_DETAILS(state: { ctaDetails: object }, payload: object) {
    state.ctaDetails = payload;
  },
  SET_BANNER_IMAGE(state: { bannerImage: object }, payload: object) {
    state.bannerImage = payload;
  },
};

const getters = {
  GET_bannerText: (state: { bannerText: string }) => state.bannerText,
  GET_subHeaderText: (state: { subHeaderText: string }) => state.subHeaderText,
  GET_textHighlight: (state: { textHighlight: string }) => state.textHighlight,
  GET_ctaDetails: (state: { ctaDetails: object }) => state.ctaDetails,
  GET_bannerImage: (state: { bannerImage: object }) => state.bannerImage,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
