<template>
  <div
    v-if="data && data.headline"
    :class="[
      'callout-block w-full rounded-call-out z-10',
      backgroundStyleClass 
    ]"
    v-motion-fade
  >
    <div
      :class="[
        'flex flex-col gap-y-14 lg:flex-row xl:gap-x-20 lg:gap-x-10 items-center justify-center',
        data.imageDirection === 'right' ? 'lg:flex-row-reverse' : '' 
      ]"
    >
      <div
        class="overflow-hidden w-full max-w-[435px] max-h-[435px] lg:min-w-[435px] lg:min-h-[435px] flex items-center"
      >
        <img
          v-if="data.image"
          width="640"
          height="360"
          class="w-full h-full"
          :src="parseSanityImage(data.image).url()"
        />
      </div>

      <div class="flex flex-col">
        <HeadingWithHighlights
          :text-location="data.backgroundStyle === 'primary' ? '' : 'dark'"
          :text="data.headline"
          :highlight="data.highlightedText"
        />
        <div class="space-20px"></div>


        <p :class="[data.backgroundStyle === 'primary' ? 'text-neutral-2' : 'text-white']">
          {{ data.description }}
        </p>

        <template v-if="data.ctaLink">
          <div class="space-20px"></div>
          <ButtonDefault :buttonText="data.ctaLink.title" :redirection="data.ctaLink.internalLink" />
        </template>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import HeadingWithHighlights from "../atom/text/HeadingWithHighlights.vue";
import ButtonDefault from "../atom/button/ButtonDefault.vue";
import { parseSanityImage } from "@/js/composable/parseSanityImage";

import { type CalloutBlockType } from '@/core/types';

const props = defineProps<{
  data: CalloutBlockType;
}>();

const backgroundStyleClass = computed(() => {
  if (props.data.backgroundStyle === 'primary') {
    return 'bg-white/80';
  } else if (props.data.backgroundStyle === 'secondary') {
    return 'bg-gradient-to-r from-primary-1 to-primary-3';
  } else {
    return 'bg-white/80';
  }
});
</script>

<style scoped>
.callout-block {
  width: 100%;
  padding: 60px 35px;
  box-shadow: 0px 12px 50px rgba(0, 0, 0, 0.12);
}

@media only screen and (min-width: 1440px) {
  .callout-block {
    max-width: 1400px;
    margin: 0 auto;
    padding: 60px 100px;
  }
}
</style>
