import ResourcesFooterCallout from "@/core/infrastructure/repository/resources/ResourcesFooterCallout"
import hasData from "@/core/domain/specification/hasData"

export default class ResourcesFooterCalloutService {
    protected resourcesFooterCallout: ResourcesFooterCallout

    constructor() {
        this.resourcesFooterCallout = new ResourcesFooterCallout()
    }

    async process() {
        const { data, totalCount } = await this.resourcesFooterCallout.fetch()

        if (!hasData(totalCount)) {
            throw new TypeError("No data was provided")
        }

        return data
    }
}
