import SiteSettingRepository from "@/core/infrastructure/repository/SiteSettingRepository"
import hasData from "@/core/domain/specification/hasData"

export default class SiteSettingService {
    siteSettingRepository: SiteSettingRepository

    constructor() {
        this.siteSettingRepository = new SiteSettingRepository()
    }

    async process() {
        const { data, totalCount } = await this.siteSettingRepository.fetch()

        if (!hasData(totalCount)) {
            throw new TypeError("No data was provided")
        }

        return data
    }
}
