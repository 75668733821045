import CompanyFooterCallout from "@/core/infrastructure/repository/company/CompanyFooterCallout"
import hasData from "@/core/domain/specification/hasData"

export default class CompanyFooterCalloutService {
    protected companyFooterCallout: CompanyFooterCallout

    constructor() {
        this.companyFooterCallout = new CompanyFooterCallout()
    }

    async process() {
        const { data, totalCount } = await this.companyFooterCallout.fetch()

        if (!hasData(totalCount)) {
            throw new TypeError("No data was provided")
        }

        return data
    }
}
