<template>
  <li>
    <a
      :href="url"
      class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
    >
      <span class="ml-3">{{ text }}</span>
    </a>
  </li>
</template>

<script lang="ts">
export default {
  name: "DevMenuItem",
  props: ["text", "url"],
};
</script>

<style scoped></style>
