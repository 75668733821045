<template>
  <div class="bg-white w-full rounded-call-out form-shadow px-[25px] py-[40px] xl:px-[30px] xl:py-[60px]">
    <template v-if="sending">
      <div class="h-[400px] flex flex-col items-center justify-center">
        <spinner :inverted="false" />
        <p class="mt-2 text-neutral-1">Sending...</p>
      </div>
    </template>
    <template v-else>
      <template v-if="sent">
        <template v-if="error.status">
          <danger-alert :text="error.message" />
        </template>
        <template v-else>
          <div class="h-[400px] flex flex-col items-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1"
              stroke="currentColor"
              class="w-16 h-16 text-green-600"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <h2 class="mt-2 text-2xl font-bold">Thank you!</h2>
            <p class="apercu-light px-5 text-center text-neutral-1 mb-5">
              Your message has been sent successfully!
            </p>
            <p class="text-neutral-3 text-xs">
              Reloading in {{ closingCountdown }}
            </p>
          </div>
        </template>
      </template>
      <template v-else>
        <div
          class="mb-[20px] flex flex-col gap-y-[20px] lg:flex-row lg:gap-x-[20px]"
        >
          <input-field
            v-model="formInputs.firstName"
            :hasError="formError.firstName"
            :errorMessage="formErrorMessage.firstName"
            class="flex-1"
            placeholderText="First name"
            id="first_name"
            @input="checkFields()"
          />
          <input-field
            v-model="formInputs.lastName"
            :hasError="formError.lastName"
            :errorMessage="formErrorMessage.lastName"
            class="flex-1"
            placeholderText="Last name"
            id="last_name"
            @input="checkFields()"
          />
        </div>
        <div class="mb-[20px]">
          <input-field
            v-model="formInputs.email"
            :hasError="formError.email"
            :errorMessage="formErrorMessage.email"
            type="email"
            id="contact_email"
            placeholderText="Email address"
            @input="checkFields()"
          />
        </div>
        <div
          class="mb-[20px] flex flex-col gap-y-[20px] lg:flex-row lg:gap-x-[20px]"
        >
          <number-only-field
            v-model="formInputs.phoneNumber"
            :hasError="formError.phoneNumber"
            :errorMessage="formErrorMessage.phoneNumber"
            class="flex-1"
            placeholderText="Phone number"
            id="phone_number"
            @input="checkFields()"
          />
          <input-field
            v-model="formInputs.companyName"
            :hasError="formError.companyName"
            :errorMessage="formErrorMessage.companyName"
            class="flex-1"
            placeholderText="Company name"
            id="company_name"
            @input="checkFields()"
          />
        </div>

        <div class="mb-[20px]">
          <input-field
            v-model="formInputs.jobTitle"
            :hasError="formError.jobTitle"
            :errorMessage="formErrorMessage.jobTitle"
            class="flex-1"
            placeholderText="Job Title"
            id="job_title"
            @input="checkFields()"
          />
        </div>

        <div v-if="formOrigin === 'Contact Us'" class="mb-[20px]">
          <select-field
            class="flex-1"
            placeholderText="What information would you like?"
            id="category"
            v-model="formInputs.inquiryCategory"
            :hasError="formError.inquiryCategory"
            :errorMessage="formErrorMessage.inquiryCategory"
            @input="checkFields()"
            :data="dropdownItems"
          />
        </div>

        <div>
          <textarea-field
            v-model="formInputs.message"
            placeholderText="Message"
            :requred="false"
            id="message"
          />
        </div>

        <div class="mb-[20px]">
          <checkbox-field
            v-model:checked="formInputs.gdpr"
            :hasError="formError.gdpr"
            :errorMessage="formErrorMessage.gdpr"
            label="By checking this box, you are consenting to receive future communication from Podiem."
            id="gdpr_checkbox"
            @update:checked="checkFields()"
          />
        </div>

        <div class="mb-[20px]">
          <submit-button
            @click="sendInquiry()"
            buttonSize="fluid"
            buttonText="Submit"
            redirection="#"
          />
        </div>

        <div>
          <p class="apercu-light text-sm mb-0 text-neutral-2">
            By submitting your information to our website, you agree to the
            terms outlined in our
            <a href="/privacy-policy" class="text-primary-1 hover:underline"
              >Privacy Policy</a
            >.
          </p>
        </div>
      </template>
    </template>
  </div>
</template>
<script lang="ts">
import { ref, reactive } from "vue";
import { isValidEmail } from "@/js/composable/validateEmail";
import ButtonOutlined from "@/components/atom/button/ButtonOutlined.vue";
import CheckboxField from "@/components/atom/input/CheckboxField.vue";
import DangerAlert from "@/components/atom/alerts/DangerAlert.vue";
import InputField from "@/components/atom/input/InputField.vue";
import isOk from "@/core/domain/specification/isOk";
import NumberOnlyField from "@/components/atom/input/NumberOnlyField.vue";
import SubmitButton from "@/components/atom/button/SubmitButton.vue";
import Spinner from "@/components/atom/loading/Spinner.vue";
import TextareaField from "@/components/atom/input/TextareaField.vue";
import SelectField from "@/components/atom/input/SelectField.vue";

import ContactFormDto from "@/core/domain/dto/ContactFormDto";
import SendContactForm from "@/core/application/service/SendContactForm";

export default {
  name: "ContactForm",
  components: {
    ButtonOutlined,
    CheckboxField,
    DangerAlert,
    InputField,
    NumberOnlyField,
    SubmitButton,
    Spinner,
    TextareaField,
    SelectField,
  },
  props: {
    formOrigin: {
      type: String,
    },
  },
  setup(props: any) {
    const submittedOnce = ref(false);
    const sending = ref(false);
    const sent = ref(false);
    const error = reactive({
      status: false,
      message: "Something went wrong while sending the form.",
    });
    const closingCountdown = ref(10); // 10 seconds

    const isDemoPage = props.formOrigin === "Demo Page";

    const formInputs = reactive({
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      companyName: "",
      jobTitle: "",
      inquiryCategory: isDemoPage ? "Demo" : "",
      message: "",
      gdpr: false,
      requestForm: props.formOrigin,
    });

    const formError = reactive({
      firstName: false,
      lastName: false,
      email: false,
      phoneNumber: false,
      companyName: false,
      jobTitle: false,
      inquiryCategory: false,
      gdpr: false,
    });

    const formErrorMessage = reactive({
      firstName: "First name is required",
      lastName: "Last name is required",
      email: "Email is required",
      phoneNumber: "Phone number is required",
      companyName: "Company name is required",
      jobTitle: "Job Title is required",
      inquiryCategory: "Inquiry category is required",
      gdpr: "Please agree to the terms and conditions",
    });

    const checkFields = () => {
      if (submittedOnce.value) {
        Object.entries(formInputs).forEach(([key, item]) => {
          formError[key as keyof typeof formError] = false;
          if ((item === "" || !item) && key !== "message") {
            formError[key as keyof typeof formError] = true;
          }
          if (key === "email") {
            if (!isValidEmail(item) && item !== "") {
              formError[key as keyof typeof formError] = true;
              formErrorMessage[key as keyof typeof formErrorMessage] =
                "Invalid email address provided";
            }
          }
        });
      }
    };

    const sendInquiry = async () => {
      submittedOnce.value = true;
      checkFields();

      const hasErrors = Object.values(formError).includes(true);
      if (!hasErrors) {
        sending.value = true;
        const contactFormDto = new ContactFormDto(formInputs);
        const sendContactForm = new SendContactForm(contactFormDto);
        const sendForm = await sendContactForm.send();

        if (isOk(sendForm.status)) {
          sending.value = false;
          sent.value = true;

          const counter = setInterval(() => {
            closingCountdown.value--;
          }, 1000);

          setTimeout(() => {
            clearInterval(counter);
            location.reload();
          }, 10000);
        } else {
          error.status = true;
        }
      }
    };

    const dropdownItems = ["Pricing", "Demo", "Partner", "Other"];

    const isOpenDropdown = ref(false);
    const openDropdown = () => {
      isOpenDropdown.value = !isOpenDropdown.value;
    };

    return {
      sendInquiry,
      formInputs,
      formError,
      formErrorMessage,
      checkFields,
      sending,
      sent,
      error,
      closingCountdown,
      dropdownItems,
      isOpenDropdown,
      openDropdown,
    };
  },
};
</script>
<style scoped>
.custom-dropdown-component {
  @apply bg-neutral-4 w-full outline-none rounded-custom-input transition-all duration-100 border hover:border-primary-1 h-[48px] flex items-center px-[10px];
}
</style>
