<template>
  <div v-if="bannerText" v-motion-slide-visible-once-bottom>
    <heading-with-highlights
      class="mb-[60px]"
      :text="bannerText"
      :highlight="textHighlight"
    />
    <div class="two-column flex flex-col md:flex-row md:gap-x-[30px]">
      <div v-for="(item, id) in columnItems" :key="id" class="md:flex-1">
        <div class="short-border bg-primary-1"></div>
        <column-heading
          class="mb-[20px]"
          :columnHeader="item.twoColumnContentTitle"
        />
        <template v-if="item.twoColumnContentText">
          <column-content
            v-for="(value, key) in item.twoColumnContentText"
            :key="key"
          >
            <sanity-blocks :blocks="[value]"></sanity-blocks>
          </column-content>
        </template>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { toRefs } from "vue";
import { SanityBlocks } from "sanity-blocks-vue-component";
import HeadingWithHighlights from "@/components/atom/text/HeadingWithHighlights.vue";
import ColumnHeading from "@/components/atom/text/ColumnHeading.vue";
import ColumnContent from "@/components/atom/text/ColumnContent.vue";

export default {
  name: "TwoColumn",
  components: {
    HeadingWithHighlights,
    ColumnHeading,
    ColumnContent,
    SanityBlocks,
  },
  props: {
    bannerText: {
      type: String,
      default: "Two column",
    },
    textHighlight: {
      type: String,
      default: "column",
    },
    columnItems: {
      type: Object,
      default: {
        twoColumnSection1: {
          twoColumnContentText: [
            {
              _key: "4828abe2680d",
              _type: "block",
              children: [
                {
                  _key: "19c1c0a198f80",
                  _type: "span",
                  marks: [],
                  text: "Podiem exists to provide salespeople an all in one platform to track pipeline, reduce the time spent prospecting, and book meetings at scale. Podiem is the only assisted power dialer on the market to cover the globe and guarantee conversations. Our platform includes gamification, orchestration, assisted dialing, scheduling, and reporting.",
                },
              ],
              markDefs: [],
              style: "normal",
            },
          ],
          twoColumnContentTitle: "Our purpose",
        },
        twoColumnSection2: {
          twoColumnContentText: [
            {
              _key: "4828abe2680d",
              _type: "block",
              children: [
                {
                  _key: "19c1c0a198f80",
                  _type: "span",
                  marks: [],
                  text: "Podiem exists to provide salespeople an all in one platform to track pipeline, reduce the time spent prospecting, and book meetings at scale. Podiem is the only assisted power dialer on the market to cover the globe and guarantee conversations. Our platform includes gamification, orchestration, assisted dialing, scheduling, and reporting.",
                },
              ],
              markDefs: [],
              style: "normal",
            },
          ],
          twoColumnContentTitle: "Our mission",
        },
      },
    },
  },
  setup(props: any) {
    const { bannerText, textHighlight, columnItems } = toRefs(props);

    return {
      bannerText,
      textHighlight,
      columnItems,
    };
  },
};
</script>
<style lang=""></style>
