<template>
  <div class="container mx-auto">
    <div class="pt-5 fixed z-50 pt-88px">
      <a
        href="/"
        class="text-neutral-1 flex flex-row items-center gap-x-2 hover:text-primary-2"
      >
        <svg
          width="11"
          height="18"
          viewBox="0 0 11 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.53034 0.513184L10.1143 2.0971L3.20622 9.00514L10.1143 15.9132L8.53034 17.4971L0.0383806 9.00514L8.53034 0.513184Z"
            fill="#3D3D3D"
          />
        </svg>

        <span>Go back to Components</span>
      </a>
    </div>
    <div class="h-screen flex flex-col items-center justify-center">
      <podiem-icon></podiem-icon>
      <h1
        class="text-heading-lg text-center text-gray-600"
        v-motion-slide-bottom
      >
        Animations
      </h1>
      <div
        class="text-gray-700"
        v-motion
        :initial="{
          opacity: 0,
        }"
        :enter="{
          opacity: 1,
          transition: {
            repeat: Infinity,
            repeatType: 'mirror',
            delay: 500,
            repeatDelay: 400,
          },
        }"
      >
        Scroll to Start
      </div>
    </div>
    <div class="flex flex-col gap-y-5">
      <div
        class="h-96 mb-48 w-1/8 bg-green-600"
        v-motion-slide-visible-bottom
      ></div>
      <div
        class="h-96 mb-48 w-1/8 bg-yellow-700"
        v-motion-slide-visible-bottom
      ></div>
      <div class="flex flex-row mb-48" v-motion-fade>
        <div
          class="h-screen w-1/2 bg-green-200"
          v-motion-slide-visible-left
          v-motion
          :initial="{ opacity: 0, x: -100 }"
          :enter="{ opacity: 1, x: 0, scale: 1 }"
          :variants="{ custom: { scale: 2 } }"
          :delay="100"
        ></div>
        <div
          class="h-screen w-1/2 bg-red-200"
          v-motion-slide-visible-right
          v-motion
          :initial="{ opacity: 0, x: 100 }"
          :enter="{ opacity: 1, x: 0, scale: 1 }"
          :variants="{ custom: { scale: 2 } }"
          :delay="200"
        ></div>
      </div>

      <div class="flex flex-row mb-48" v-motion-fade>
        <div
          class="h-48 w-1/6 bg-red-100"
          v-motion-slide-visible-right
          v-motion
          :initial="{ opacity: 0, x: 100 }"
          :enter="{ opacity: 1, x: 0, scale: 1 }"
          :variants="{ custom: { scale: 2 } }"
          :delay="100"
        ></div>
        <div
          class="h-48 w-1/6 bg-red-200"
          v-motion-slide-visible-right
          v-motion
          :initial="{ opacity: 0, x: 100 }"
          :enter="{ opacity: 1, x: 0, scale: 1 }"
          :variants="{ custom: { scale: 2 } }"
          :delay="500"
        ></div>
        <div
          class="h-48 w-1/6 bg-red-300"
          v-motion-slide-visible-right
          v-motion
          :initial="{ opacity: 0, x: 100 }"
          :enter="{ opacity: 1, x: 0, scale: 1 }"
          :variants="{ custom: { scale: 2 } }"
          :delay="1000"
        ></div>
        <div
          class="h-48 w-1/6 bg-red-400"
          v-motion-slide-visible-right
          v-motion
          :initial="{ opacity: 0, x: 100 }"
          :enter="{ opacity: 1, x: 0, scale: 1 }"
          :variants="{ custom: { scale: 2 } }"
          :delay="1500"
        ></div>
        <div
          class="h-48 w-1/6 bg-red-500"
          v-motion-slide-visible-right
          v-motion
          :initial="{ opacity: 0, x: 100 }"
          :enter="{ opacity: 1, x: 0, scale: 1 }"
          :variants="{ custom: { scale: 2 } }"
          :delay="2000"
        ></div>
        <div
          class="h-48 w-1/6 bg-red-600"
          v-motion-slide-visible-right
          v-motion
          :initial="{ opacity: 0, x: 100 }"
          :enter="{ opacity: 1, x: 0, scale: 1 }"
          :variants="{ custom: { scale: 2 } }"
          :delay="2500"
        ></div>
      </div>

      <div
        class="h-96 mb-48 w-1/8 bg-blue-200"
        v-motion-slide-visible-top
      ></div>
      <div
        class="h-96 mb-48 w-1/8 bg-green-200"
        v-motion-slide-visible-top
      ></div>
      <div class="h-96 mb-48 w-1/8 bg-red-200" v-motion-slide-visible-top></div>
    </div>
  </div>
</template>

<script lang="ts">
import { onUnmounted } from "vue";
import { useStore } from "vuex";
import PodiemIcon from "../components/icons/PodiemIcon.vue";

export default {
  components: {
    PodiemIcon,
  },
  setup() {
    // const store = useStore();
    // store.dispatch('navigation/toggleNavbarOpaqueStatus', true);
    // onUnmounted(() => {
    //     store.dispatch('navigation/toggleNavbarOpaqueStatus', false);
    // })
  },
};
</script>

<style scoped></style>
