import WhyPodiemCarousel from "@/core/infrastructure/repository/why_podiem/WhyPodiemCarousel"
import hasData from "@/core/domain/specification/hasData"

export default class WhyPodiemCarouselService {
    whyPodiemCallout: WhyPodiemCarousel

    constructor() {
        this.whyPodiemCallout = new WhyPodiemCarousel()
    }

    async process() {
        const { data, totalCount } = await this.whyPodiemCallout.fetch()

        if (!hasData(totalCount)) {
            throw new TypeError("No data was provided")
        }

        return data
    }
}
