import ContactInPageBannerSimpleService from "./service/contact/ContactInPageBannerSimpleService"
import ContactThreeColumnImageSimpleService from "./service/contact/ContactThreeColumnImageSimpleService"
import ContactFooterCalloutService from "./service/contact/ContactFooterCalloutService"

export default class Contact {
    inPageBannerSimple: ContactInPageBannerSimpleService
    threeColumnImageSimple: ContactThreeColumnImageSimpleService
    footerCallout: ContactFooterCalloutService

    constructor() {
        this.inPageBannerSimple = new ContactInPageBannerSimpleService()
        this.threeColumnImageSimple = new ContactThreeColumnImageSimpleService()
        this.footerCallout = new ContactFooterCalloutService()
    }

    async getAllData() {
        const inPageBannerSimple = await this.inPageBannerSimple.process()
        const threeColumnImageSimple = await this.threeColumnImageSimple.process()
        const footerCallout = await this.footerCallout.process()

        return {
            inPageBannerSimple,
            threeColumnImageSimple,
            footerCallout,
        }
    }
}
