<template>
  <div class="md:container md:mx-auto py-12 pt-88px mt-5">
    <div class="flex flex-row gap-x-5">
      <aside aria-label="Sidebar" class="h-screen sticky top-24">
        <podiem-text-icon-blue v-if="!darkModeStatus"></podiem-text-icon-blue>
        <podiem-text-white-icon v-else></podiem-text-white-icon>
        <div class="px-3 py-4 rounded">
          <ul class="space-y-2">
            <li class="uppercase text-xs" style="letter-spacing: 3px">
              Components
            </li>
            <dev-menu-item url="#typography" text="Typography"></dev-menu-item>
            <dev-menu-item url="#shadow" text="Shadow"></dev-menu-item>
            <dev-menu-item url="#bradius" text="Border Radius"></dev-menu-item>
            <dev-menu-item url="#buttons" text="Buttons"></dev-menu-item>
            <dev-menu-item url="#inputs" text="Input"></dev-menu-item>
            <dev-menu-item url="#selects" text="Select"></dev-menu-item>
            <dev-menu-item url="#headings" text="Heading"></dev-menu-item>
            <dev-menu-item url="#tabs" text="Tabs"></dev-menu-item>
            <dev-menu-item url="#accordion" text="Accordion"></dev-menu-item>
            <dev-menu-item
              url="#horizontal-scroll"
              text="Horizontal Scroll"
            ></dev-menu-item>
            <dev-menu-item url="#callout" text="Call-Out Block"></dev-menu-item>
            <dev-menu-item
              url="#threecolumns"
              text="Three Columns"
            ></dev-menu-item>

            <li>
              <a
                href="/animate-sample"
                class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
              >
                Go to Animation Page
              </a>
            </li>

            <li>
              <a
                href="#"
                class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
              >
                <label for="toogleA" class="flex items-center cursor-pointer">
                  <!-- toggle -->
                  <div class="relative">
                    <!-- input -->
                    <input
                      id="toogleA"
                      @change="togglePreference()"
                      type="checkbox"
                      class="sr-only"
                    />
                    <!-- line -->
                    <div
                      class="w-10 h-4 bg-gray-400 rounded-full shadow-inner"
                    ></div>
                    <!-- dot -->
                    <div
                      class="dot absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition"
                    ></div>
                  </div>
                  <!-- label -->
                  <div class="ml-3 font-medium">
                    {{ preferenceText }}
                  </div>
                </label>
              </a>
            </li>
          </ul>
        </div>
      </aside>
      <main>
        <h1 class="text-heading-md border-b border-gray-200 pb-2">
          {{ title }}
        </h1>
        <div class="py-8">
          <slot></slot>
        </div>
      </main>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, ref } from "vue";
import { useStore } from "vuex";

import DevMenuItem from "../atom/Dev/DevMenuItem.vue";
import PodiemTextIcon from "../icons/PodiemTextIcon.vue";
import PodiemTextIconBlue from "../icons/PodiemTextIconBlue.vue";
import PodiemTextWhiteIcon from "../icons/PodiemTextWhiteIcon.vue";

export default {
  name: "DevLayout",
  props: ["title"],
  components: {
    DevMenuItem,
    PodiemTextIconBlue,
    PodiemTextIcon,
    PodiemTextWhiteIcon,
  },
  setup() {
    const preferenceText = ref("Light");
    const store = useStore();
    const darkModeStatus = computed(
      () => store.getters["navigation/GET_isDarkModeOn"],
    );

    const togglePreference = () => {
      store.dispatch("navigation/toggleDarkMode", !darkModeStatus.value);
      preferenceText.value = darkModeStatus.value ? "Dark" : "Light";
    };

    return {
      togglePreference,
      darkModeStatus,
      preferenceText,
    };
  },
};
</script>

<style scoped>
aside {
  min-width: 250px;
  width: 20%;
}

main {
  width: 80%;
}

/* Toggle A */
input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #48bb78;
}

/* Toggle B */
input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #48bb78;
}
</style>
