import PricingThreeColumn from "@/core/infrastructure/repository/pricing/PricingThreeColumn"
import hasData from "@/core/domain/specification/hasData"

export default class PricingThreeColumnService {
    pricingThreeColumn: PricingThreeColumn

    constructor() {
        this.pricingThreeColumn = new PricingThreeColumn()
    }

    async process() {
        const { data, totalCount } = await this.pricingThreeColumn.fetch()

        if (!hasData(totalCount)) {
            throw new TypeError("No data was provided")
        }

        return data
    }
}
