<template>
  <div class="apercu-light copyright-text">
    {{ copyright }}
  </div>
</template>
<script lang="ts">
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  name: "Copyright",
  setup() {
    const store = useStore();
    const copyright = computed(
      () => store.getters["site_setting/GET_copyrightText"],
    );

    return {
      copyright,
    };
  },
};
</script>
