import ContactThreeColumnImageSimple from "@/core/infrastructure/repository/contact/ContactThreeColumnImageSimple"
import hasData from "@/core/domain/specification/hasData"

export default class ContactThreeColumnImageSimpleService {
    protected contactThreeColumnImageSimple: ContactThreeColumnImageSimple

    constructor() {
        this.contactThreeColumnImageSimple = new ContactThreeColumnImageSimple()
    }

    async process() {
        const { data, totalCount } =
      await this.contactThreeColumnImageSimple.fetch()

        if (!hasData(totalCount)) {
            throw new TypeError("No data was provided")
        }

        return data
    }
}
