import { ctaLink } from "./cta"

export const calloutBlockSchema = `calloutBlock->{
  image,
  imageDirection,
  headline,
  highlightedText,
  backgroundStyle,
  description,
  'ctaLink': ${ctaLink}
}`

export const threeColumnBlockSchema = `threeColumnBlock->{
  headline,
  highlightedText,
  preamble,
  threeColumnSection {
    'columnOne': columnSection1 {
      'title': contentTitle,
      'text': contentText
    },
    'columnTwo': columnSection2 {
      'title': contentTitle,
      'text': contentText
    },
    'columnThree': columnSection3 {
      'title': contentTitle,
      'text': contentText
    },
  }
}`

export const statisticBlockSchema = `statisticBlock-> {
  headline,
  highlightedText,
  statistic[] {
    text,
    image
  }
}`

export const heroBlock = `heroBlock-> {
  headline,
  highlightedText,
  description,
  image,
  'ctaLink': ${ctaLink}
}`

export const componentBlockSchema = `select(
  componentSchema == 'calloutBlock' => ${calloutBlockSchema},
  componentSchema == 'threeColumnBlock' => ${threeColumnBlockSchema},
  componentSchema == "statisticBlock" => ${statisticBlockSchema},
  componentSchema == "heroBlock" => ${heroBlock} 
)`