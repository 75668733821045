<template>
  <div v-if="headline" class="home-banner-container">
    <div class="home-banner-left-content z-10">
      <div v-motion-fade>
        <heading-with-highlights
          textLocation="home"
          :text="headline"
          :highlight="highlightedText"
        />

        <p class="text-white text-heading-1 apercu-light py-[20px]">
          {{ description }}
        </p>

        <button-default :buttonText="ctaLink.title" :redirection="ctaLink.internalLink" />
      </div>
    </div>

    <div v-if="image" class="home-banner-right-content" v-motion-fade>
      <img
        width="640"
        height="360"
        :src="parseSanityImage(image).url() as string"
        :alt="image.alt"
        class="w-full"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { toRefs } from "vue";
import HeadingWithHighlights from "@/components/atom/text/HeadingWithHighlights.vue";
import ButtonDefault from "@/components/atom/button/ButtonDefault.vue";
import { parseSanityImage } from "@/js/composable/parseSanityImage";

const props = defineProps({
  headline: {
    type: String,
    default: "All in one sales engagement platform for inside sales teams",
  },
  highlightedText: {
    type: String,
    default: "sales engagement",
  },
  description: {
    type: String,
    default: "Spend less time prospecting and more time qualifying leads",
  },
  ctaLink: {
    type: Object,
    default: () => ({}),
  },
  image: {
    type: Object,
    default: () => ({}),
  },
});


const { headline, highlightedText, description, ctaLink, image } = toRefs(props);

</script>
