<template>
  <button
    class="flex flex-row items-center gap-x-2 bg-primary-4 text-white w-fit rounded-full hover:bg-primary-2 disabled:bg-disabled-1 disabled:text-disabled-text-1"
  >
    <slot></slot>
    {{ buttonText }}
  </button>
</template>

<script lang="ts">
export default {
  name: "ButtonWithIcon",
  props: {
    buttonText: {
      type: String,
      default: "Button",
    },
    buttonSize: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped>
button:disabled {
  cursor: not-allowed;
}

:disabled::v-deep(path) {
  fill: #939597;
}
</style>
