<template>
  <div class="feature-tabs">
    <button
      v-for="(value, index) in featureCategory"
      :key="index"
      :class="{ active: activeTab === index }"
      @click="switchTab(index)"
    >
      {{ value.title }}
    </button>
  </div>

  <div class="feature-content">
    <slot></slot>
  </div>
</template>
<script lang="ts">
import { computed } from "vue";
import { useStore } from "vuex";
import { FEATURE_CATEGORY } from "@/js/BaseConstants";

export default {
  name: "FeatureTabs",
  setup() {
    const store = useStore();
    const activeTab = computed(
      () => store.getters["features/GET_activeFeatureTab"],
    );

    const switchTab = (tabIndex: number) => {
      store.dispatch("features/setActiveTab", tabIndex);
    };

    return {
      featureCategory: FEATURE_CATEGORY,
      switchTab,
      activeTab,
    };
  },
};
</script>
<style scoped>
.feature-content {
  padding-block: 70px;
}
.feature-tabs {
  width: 100%;
  height: 54px;
  /* background: #5CDDD4; */
  /* border: 1px solid #9EEDE3;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25); */
  border-radius: 50px;
  padding: 8px;
  display: flex;
  flex-direction: row;
  /* gap: 5px; */
  justify-content: space-between;
  /* overflow-x: auto; */
  flex-wrap: wrap;
  gap: 5px;
}

@media only screen and (min-width: 1024px) {
  .feature-tabs {
    width: 500px;
    margin: 0 auto;
  }
}

.feature-tabs button {
  width: fit-content;
  height: 100%;
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  padding: 10px 12px;
  white-space: nowrap;
  flex: 1;
  border: 1px solid #31918a;
  background-color: #5cddd4;
}

.feature-tabs button.active {
  color: white;
  background: #000;
}
</style>
