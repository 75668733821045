<template>
  <div>
    <section class="mt-[88px] lg:-mt-[50px] bg-white in-page-banner h-screen">
      <div class="section-container lg:pt-[50px]">
        <suspense>
          <in-page-banner
            :bannerLabel="inPageBanner.bannerLabel"
            :bannerText="inPageBanner.bannerText"
            :textHighlight="inPageBanner.textHighlight"
            :bannerDescription="inPageBanner.subheaderText"
            :bannerImage="inPageBanner.imagePanel"
          />
        </suspense>
      </div>
    </section>

    <section class="bg-neutral-5">
      <img
        src="/public/images/reversed_content_wave_top_xl_white.png"
        class="w-full"
        alt="Podiem Website"
      />
      <div class="section-container py-[80px]">
        <suspense>
          <three-column-image
            :description="threeColumnImage.subheaderText"
            :bannerText="threeColumnImage.bannerText"
            :textHighlight="threeColumnImage.textHighlight"
            :columnItems="threeColumnImage.columnItems"
          />
        </suspense>
      </div>
    </section>

    <section class="bg-neutral-5">
      <div class="section-container py-[80px]">
        <suspense>
          <two-column
            :bannerText="twoColumn.bannerText"
            :columnItems="twoColumn.twoColumnItems"
          />
        </suspense>
      </div>
    </section>

    <section
      class="bg-gradient-to-r from-primary-1 to-primary-3 pb-[130px] overflow-hidden -mt-[1px]"
    >
      <img
        src="/public/images/gray_top_wave.png"
        class="w-full mb-[3px]"
        alt="Podiem Website"
      />
      <div class="section-container py-[80px]">
        <suspense>
          <call-out-block
            textLocation="normal"
            textSize="lg"
            :calloutText="callout.bannerText"
            :calloutHighlight="callout.textHighlight"
            :calloutDescription="callout.subheaderText"
            :calloutImage="callout.imagePanel"
            :isCTAEnabled="callout.ctaEnabled"
            :ctaText="callout.ctaText"
            :ctaRedirection="callout.redirection"
          />
        </suspense>
      </div>
    </section>

    <section
      class="bg-gradient-to-r from-primary-1 to-primary-3 pb-[130px] overflow-hidden -mt-[1px]"
    >
      <suspense>
        <carousel
          :bannerText="carousel.bannerText"
          :textHighlight="carousel.textHighlight"
          :description="carousel.subheaderText"
          :carouselItems="carousel.carouselItems"
        />
      </suspense>
    </section>

    <section class="bg-neutral-5">
      <div class="footer-callout-container py-[40px]">
        <suspense>
          <footer-call-out-block
            :bannerText="footerCallout.bannerText"
            :bannerTextHighlight="footerCallout.textHighlight"
            :bannerImage="footerCallout.calloutImage"
            :ctaDetails="footerCallout.ctaDetails"
          />
        </suspense>
      </div>
    </section>
  </div>
</template>
<script lang="ts">
import { reactive } from "vue";
import HeadingWithHighlights from "@/components/atom/text/HeadingWithHighlights.vue";
import ThreeColumnImage from "@/components/molecule/ThreeColumnImage.vue";
import CallOutBlock from "@/components/molecule/CallOutBlock.vue";
import ShortBorder from "@/components/atom/text/ShortBorder.vue";
import FooterCallOutBlock from "@/components/molecule/FooterCallOutBlock.vue";
import InPageBanner from "@/components/molecule/InPageBanner.vue";
import Carousel from "@/components/organism/Carousel.vue";
import WhyPodiem from "@/core/application/WhyPodiem";
import WhyPodiemPageModel from "@/core/domain/model/WhyPodiemPageModel";
import TwoColumn from "@/components/molecule/TwoColumn.vue";

export default {
  name: "WhyPodiem",
  components: {
    HeadingWithHighlights,
    ThreeColumnImage,
    CallOutBlock,
    ShortBorder,
    FooterCallOutBlock,
    InPageBanner,
    Carousel,
    TwoColumn,
  },
  setup() {
    const inPageBanner = reactive({
      bannerLabel: "",
      bannerText: "",
      subheaderText: "",
      textHighlight: "",
      imagePanel: {},
    });

    const threeColumnImage = reactive({
      bannerText: "",
      textHighlight: "",
      subheaderText: "",
      ctaDetails: {},
      columnItems: {},
    });

    const twoColumn = reactive({
      bannerText: "",
      twoColumnItems: {},
    });

    const callout = reactive({
      bannerText: "",
      subheaderText: "",
      textHighlight: "",
      ctaEnabled: false,
      imagePanel: {},
      redirection: "",
      ctaText: "",
    });

    const carousel = reactive({
      bannerText: "",
      textHighlight: "",
      subheaderText: {},
      carouselItems: {},
    });

    const footerCallout = reactive({
      bannerText: "",
      textHighlight: "",
      calloutImage: {},
      ctaDetails: {},
    });

    const whyPodiem = new WhyPodiem();
    whyPodiem.getAllData().then((data: WhyPodiemPageModel) => {
      console.info(data);
      inPageBanner.bannerText = data.inPageBanner.bannerText!;
      inPageBanner.textHighlight = data.inPageBanner.text_highlight!;
      inPageBanner.bannerLabel = data.inPageBanner.banner_label!;
      inPageBanner.subheaderText = data.inPageBanner.subheaderText!;
      inPageBanner.imagePanel = data.inPageBanner.imagePanel!;

      threeColumnImage.bannerText = data.threeColumn.bannerText!;
      threeColumnImage.textHighlight = data.threeColumn.text_highlight!;
      threeColumnImage.subheaderText = data.threeColumn.subheaderText!;
      threeColumnImage.ctaDetails = data.threeColumn.cta_details!;
      threeColumnImage.columnItems = data.threeColumn.threeColumnSection!;

      twoColumn.bannerText = data.twoColumn.bannerText!;
      twoColumn.twoColumnItems = data.twoColumn.twoColumnSection!;

      callout.bannerText = data.callout.bannerText!;
      callout.textHighlight = data.callout.text_highlight!;
      callout.subheaderText = data.callout.subheaderText!;
      callout.ctaEnabled = data.callout.cta_enabled!;
      callout.imagePanel = data.callout.image!;
      callout.ctaText = data.callout.cta_text!;
      callout.redirection = data.callout.cta_redirection!;

      carousel.bannerText = data.carousel.bannerText!;
      carousel.textHighlight = data.carousel.text_highlight!;
      carousel.subheaderText = data.carousel.subheaderText!;
      carousel.carouselItems = data.carousel.carousel_container!;

      footerCallout.bannerText = data.footerCallout.bannerText!;
      footerCallout.textHighlight = data.footerCallout.text_highlight!;
      footerCallout.calloutImage = data.footerCallout.image!;
      footerCallout.ctaDetails = data.footerCallout.cta_details!;
    });

    return {
      inPageBanner,
      threeColumnImage,
      twoColumn,
      callout,
      carousel,
      footerCallout,
    };
  },
};
</script>
