/* eslint-disable @typescript-eslint/no-unsafe-call */
const state = () => ({
  pageTitle: "",
  pageDescription: "",
  pageDescriptionBlock: {},
  faqItems: {},
  activeTab: 0,
  activeSchemaId: "faq_pricing",
});

const mutations = {
  SET_PAGE_TITLE(state: { pageTitle: string }, payload: string) {
    state.pageTitle = payload;
  },
  SET_PAGE_DESCRIPTION(state: { pageDescription: string }, payload: string) {
    state.pageDescription = payload;
  },
  SET_PAGE_DESCRIPTION_BLOCK(
    state: { pageDescriptionBlock: object },
    payload: object,
  ) {
    state.pageDescriptionBlock = payload;
  },
  SET_FAQ_ITEMS(state: { faqItems: object }, payload: object) {
    state.faqItems = payload;
  },
  SET_ACTIVE_TAB(state: { activeTab: number }, payload: number) {
    state.activeTab = payload;
  },
  SET_ACTIVE_SCHEMA_ID(state: { activeSchemaId: string }, payload: string) {
    state.activeSchemaId = payload;
  },
};

const actions = {
  setPageBannerInfo(
    { commit }: any,
    data: {
      bannerText: string;
      subheaderText: string;
      simpleBlockContent: object;
    },
  ) {
    commit("SET_PAGE_TITLE", data.bannerText);
    commit("SET_PAGE_DESCRIPTION", data.subheaderText);
    commit("SET_PAGE_DESCRIPTION_BLOCK", data.simpleBlockContent);
  },
  setFaqItems({ commit }: any, data: { faqList: object }) {
    commit("SET_FAQ_ITEMS", data.faqList);
  },
  setActiveTabInfo(
    { commit }: any,
    data: { tabIndex: number; schemaId: string },
  ) {
    commit("SET_ACTIVE_TAB", data.tabIndex);
    commit("SET_ACTIVE_SCHEMA_ID", data.schemaId);
  },
};

const getters = {
  GET_pageTitle: (state: { pageTitle: string }) => state.pageTitle,
  GET_pageDescription: (state: { pageDescription: string }) =>
    state.pageDescription,
  GET_pageDescriptionBlock: (state: { pageDescriptionBlock: object }) =>
    state.pageDescriptionBlock,
  GET_pageBannerInfo: (state: { pageTitle: any; pageDescription: any }) => {
    return {
      pageTitle: state.pageTitle,
      pageDescription: state.pageDescription,
    };
  },
  GET_faqItems: (state: { faqItems: object }) => state.faqItems,
  GET_activeTab: (state: { activeTab: number }) => state.activeTab,
  GET_activeSchemaId: (state: { activeSchemaId: string }) =>
    state.activeSchemaId,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
