<template>
  <svg
    width="137"
    height="27"
    viewBox="0 0 137 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.6383 20.1447C12.5003 19.7785 13.2687 19.2714 13.934 18.6327C14.5993 17.9941 15.1334 17.2428 15.527 16.3882C15.9205 15.5336 16.1267 14.6133 16.1267 13.6178C16.1267 12.6411 15.9299 11.7489 15.5457 10.9225C15.1521 10.1054 14.6274 9.4011 13.9621 8.81884C13.2968 8.23658 12.5378 7.78579 11.6757 7.4571C10.8137 7.1284 9.90474 6.96875 8.9677 6.96875H0.178293L0.12207 26.8783H5.36011L5.37885 20.6988H8.93022C9.86726 20.6988 10.7762 20.511 11.6383 20.1447ZM9.43622 11.5799C9.70796 11.6926 9.94222 11.8428 10.139 12.0401C10.3358 12.2373 10.4951 12.4627 10.6169 12.7162C10.7293 12.9698 10.7949 13.2515 10.7949 13.5614C10.7949 13.8714 10.7387 14.1437 10.6169 14.4067C10.4951 14.6602 10.3358 14.8856 10.139 15.0828C9.93285 15.2801 9.69859 15.4303 9.43622 15.543C9.16448 15.6557 8.88337 15.7121 8.58351 15.7121H5.5194L5.52877 11.4296H8.57414C8.88337 11.4108 9.16448 11.4672 9.43622 11.5799Z"
      fill="white"
    />
    <path
      d="M34.5018 26.2024C35.7013 25.6859 36.7507 24.9722 37.6503 24.08C38.5405 23.1784 39.2526 22.1266 39.768 20.9057C40.2927 19.6943 40.5457 18.3983 40.5551 17.0177C40.5551 15.6372 40.3021 14.3412 39.7867 13.1297C39.2714 11.9183 38.5686 10.857 37.6784 9.95548C36.7882 9.05391 35.7481 8.34017 34.5487 7.81426C33.3493 7.28835 32.0749 7.02539 30.7162 7.02539C29.3481 7.02539 28.0644 7.28835 26.8744 7.81426C25.675 8.34017 24.6348 9.05391 23.7447 9.95548C22.8545 10.857 22.1423 11.9089 21.627 13.1297C21.1022 14.3412 20.8492 15.6372 20.8398 17.0177C20.8398 18.3983 21.0928 19.6943 21.6082 20.9057C22.1236 22.1172 22.8264 23.1784 23.7165 24.08C24.6067 24.9816 25.6468 25.6859 26.8369 26.2024C28.0269 26.719 29.3107 26.9819 30.6787 26.9819C32.0187 26.9819 33.3024 26.719 34.5018 26.2024ZM32.6559 12.2657C33.2556 12.5475 33.7616 12.9231 34.1926 13.3927C34.6237 13.8623 34.961 14.407 35.2046 15.0362C35.4483 15.656 35.5701 16.3228 35.5701 17.0177C35.5701 17.6939 35.4389 18.3513 35.1953 18.9899C34.9423 19.6191 34.6049 20.1732 34.1739 20.6428C33.7428 21.1123 33.2275 21.488 32.6278 21.7697C32.0281 22.0515 31.3815 22.1923 30.6787 22.1923C29.976 22.1923 29.3294 22.0515 28.7391 21.7697C28.1394 21.488 27.6334 21.1123 27.2023 20.6428C26.7713 20.1732 26.434 19.6191 26.1903 18.9899C25.9467 18.3607 25.8249 17.7033 25.8249 17.0177C25.8249 16.3228 25.9561 15.6654 26.1997 15.0362C26.4433 14.4163 26.79 13.8623 27.2211 13.3927C27.6521 12.9231 28.1675 12.5475 28.7672 12.2657C29.3669 11.984 30.0134 11.8431 30.7162 11.8431C31.4096 11.8431 32.0562 11.984 32.6559 12.2657Z"
      fill="white"
    />
    <path
      d="M57.5436 26.1079C58.7336 25.5914 59.7738 24.8777 60.6639 23.9855C61.5541 23.0933 62.2569 22.0321 62.7723 20.8206C63.2876 19.6091 63.55 18.3225 63.55 16.9608C63.55 15.5991 63.297 14.3124 62.791 13.101C62.285 11.8895 61.5822 10.8377 60.7014 9.93609C59.8112 9.04392 58.7805 8.33018 57.5905 7.81366C56.4004 7.29714 55.126 7.03418 53.7673 7.03418H45.0435L44.9873 26.8968H53.7111C55.0792 26.8874 56.3536 26.6338 57.5436 26.1079ZM55.1354 12.2651C55.7445 12.5281 56.2786 12.8944 56.7284 13.3545C57.1782 13.8147 57.5342 14.3594 57.7966 14.9792C58.059 15.599 58.1808 16.2658 58.1808 16.9608C58.1808 17.6651 58.0496 18.3225 57.7872 18.9424C57.5249 19.5622 57.1688 20.1069 56.719 20.5671C56.2692 21.0272 55.7351 21.3935 55.1167 21.6564C54.5076 21.9194 53.8423 22.0509 53.1395 22.0509H50.1504L50.1785 11.8613H53.1676C53.861 11.8707 54.517 12.0022 55.1354 12.2651Z"
      fill="white"
    />
    <path
      d="M73.4174 26.9437L73.4642 7.27832H68.2636L68.2168 26.9437H73.4174Z"
      fill="white"
    />
    <path
      d="M93.7788 26.8973L93.7882 22.1923H83.3215L83.3309 19.3561H93.1979L93.2072 14.6323H83.3403L83.3496 11.7961H93.8163L93.8257 7.07227H78.2896L78.2334 26.9067H93.7788V26.8973Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M106.382 7.07227H113.138L117.786 24.6904H111.03L106.382 7.07227Z"
      fill="#008AFE"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M123.08 -0.0185547H129.536L129.518 26.9157H123.062L123.08 -0.0185547Z"
      fill="#008AFE"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M106.382 7.07227H113.138L102.512 26.9161H95.7656L106.382 7.07227Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M122.846 -0.0185547H129.583L117.748 24.69H111.011L122.846 -0.0185547Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M129.63 14.3506H136.179L129.62 26.9162H123.07L129.63 14.3506Z"
      fill="white"
    />
  </svg>
</template>

<script lang="ts">
export default {
  name: "PodiemTextWhiteIcon",
};
</script>

<style scoped></style>
