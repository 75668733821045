<template>
  <p class="text-neutral-6">
    {{ text }}
  </p>
</template>
<script lang="ts">
import { toRefs } from "vue";

export default {
  name: "InPageBannerDescription",
  props: {
    text: {
      type: String,
      default:
        "We know what it feels like to jump between multiple tools in the tech stack, that’s why we built Podiem.",
    },
  },
  setup(props: any) {
    const { text } = toRefs(props);

    return {
      text,
    };
  },
};
</script>
<style lang=""></style>
