<template>
  <div>
    <section class="mt-[88px] lg:-mt-[50px] bg-white in-page-banner h-screen">
      <div class="section-container lg:pt-[50px]">
        <suspense>
          <in-page-banner
            :bannerLabel="inPageBanner.bannerLabel"
            :bannerText="inPageBanner.bannerText"
            :textHighlight="inPageBanner.textHighlight"
            :bannerDescription="inPageBanner.subheaderText"
            :bannerImage="inPageBanner.imagePanel"
          />
        </suspense>
      </div>
    </section>

    <section class="bg-neutral-5">
      <img
        src="/public/images/reversed_content_wave_top_xl_white.png"
        class="w-full"
        alt="Podiem Website"
      />
      <div class="section-container py-[80px]">
        <suspense>
          <three-column-image
            :description="threeColumnImage.subheaderText"
            :bannerText="threeColumnImage.bannerText"
            :textHighlight="threeColumnImage.textHighlight"
            :columnItems="threeColumnImage.columnItems"
          />
        </suspense>
      </div>
    </section>

    <section
      v-if="twoColumnImageRight"
      class="bg-white overflow-hidden -mt-[1px]"
    >
      <div class="section-container py-[80px]">
        <div class="flex flex-col lg:flex-row">
          <div class="flex-1 text-content flex-grow">
            <div class="flex flex-col h-full justify-center items-center">
              <heading-with-highlights
                textSize="lg"
                :text="twoColumnImageRight.bannerText"
                :highlight="twoColumnImageRight.textHighlight"
              />
              <div class="space-20px"></div>
              <header-description
                :textContent="twoColumnImageRight.subheaderText"
              />
            </div>
          </div>
          <div class="flex-1 flex items-center justify-center">
            <img
              width="640"
              height="360"
              class="w-full md:w-[380px]"
              :src="parseSanityImage(twoColumnImageRight.imagePanel)"
              :alt="twoColumnImageRight.imagePanel.alt"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="bg-neutral-5 overflow-hidden -mt-[1px]">
      <div class="section-container py-[80px]">
        <suspense>
          <two-column
            :bannerText="twoColumn.bannerText"
            :columnItems="twoColumn.twoColumnItems"
          />
        </suspense>
      </div>
    </section>

    <section class="bg-white overflow-hidden -mt-[1px]">
      <div class="section-container py-[80px]">
        <div v-if="twoColumnImageLeft" class="flex flex-col lg:flex-row">
          <div class="flex-1 flex-grow flex items-center justify-center">
            <img
              width="640"
              height="360"
              class="w-full md:w-[380px]"
              :src="parseSanityImage(twoColumnImageLeft.imagePanel)"
              :alt="twoColumnImageLeft.imagePanel.alt"
            />
          </div>

          <div class="flex-1 text-content">
            <div class="flex flex-col h-full justify-center items-center">
              <heading-with-highlights
                textSize="lg"
                :text="twoColumnImageLeft.bannerText"
                :highlight="twoColumnImageLeft.textHighlight"
              />
              <div class="space-20px"></div>
              <header-description
                :textContent="twoColumnImageLeft.subheaderText"
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="bg-neutral-5 overflow-hidden -mt-[1px]">
      <div class="section-container py-[80px]">
        <suspense>
          <two-column
            :bannerText="twoColumn2.bannerText"
            :columnItems="twoColumn2.twoColumnItems"
          />
        </suspense>
      </div>
    </section>

    <section class="bg-neutral-5">
      <div class="footer-callout-container py-[40px]">
        <suspense>
          <footer-call-out-block
            :bannerText="footerCallout.bannerText"
            :bannerTextHighlight="footerCallout.textHighlight"
            :bannerImage="footerCallout.calloutImage"
            :ctaDetails="footerCallout.ctaDetails"
          />
        </suspense>
      </div>
    </section>
  </div>
</template>
<script lang="ts">
import get from "lodash/get";
import { reactive } from "vue";
import { useRoute } from "vue-router";
import PlainCallOutBlock from "@/components/molecule/PlainCallOutBlock.vue";
import CallOutBlock from "@/components/molecule/CallOutBlock.vue";
import Carousel from "@/components/organism/Carousel.vue";
import FooterCallOutBlock from "@/components/molecule/FooterCallOutBlock.vue";
import ThreeColumnImage from "@/components/molecule/ThreeColumnImage.vue";
import InPageBanner from "@/components/molecule/InPageBanner.vue";
import TwoColumn from "@/components/molecule/TwoColumn.vue";
import HeadingWithHighlights from "@/components/atom/text/HeadingWithHighlights.vue";
import HeaderDescription from "@/components/atom/text/HeaderDescription.vue";
import ButtonDefault from "@/components/atom/button/ButtonDefault.vue";
import { parseSanityImage } from "@/js/composable/parseSanityImage";

import FeatureDetail from "@/core/application/FeatureDetail";

export default {
  name: "FeatureDetail",
  components: {
    CallOutBlock,
    Carousel,
    FooterCallOutBlock,
    ThreeColumnImage,
    InPageBanner,
    PlainCallOutBlock,
    TwoColumn,
    HeadingWithHighlights,
    HeaderDescription,
    ButtonDefault,
  },
  setup() {
    const inPageBanner = reactive({
      bannerLabel: "",
      bannerText: "",
      subheaderText: "",
      textHighlight: "",
      imagePanel: {
        _type: "",
        alt: "",
      },
    });

    const threeColumnImage = reactive({
      bannerText: "",
      textHighlight: "",
      subheaderText: "",
      columnItems: {},
    });

    const twoColumn = reactive({
      bannerText: "",
      twoColumnItems: {},
    });

    const twoColumnImageRight = reactive({
      bannerText: "",
      subheaderText: "",
      textHighlight: "",
      imagePanel: {},
    });

    const twoColumn2 = reactive({
      bannerText: "",
      twoColumnItems: {},
    });

    const twoColumnImageLeft = reactive({
      bannerText: "",
      subheaderText: "",
      textHighlight: "",
      imagePanel: {},
    });

    const footerCallout = reactive({
      bannerText: "",
      textHighlight: "",
      calloutImage: {},
      ctaDetails: {},
    });

    const route = useRoute();
    const key = route.params.id.toString();

    const featureDetail = new FeatureDetail();
    featureDetail.setKey(key);

    const getAllData = featureDetail.process();

    getAllData.then((data: any) => {
      console.info(data);
      inPageBanner.bannerLabel = data.inPageBanner.banner_label!;
      inPageBanner.bannerText = data.inPageBanner.bannerText!;
      inPageBanner.subheaderText = data.inPageBanner.subheaderText!;
      inPageBanner.textHighlight = data.inPageBanner.text_highlight!;
      inPageBanner.imagePanel = data.inPageBanner.imagePanel!;

      threeColumnImage.bannerText = data.threeColumnImage.bannerText!;
      threeColumnImage.textHighlight = data.threeColumnImage.text_highlight!;
      threeColumnImage.subheaderText = data.threeColumnImage.subheaderText!;
      threeColumnImage.columnItems = data.threeColumnImage.threeColumnSection!;

      twoColumn.bannerText = data.twoColumn.bannerText!;
      twoColumn.twoColumnItems = data.twoColumn.twoColumnSection!;

      twoColumnImageRight.bannerText = data.twoColumnImageRight.bannerText!;
      twoColumnImageRight.subheaderText =
        data.twoColumnImageRight.subheaderText!;
      twoColumnImageRight.textHighlight =
        data.twoColumnImageRight.text_highlight!;
      twoColumnImageRight.imagePanel = data.twoColumnImageRight.imagePanel!;

      twoColumn2.bannerText = data.twoColumn2.bannerText!;
      twoColumn2.twoColumnItems = data.twoColumn2.twoColumnSection!;

      twoColumnImageLeft.bannerText = data.twoColumnImageLeft.bannerText!;
      twoColumnImageLeft.subheaderText = data.twoColumnImageLeft.subheaderText!;
      twoColumnImageLeft.textHighlight =
        data.twoColumnImageLeft.text_highlight!;
      twoColumnImageLeft.imagePanel = data.twoColumnImageLeft.imagePanel!;

      footerCallout.bannerText = data.footerCallout.bannerText!;
      footerCallout.textHighlight = data.footerCallout.text_highlight!;
      footerCallout.calloutImage = data.footerCallout.imagePanel!;
      footerCallout.ctaDetails = {
        cta_enabled: data.footerCallout.cta_enabled!,
        cta_redirection: data.footerCallout.cta_redirection!,
        cta_text: data.footerCallout.cta_text!,
      };
    });

    return {
      get,
      parseSanityImage,
      inPageBanner,
      threeColumnImage,
      twoColumn,
      twoColumnImageRight,
      twoColumn2,
      twoColumnImageLeft,
      footerCallout,
    };
  },
};
</script>
<style lang=""></style>
