import CompanySimpleCalloutRepository from "@/core/infrastructure/repository/company/CompanySimpleCalloutRepository"
import hasData from "@/core/domain/specification/hasData"

export default class CompanySimpleCalloutService {
    protected companySimpleCalloutRepository: CompanySimpleCalloutRepository

    constructor() {
        this.companySimpleCalloutRepository = new CompanySimpleCalloutRepository()
    }

    async process() {
        const { data, totalCount } =
      await this.companySimpleCalloutRepository.fetch()

        if (!hasData(totalCount)) {
            throw new TypeError("No data was provided")
        }

        return data
    }
}
