import FaqBannerService from "./service/faq/FaqBannerService"
import FaqFooterCalloutService from "./service/faq/FaqFooterCalloutService"
import FaqItemService from "./service/faq/FaqItemService"

export default class Faq {
    faqBannerService: FaqBannerService
    faqFooterCalloutService: FaqFooterCalloutService
    faqItem: FaqItemService

    constructor() {
        this.faqBannerService = new FaqBannerService()
        this.faqFooterCalloutService = new FaqFooterCalloutService()
        this.faqItem = new FaqItemService()
    }

    async getAllData() {
        const faqBanner = await this.faqBannerService.process()
        const faqFooterCallout = await this.faqFooterCalloutService.process()
        const faqItem = await this.faqItem.process()

        return {
            faqBanner,
            faqFooterCallout,
            faqItem,
        }
    }
}
