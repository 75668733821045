import DemoInPageBannerSimpleService from "./service/demo/DemoInPageBannerSimpleService"
import DemoThreeColumnImageSimpleService from "./service/demo/DemoThreeColumnImageSimpleService"
import DemoFooterCalloutService from "./service/demo/DemoFooterCalloutService"

export default class Demo {
    inPageBannerSimple: DemoInPageBannerSimpleService
    threeColumnImageSimple: DemoThreeColumnImageSimpleService
    footerCallout: DemoFooterCalloutService

    constructor() {
        this.inPageBannerSimple = new DemoInPageBannerSimpleService()
        this.threeColumnImageSimple = new DemoThreeColumnImageSimpleService()
        this.footerCallout = new DemoFooterCalloutService()
    }

    async getAllData() {
        const inPageBannerSimple = await this.inPageBannerSimple.process()
        const threeColumnImageSimple = await this.threeColumnImageSimple.process()
        const footerCallout = await this.footerCallout.process()

        return {
            inPageBannerSimple,
            threeColumnImageSimple,
            footerCallout,
        }
    }
}
