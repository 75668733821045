import PricingCallout from "@/core/infrastructure/repository/pricing/PricingCallout"
import hasData from "@/core/domain/specification/hasData"

export default class PricingCalloutService {
    pricingCallout: PricingCallout

    constructor() {
        this.pricingCallout = new PricingCallout()
    }

    async process() {
        const { data, totalCount } = await this.pricingCallout.fetch()

        if (!hasData(totalCount)) {
            throw new TypeError("No data was provided")
        }

        return data
    }
}
