<template>
  <p class="m-0 text-neutral-2 leading-none text-center" :class="customClass">
    {{ textContent }}
  </p>
</template>

<script lang="ts">
import { toRefs } from "vue";

export default {
  name: "SimpleBannerDescription",
  props: {
    textContent: {
      type: String,
      default: "Sample Description",
    },
    customClass: {
      type: String,
      default: "",
    },
  },
  setup(props: any) {
    const { textContent, customClass } = toRefs(props);

    return {
      textContent: textContent,
      customClass: customClass,
    };
  },
};
</script>
