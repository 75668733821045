import FeatureSimpleCallout from "@/core/infrastructure/repository/feature/FeatureSimpleCallout"
import hasData from "@/core/domain/specification/hasData"

export default class FeatureSimpleCalloutService {
    protected featureSimpleCallout: FeatureSimpleCallout

    constructor() {
        this.featureSimpleCallout = new FeatureSimpleCallout()
    }

    async process() {
        const { data, totalCount } = await this.featureSimpleCallout.fetch()

        if (!hasData(totalCount)) {
            throw new TypeError("No data was provided")
        }

        return data
    }
}
