<template>
  <a
    :href="linkRedirection"
    class="apercu-light hover:underline block px-3 py-2 text-base"
    :class="customClass"
  >
    {{ linkText }}
  </a>
</template>

<script lang="ts">
import { ref, watch } from "vue";
import { useStore } from "vuex";

import * as utils from "@/js/utils";

export default {
  name: "NavItem",
  props: {
    linkText: {
      type: String,
    },
    linkRedirection: {
      type: String,
      default: "javascript:void(0)",
    },
  },
  setup() {
    const store = useStore();
    const customClass = ref("text-white");

    customClass.value = utils.isSmallScreen
      ? "text-neutral-6 hover:text-primary-1"
      : "text-white";

    watch(
      () => store.getters["navigation/GET_navbarOpaqueStatus"],
      (value) => {
        if (value) {
          customClass.value = "text-neutral-6 hover:text-primary-1";
        } else {
          if (!utils.isSmallScreen) {
            customClass.value = "text-white";
          }
        }
      },
    );

    return {
      customClass,
    };
  },
};
</script>
