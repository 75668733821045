import { createApp } from "vue";
import { MotionPlugin } from "@vueuse/motion";
import VueConfetti from "vue-confetti";
import VueGtag from "vue-gtag-next";

import App from "./App.vue";
import router from "./router";
import store from "./store/index.js";

import "animate.css";
import "./assets/index.css";

const GOOGLE_TAG_ID: string = import.meta.env.VITE_GOOGLE_TAG_ID;

const app = createApp(App);

app.use(MotionPlugin);
app.use(VueConfetti);
app.use(router);
app.use(store);
app.use(VueGtag, {
  useDebugger: true,
  isEnabled: false,
  property: {
    id: GOOGLE_TAG_ID,
  },
  params: {
    anonymize_ip: true,
  },
});

app.mount("#app");
