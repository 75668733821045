import CompanyImagePanelRepository from "@/core/infrastructure/repository/company/CompanyImagePanelRepository"
import hasData from "@/core/domain/specification/hasData"

export default class CompanyImagePanelService {
    protected companyImagePanelRepository: CompanyImagePanelRepository

    constructor() {
        this.companyImagePanelRepository = new CompanyImagePanelRepository()
    }

    async process() {
        const { data, totalCount } = await this.companyImagePanelRepository.fetch()

        if (!hasData(totalCount)) {
            throw new TypeError("No data was provided")
        }

        return data
    }
}
