/* eslint-disable @typescript-eslint/no-unsafe-call */
const state = () => ({
  darkModeOn: false,
  navbarOpaqueStatus: false,
  navbarVisible: true,
  mobileMenuOpen: false,
  footerVisible: true,
});

const mutations = {
  SET_DARK_MODE_STATUS(state: { darkModeOn: boolean }, payload: boolean) {
    state.darkModeOn = payload;
  },
  SET_NAVBAR_OPAQUE_STATUS(
    state: { navbarOpaqueStatus: boolean },
    payload: boolean,
  ) {
    state.navbarOpaqueStatus = payload;
  },
  SET_NAVBAR_VISIBILITY_STATUS(
    state: { navbarVisible: boolean },
    payload: boolean,
  ) {
    state.navbarVisible = payload;
  },
  SET_MOBILE_MENU_STATUS(state: { mobileMenuOpen: boolean }, payload: boolean) {
    state.mobileMenuOpen = payload;
  },
  SET_NAVBAR_FOOTER_STATUS(
    state: { footerVisible: boolean },
    payload: boolean,
  ) {
    state.footerVisible = payload;
  },
};

const actions = {
  toggleDarkMode({ commit }: any, status: boolean) {
    commit("SET_DARK_MODE_STATUS", status);
  },
  toggleNavbarOpaqueStatus({ commit }: any, status: boolean) {
    commit("SET_NAVBAR_OPAQUE_STATUS", status);
  },
  toggleNavbarVisibilityStatus({ commit }: any, status: boolean) {
    commit("SET_NAVBAR_VISIBILITY_STATUS", status);
  },
  toggleMobileMenuStatus({ commit }: any, status: boolean) {
    commit("SET_MOBILE_MENU_STATUS", status);
  },
  toggleFooterVisibilityStatus({ commit }: any, status: boolean) {
    commit("SET_NAVBAR_FOOTER_STATUS", status);
  },
};

const getters = {
  GET_isDarkModeOn: (state: { darkModeOn: boolean }) => state.darkModeOn,
  GET_navbarOpaqueStatus: (state: { navbarOpaqueStatus: boolean }) =>
    state.navbarOpaqueStatus,
  GET_navbarVisibilityStatus: (state: { navbarVisible: boolean }) =>
    state.navbarVisible,
  GET_navbarMobileMenuStatus: (state: { mobileMenuOpen: boolean }) =>
    state.mobileMenuOpen,
  GET_footerVisibilityStatus: (state: { footerVisible: boolean }) =>
    state.footerVisible,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
