<template>
  <div v-if="isFooterVisible" class="bg-neutral-5 w-full">
    <div class="footer-container mx-auto">
      <div class="flex flex-col lg:flex-row gap-y-10 lg:gap-x-20 w-full mb-16">
        <div
          class="flex flex-col lg:flex-row gap-y-5 justify-between lg:w-6/12 xl:w-6/12"
        >
          <!-- <div class="flex flex-col lg:flex-row gap-y-5 justify-between lg:w-10/12 lg:mx-auto"> -->
          <ul class="flex flex-col gap-y-3 mb-5">
            <li class="title apercu-bold">Podiem</li>
            <!-- <li>
                            <a href="/feature" class="apercu-light">Features</a>
                        </li> -->
            <li>
              <a href="/faq" class="apercu-light">FAQs</a>
            </li>
            <li>
              <a href="/pricing" class="apercu-light">Pricing</a>
            </li>
          </ul>

          <ul class="flex flex-col gap-y-3 mb-5">
            <li class="title apercu-bold">Company</li>
            <li>
              <a href="/company" class="apercu-light">About</a>
            </li>
            <li>
              <a href="/contact-us" class="apercu-light">Contact</a>
            </li>
          </ul>

          <ul class="flex flex-col gap-y-3 mb-5">
            <li class="title apercu-bold">Follow Us</li>
            <li>
              <social-icon-vue />
            </li>
          </ul>
        </div>

        <div class="lg:w-6/12 xl:w-4/12">
          <subscribe />
        </div>
      </div>

      <!-- Footer Icon -->
      <div
        class="footer-bottom flex flex-col lg:flex-row gap-y-9 md:gap-x-10 justify-between"
      >
        <div
          class="flex flex-col lg:flex-row gap-y-5 lg:items-center lg:gap-x-5 md:w-5/12"
        >
          <NewPodiemIcon class="w-[140px]" />
          <p class="text-gray-400 apercu-light">
            Join our online community for free and hear about the latest news.
          </p>
        </div>

        <div class="md:w-7/12">
          <ul
            class="flex flex-col md:flex-row gap-y-6 mb-5 text-gray-400 md:justify-between"
          >
            <li>
              <a href="/terms-of-use" class="apercu-light underline"
                >Terms of Use</a
              >
            </li>
            <li>
              <a href="/privacy-policy" class="apercu-light underline"
                >Privacy Policy</a
              >
            </li>
            <li>
              <a href="/cookies" class="apercu-light underline"
                >Cookies Policy</a
              >
            </li>
            <li>
              <copyright />
            </li>
          </ul>
        </div>
      </div>
    </div>

    <consent-banner />
  </div>
</template>

<script lang="ts">
import { computed } from "vue";
import { useStore } from "vuex";
import PodiemFooterIcon from "../icons/PodiemFooterIcon.vue";
import FooterCallOutBlock from "../molecule/FooterCallOutBlock.vue";
import SocialIconVue from "../molecule/footer/SocialIcon.vue";
import Copyright from "../atom/text/Copyright.vue";
import ConsentBanner from "../molecule/consent_banner/ConsentBanner.vue";
import Subscribe from "../molecule/footer/Subscribe.vue";
import NewPodiemIcon from "../icons/NewPodiemIcon.vue";

export default {
  name: "FooterMenu",
  components: {
    PodiemFooterIcon,
    FooterCallOutBlock,
    SocialIconVue,
    Copyright,
    ConsentBanner,
    Subscribe,
    NewPodiemIcon,
  },
  setup() {
    const store = useStore();

    const isFooterVisible = computed(
      () => store.getters["navigation/GET_footerVisibilityStatus"],
    );

    return {
      isFooterVisible,
    };
  },
};
</script>

<style scoped></style>
