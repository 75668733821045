<template>
  <div>
    <img :src="parseSanityImage(asset)" :alt="alt" />
  </div>
</template>
<script lang="ts">
import { toRefs } from "vue";
import { parseSanityImage } from "@/js/composable/parseSanityImage";

export default {
  name: "Image",
  props: {
    alt: {
      type: String,
      default: "Podiem Website",
    },
    asset: {
      type: Object,
      default: {
        _ref: "image-c7aa9c7ff2cab6c57df928638dda5eb6e997d351-937x1414-png",
        _type: "reference",
      },
    },
  },
  setup(props: any) {
    const { alt, asset } = toRefs(props);
    return {
      alt,
      asset,
      parseSanityImage,
    };
  },
};
</script>
