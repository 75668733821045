<template>
  <h4 class="text-sm text-primary-1 apercu-regular">
    {{ text }}
  </h4>
</template>

<script lang="ts">
import { toRefs } from "vue";
export default {
  props: {
    text: {
      type: String,
      default: "Small Heading Text",
    },
  },
  setup(props: any) {
    const { text } = toRefs(props);
    return {
      text,
    };
  },
};
</script>

<style scoped>
h4 {
  letter-spacing: 1%;
}
</style>
