<template>
  <div>
    <section class="mt-[88px] lg:-mt-[50px] bg-white in-page-banner h-screen">
      <div class="section-container lg:pt-[50px]">
        <suspense>
          <in-page-banner
            :bannerLabel="inPageBanner.bannerLabel"
            :bannerText="inPageBanner.bannerText"
            :textHighlight="inPageBanner.textHighlight"
            :bannerDescription="inPageBanner.subheaderText"
            :bannerImage="inPageBanner.imagePanel"
          />
        </suspense>
      </div>
    </section>

    <section class="template-1 bg-neutral-5">
      <div class="section-container-lg py-[80px]">
        <suspense>
          <call-out-block
            textLocation="normal"
            textSize="lg"
            :calloutText="callout1.bannerText"
            :calloutHighlight="callout1.textHighlight"
            :calloutDescription="callout1.subheaderText"
            :calloutImage="callout1.imagePanel"
            :isCTAEnabled="callout1.ctaEnabled"
            :ctaText="callout1.ctaText"
            :ctaRedirection="callout1.redirection"
          />
        </suspense>
      </div>
    </section>

    <section class="bg-neutral-5">
      <div class="section-container py-[80px]">
        <suspense>
          <three-column
            :bannerLabel="threeColumn.bannerLabel"
            :bannerText="threeColumn.bannerText"
            :textHighlight="threeColumn.textHighlight"
            :columnItems="threeColumn.columnItems"
            :ctaDetails="threeColumn.ctaDetails"
          />
        </suspense>
      </div>
    </section>

    <section class="bg-gradient-to-r from-primary-1 to-primary-3">
      <div class="template-2">
        <div class="section-container-lg py-[80px]">
          <suspense>
            <call-out-block
              textLocation="normal"
              textSize="lg"
              :calloutText="callout2.bannerText"
              :calloutHighlight="callout2.textHighlight"
              :calloutDescription="callout2.subheaderText"
              :calloutImage="callout2.imagePanel"
              :isCTAEnabled="callout2.ctaEnabled"
              :ctaText="callout2.ctaText"
              :ctaRedirection="callout2.redirection"
            />
          </suspense>
        </div>
      </div>
    </section>

    <section
      class="bg-gradient-to-r from-primary-1 to-primary-3 pb-[130px] overflow-hidden -mt-[1px]"
    >
      <suspense>
        <carousel
          :bannerText="carousel.bannerText"
          :textHighlight="carousel.textHighlight"
          :description="carousel.subheaderText"
          :carouselItems="carousel.carouselItems"
        />
      </suspense>
    </section>

    <section class="bg-white">
      <div class="section-container py-[80px]">
        <div>
          <small-heading :text="iconTable.bannerLabel" />
          <div class="space-20px"></div>

          <div class="w-full mb-10">
            <heading-with-highlights
              :text="iconTable.bannerText"
              :highlight="iconTable.textHighlight"
            />
            <header-description :textContent="iconTable.subheaderText" />
          </div>
        </div>

        <div class="mb-[50px] px-[25px]">
          <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            <template v-if="iconList">
              <div
                v-for="(icon, id) in iconList"
                :key="id"
                @click="goToFeatureSpecific(icon, id)"
                :class="{
                  'cursor-pointer hover:scale-110':
                    icon.hasOwnProperty('inPageBanner'),
                }"
                class="text-center ease-in transition-all duration-100"
              >
                <div class="mx-auto mb-[30px] max-w-[135px] max-h-[135px]">
                  <img
                    :src="parseSanityImage(icon.image)"
                    :alt="icon.image.alt"
                    :title="icon.text"
                  />
                </div>
                <h4 class="text-heading-sm apercu-medium">{{ icon.text }}</h4>
              </div>
            </template>
          </div>
        </div>

        <template v-if="iconTable.ctaEnabled">
          <button-outlined
            :buttonText="iconTable.ctaText"
            :redirection="iconTable.ctaRedirection"
          />
        </template>
      </div>
    </section>

    <section class="template-1 bg-neutral-5">
      <div class="section-container-lg py-[80px]">
        <suspense>
          <plain-call-out-block
            textLocation="normal"
            :bannerText="simpleCallout.bannerText"
            :textHighlight="simpleCallout.textHighlight"
            :calloutImage="simpleCallout.imagePanel"
          />
        </suspense>
      </div>
    </section>

    <section class="bg-neutral-5">
      <div class="footer-callout-container py-[40px]">
        <suspense>
          <footer-call-out-block
            :bannerText="footerCallout.bannerText"
            :bannerTextHighlight="footerCallout.textHighlight"
            :bannerImage="footerCallout.calloutImage"
            :ctaDetails="footerCallout.ctaDetails"
          />
        </suspense>
      </div>
    </section>
  </div>
</template>
<script lang="ts">
import { Ref, ref, reactive } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import ButtonOutlined from "@/components/atom/button/ButtonOutlined.vue";
import CallOutBlock from "@/components/molecule/CallOutBlock.vue";
import Carousel from "@/components/organism/Carousel.vue";
import FooterCallOutBlock from "@/components/molecule/FooterCallOutBlock.vue";
import HeadingWithHighlights from "@/components/atom/text/HeadingWithHighlights.vue";
import InPageBanner from "@/components/molecule/InPageBanner.vue";
import PlainCallOutBlock from "@/components/molecule/PlainCallOutBlock.vue";
import ThreeColumn from "@/components/molecule/ThreeColumn.vue";
import SmallHeading from "@/components/atom/text/SmallHeading.vue";
import { parseSanityImage } from "@/js/composable/parseSanityImage";
import HeaderDescription from "@/components/atom/text/HeaderDescription.vue";

import Feature from "@/core/application/Feature";
import FeaturePageModel from "@/core/domain/model/FeaturePageModel";

interface IconList {
  _key: string;
  _type: string;
  image: {
    _type: string;
    alt: string;
    asset: object;
  };
  text: string;
}

export default {
  name: "Feature",
  components: {
    ButtonOutlined,
    CallOutBlock,
    Carousel,
    FooterCallOutBlock,
    HeadingWithHighlights,
    InPageBanner,
    PlainCallOutBlock,
    ThreeColumn,
    SmallHeading,
    HeaderDescription,
  },
  props: {
    slug: {
      type: String,
      default: "",
    },
  },
  setup(props: any) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const inPageBanner = reactive({
      bannerLabel: "",
      bannerText: "",
      subheaderText: "",
      textHighlight: "",
      imagePanel: {},
    });

    const callout1 = reactive({
      bannerText: "",
      subheaderText: "",
      textHighlight: "",
      ctaEnabled: false,
      imagePanel: {},
      redirection: "",
      ctaText: "",
    });

    const threeColumn = reactive({
      bannerText: "",
      textHighlight: "",
      bannerLabel: "",
      ctaDetails: {},
      columnItems: {},
    });

    const callout2 = reactive({
      bannerText: "",
      subheaderText: "",
      textHighlight: "",
      ctaEnabled: false,
      imagePanel: {},
      redirection: "",
      ctaText: "",
    });

    const carousel = reactive({
      bannerText: "",
      textHighlight: "",
      subheaderText: {},
      carouselItems: {},
    });

    const iconTable = reactive({
      bannerText: "",
      textHighlight: "",
      bannerLabel: "",
      subheaderText: "",
      ctaEnabled: false,
      ctaText: "",
      ctaRedirection: "",
      iconList: [],
    });

    const iconList: Ref<IconList[]> = ref([]);

    const simpleCallout = reactive({
      bannerText: "",
      textHighlight: "",
      imagePanel: {},
    });

    const footerCallout = reactive({
      bannerText: "",
      textHighlight: "",
      calloutImage: {},
      ctaDetails: {},
    });

    const feature = new Feature();
    feature.getAllData().then((data: FeaturePageModel) => {
      inPageBanner.bannerLabel = data.inPageBanner.banner_label!;
      inPageBanner.bannerText = data.inPageBanner.bannerText!;
      inPageBanner.subheaderText = data.inPageBanner.subheaderText!;
      inPageBanner.textHighlight = data.inPageBanner.text_highlight!;
      inPageBanner.imagePanel = data.inPageBanner.imagePanel!;

      callout1.bannerText = data.callout1.bannerText!;
      callout1.textHighlight = data.callout1.text_highlight!;
      callout1.subheaderText = data.callout1.subheaderText!;
      callout1.imagePanel = data.callout1.image!;
      callout1.ctaEnabled = data.callout1.cta_enabled!;
      callout1.ctaText = data.callout1.cta_text!;
      callout1.redirection = data.callout1.cta_redirection!;

      threeColumn.bannerText = data.threeColumn.bannerText!;
      threeColumn.textHighlight = data.threeColumn.text_highlight!;
      threeColumn.bannerLabel = data.threeColumn.banner_label!;
      threeColumn.ctaDetails = data.threeColumn.cta_details!;
      threeColumn.columnItems = data.threeColumn.column_items!;

      carousel.bannerText = data.carousel.bannerText!;
      carousel.textHighlight = data.carousel.text_highlight!;
      carousel.subheaderText = data.carousel.subheaderText!;
      carousel.carouselItems = data.carousel.carousel_container!;

      callout2.bannerText = data.callout2.bannerText!;
      callout2.textHighlight = data.callout2.text_highlight!;
      callout2.subheaderText = data.callout2.subheaderText!;
      callout2.imagePanel = data.callout2.image!;
      callout2.ctaEnabled = data.callout2.cta_enabled!;
      callout2.ctaText = data.callout2.cta_text!;
      callout2.redirection = data.callout2.cta_redirection!;

      iconTable.bannerText = data.iconTable.bannerText!;
      iconTable.textHighlight = data.iconTable.text_highlight!;
      iconTable.bannerLabel = data.iconTable.banner_label!;
      iconTable.subheaderText = data.iconTable.subheaderText!;
      iconTable.ctaEnabled = data.iconTable.cta_enabled!;
      iconTable.ctaText = data.iconTable.cta_text!;
      iconTable.ctaRedirection = data.iconTable.cta_redirection!;
      // iconTable.iconList = data.iconTable.iconList!

      iconList.value = data.iconTable.iconList!;

      simpleCallout.bannerText = data.simpleCallout.bannerText!;
      simpleCallout.textHighlight = data.simpleCallout.text_highlight!;
      simpleCallout.imagePanel = data.simpleCallout.imagePanel!;

      footerCallout.bannerText = data.footerCallout.bannerText!;
      footerCallout.textHighlight = data.footerCallout.text_highlight!;
      footerCallout.calloutImage = data.footerCallout.image!;
      footerCallout.ctaDetails = data.footerCallout.cta_details!;
    });

    const goToFeatureSpecific = (details: any, key: number) => {
      if (details.hasOwnProperty("inPageBanner")) {
        store.dispatch("feature_detail/setSelectedFeatureId", details._key);
        const slug = details.text.toLowerCase().replace(/\s+/g, "-");
        const url = "/feature-detail/" + key + "/" + slug;

        router.push(url);
      }
    };

    return {
      inPageBanner,
      callout1,
      threeColumn,
      carousel,
      callout2,
      iconTable,
      iconList,
      simpleCallout,
      footerCallout,
      parseSanityImage,
      goToFeatureSpecific,
    };
  },
};
</script>
