import FeatureCallout2 from "@/core/infrastructure/repository/feature/FeatureCallout2"
import hasData from "@/core/domain/specification/hasData"

export default class FeatureCallout2Service {
    protected featureCallout2: FeatureCallout2

    constructor() {
        this.featureCallout2 = new FeatureCallout2()
    }

    async process() {
        const { data, totalCount } = await this.featureCallout2.fetch()

        if (!hasData(totalCount)) {
            throw new TypeError("No data was provided")
        }

        return data
    }
}
