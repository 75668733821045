import { createStore } from "vuex"

import sample from "./modules/sample"
import navigation from "./modules/navigation"
import faq from "./modules/faq"
import footer_callout from "./modules/footer_callout"
import features from "./modules/home/features"
import site_setting from "./modules/site_setting"
import home_banner from "./modules/home/home_banner"
import consent_banner from "./modules/cookies/consent_banner"
import subscribe from "./modules/subscribe"
import feature_detail from "./modules/feature/feature_detail"
import blog_category from "./modules/blog/blog_category"
import blog_article from "./modules/blog/blog_article"

export default createStore({
    modules: {
        sample,
        navigation,
        faq,
        footer_callout,
        features,
        site_setting,
        home_banner,
        consent_banner,
        subscribe,
        feature_detail,
        blog_category,
        blog_article,
    },
})
