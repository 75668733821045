import QueryBuilder from "../../QueryBuilder"

export default class FaqItemRepository {
    constructor(protected contentName: string) {}

    async fetch() {
        const results = await new QueryBuilder()
            .buildQuery(this.contentName, ["faqList"])
            .process()

        return results.getResultInfo()
    }
}
