<template>
  <div>
    <section class="in-page-banner h-screen">
      <div class="section-container">
        <div
          class="py-[30px] lg:py-[100px] flex flex-col lg:flex-row items-center justify-center"
        >
          <div
            class="mb-[30px] lg:shrink-[2] lg:pr-[50px] lg:mb-0"
            v-motion
            :initial="{ opacity: 0, x: -50 }"
            :enter="{ opacity: 1, x: 0, scale: 1 }"
            :delay="200"
          >
            <p class="text-neutral-6">
              Contact us
            </p>

            <heading-with-highlights
              text="How can we help?"
              highlight="help?"
            />

            <div class="space-20px"></div>
            <p class="text-neutral-6">
              Want to get in touch? Fill out the form and we'll get back to you as soon as possible.
            </p>
          </div>

          <div class="lg:pl-5 w-full lg:w-[500px] xl:w-[650px]">
            <contact-form formOrigin="Demo Page" />
          </div>
        </div>
      </div>
    </section>

    <section class="bg-neutral-5 w-full -mt-[4px]">
      <div class="section-container py-[50px] lg:py-[80px]">
        <suspense>
          <three-column
            :bannerLabel="threeColumn.bannerLabel"
            :bannerText="threeColumn.bannerText"
            :textHighlight="threeColumn.textHighlight"
            :columnItems="threeColumn.columnItems"
            :ctaDetails="threeColumn.ctaDetails"
          />
        </suspense>
      </div>
    </section>

    <div class="bg-neutral-5 w-full py-[100px]">
      <div class="tabs-container mx-auto px-[25px] md:px-0 pb-[100px]">
        <transition enter-active-class="animate__animated animate__fadeIn">
          <tabs :items="tabItems">
            <template v-if="loading">
              <full-text-loading :lines="4"></full-text-loading>
            </template>
            <template v-else>
              <accordion :data="faqItems"></accordion>
            </template>
          </tabs>
        </transition>
      </div>
    </div>

    <!-- <section v-if="testimonial" class="bg-gradient-to-r from-primary-1 to-primary-3 pb-[130px] overflow-hidden -mt-[1px]">
            <img src="/public/images/reversed_content_wave_top_xl.png" class="w-full" alt="Podiem Website">
            <div class="section-container py-[100px]">
                <div class="grid grid-rows-1 lg:grid-cols-3 gap-y-4 items-center">
                    <heading-with-highlights
                        class="mb-[40px] lg:mb-0"
                        textLocation="pricing" 
                        :text="testimonial.bannerText"
                        :highlight="testimonial.texthighlight"
                    />
                    <div class="col-span-2">
                        <template v-if="testimonial.testimonialList.length !== 0">
                            <carousel-card 
                                ref="carouselCardRef" 
                                :interval="7000" 
                                :autoplay="false" 
                                trigger="click"
                                height="425px" 
                                type="card" 
                                @change="changeHandle"
                            >
                                <carousel-card-item v-for="(testimonial, i) in testimonial.testimonialList" :key="i" :name="`cc_${i}`">
                                    <div class="h-full flex flex-col max-w-[420px] rounded-2xl">
                                        <div class="rounded-t-2xl bg-primary-3 h-[270px] pt-[40px] px-[40px] text-center">
                                            <h2 class="mb-[10px] text-heading-md text-ellipsis overflow-hidden whitespace-nowrap">{{ testimonial.testimonialTitle }}</h2>
                                            <div v-if="testimonial.testimonialDescription" class="max-w-none w-full prose apercu-light testimonial-text">
                                                <sanity-blocks :blocks="[...testimonial.testimonialDescription]"></sanity-blocks>
                                            </div>
                                        </div>
                                        <div class="rounded-b-2xl bg-white h-[150px] flex items-start justify-center relative">
                                            <div class="w-[100px] h-[100px] bg-gray-100 rounded-full border-2 border-gray-300 overflow-hidden absolute -top-[50px]">
                                                <img 
                                                    :src="parseSanityImage(testimonial.authorDetails.imagePanel)"
                                                    :alt="testimonial.authorDetails.imagePanel!.alt"
                                                    class="w-full h-full object-cover"
                                                >
                                            </div>

                                            <div class="absolute top-[60px] text-center">
                                                <h3 class="text-heading-sm">{{ testimonial.authorDetails.authorName }}</h3>
                                                <p class="text-gray-400 apercu-light">{{ testimonial.authorDetails.authorJob }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </carousel-card-item>
                            </carousel-card>

                            <div class="flex items-center justify-center gap-x-3 py-[20px]">
                                <button-icon @click="prev()" themes="light">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                                    </svg>
                                </button-icon>
                                <button-icon @click="next()" themes="light">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                    </svg>
                                </button-icon>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </section> -->

    <section
      class="bg-gradient-to-r from-primary-1 to-primary-3 py-[130px] overflow-hidden -mt-[1px]"
    >
      <div class="section-container callout">
        <suspense>
          <call-out-block
            textLocation="normal"
            :calloutText="callout.calloutText"
            :calloutHighlight="callout.calloutHighlight"
            :calloutDescription="callout.callOutDescription"
            :calloutImage="callout.calloutImage"
            :isCTAEnabled="callout.ctaEnabled"
            :ctaText="callout.ctaText"
            :ctaRedirection="callout.ctaRedirection"
          />
        </suspense>
      </div>
    </section>
  </div>
</template>
<script lang="ts">
import { ref, reactive, onMounted, watch, computed, nextTick } from "vue";
import { useStore } from "vuex";
import { TAB_ITEMS } from "../js/BaseConstants";
import Accordion from "@/components/molecule/Accordion.vue";
import ButtonDefault from "@/components/atom/button/ButtonDefault.vue";
import ButtonIcon from "@/components/atom/button/ButtonIcon.vue";
import CallOutBlock from "@/components/molecule/CallOutBlock.vue";
import FullTextLoading from "@/components/atom/loading/FullTextLoading.vue";
import HeadingWithHighlights from "@/components/atom/text/HeadingWithHighlights.vue";
import SimpleBannerDescription from "@/components/atom/text/SimpleBannerDescription.vue";
import SimpleBannerTitle from "@/components/atom/text/SimpleBannerTitle.vue";
import SubscriptionCard from "@/components/molecule/SubscriptionCard.vue";
import Tabs from "@/components/molecule/Tabs.vue";
import ThreeColumn from "@/components/molecule/ThreeColumn.vue";
import ContactForm from "@/components/molecule/ContactForm.vue";

import { CarouselCard, CarouselCardItem } from "vue-carousel-card";
import { ICarouselCard } from "vue-carousel-card";
import "vue-carousel-card/styles/index.css";

import { parseSanityImage } from "@/js/composable/parseSanityImage";
import { SanityBlocks } from "sanity-blocks-vue-component";

import Pricing from "@/core/application/Pricing";
import FaqItemService from "@/core/application/service/faq/FaqItemService";
import PricingPageModel from "@/core/domain/model/PricingPageModel";

export default {
  name: "Pricing",
  components: {
    CarouselCard,
    CarouselCardItem,
    SanityBlocks,

    // Built-in components
    Accordion,
    ButtonDefault,
    ButtonIcon,
    CallOutBlock,
    FullTextLoading,
    HeadingWithHighlights,
    SimpleBannerDescription,
    SimpleBannerTitle,
    SubscriptionCard,
    Tabs,
    ThreeColumn,
    ContactForm,
  },
  setup() {
    const store = useStore();
    const carouselCardRef = ref<ICarouselCard>();
    const loading = ref(true);

    const changeHandle = (index: number) => {
      console.info(index);
    };
    const next = () => {
      carouselCardRef.value?.next();
    };
    const prev = () => {
      carouselCardRef.value?.prev();
    };

    const pageBanner = reactive({
      bannerText: "",
      subheaderText: "",
    });
    const subscription = reactive({
      subscriptionItems: [],
    });
    const ctaDetails = reactive({
      cta_enabled: false,
      cta_text: "",
      cta_redirection: "",
    });
    const threeColumn = reactive({
      bannerText: "",
      textHighlight: "",
      bannerLabel: "",
      ctaDetails: {},
      columnItems: {},
    });
    const testimonial = reactive({
      bannerText: "",
      texthighlight: "",
      testimonialList: [],
    });
    const callout = reactive({
      calloutText: "",
      calloutHighlight: "",
      callOutDescription: "",
      calloutImage: {},
      ctaEnabled: false,
      ctaText: "",
      ctaRedirection: "",
    });
    const faqItems = computed(() => store.getters["faq/GET_faqItems"]);

    const pricing = new Pricing();
    pricing.getAllData().then((data: PricingPageModel) => {
      pageBanner.bannerText = data.inPageBanner.bannerText!;
      pageBanner.subheaderText = data.inPageBanner.subheaderText!;

      subscription.subscriptionItems = data.subscription!;

      ctaDetails.cta_enabled = data.ctaDetails.cta_enabled!;
      ctaDetails.cta_text = data.ctaDetails.cta_text!;
      ctaDetails.cta_redirection = data.ctaDetails.cta_redirection!;

      threeColumn.bannerText = data.threeColumn.bannerText!;
      threeColumn.textHighlight = data.threeColumn.text_highlight!;
      threeColumn.bannerLabel = data.threeColumn.banner_label!;
      threeColumn.ctaDetails = data.threeColumn.cta_details!;
      threeColumn.columnItems = data.threeColumn.column_items!;

      store.dispatch("faq/setFaqItems", data.faqItem);

      testimonial.bannerText = data.testimonial.bannerText!;
      testimonial.texthighlight = data.testimonial.text_highlight!;
      testimonial.testimonialList = data.testimonial.testimonialList!;

      callout.calloutText = data.callout.bannerText!;
      callout.calloutHighlight = data.callout.text_highlight!;
      callout.callOutDescription = data.callout.subheaderText!;
      callout.calloutImage = data.callout.image!;
      callout.ctaEnabled = data.callout.cta_enabled!;
      callout.ctaText = data.callout.cta_text!;
      callout.ctaRedirection = data.callout.cta_redirection!;
    });

    onMounted(async () => {
      setTimeout(() => {
        loading.value = false;
      }, 1000);
      watch(
        () => store.getters["faq/GET_activeSchemaId"],
        (value) => {
          loading.value = true;
          const faqService = new FaqItemService(value);
          const faqItems = faqService.process();
          faqItems.then((items: any) => {
            store.dispatch("faq/setFaqItems", items);
            setTimeout(() => {
              loading.value = false;
            }, 1000);
          });
        },
      );
      const setDefaultItem = () => {
        carouselCardRef.value?.setActiveItem(0);
      };

      await nextTick();
      setTimeout(() => {
        setDefaultItem();
      }, 1000);
    });

    return {
      tabItems: TAB_ITEMS,
      carouselCardRef,
      changeHandle,
      next,
      prev,
      pageBanner,
      subscription,
      ctaDetails,
      threeColumn,
      testimonial,
      callout,
      faqItems,
      parseSanityImage,
      loading,
    };
  },
};
</script>
<style scoped>
.testimonial-text {
  mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
}

.testimonial-text::v-deep(p) {
  height: 110px;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
}

.testimonial-text::v-deep(p):before {
  content: open-quote;
}

.testimonial-text::v-deep(p):after {
  content: close-quote;
}
</style>
