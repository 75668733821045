<template>
  <div class="mx-auto w-1/2 py-8">
    <h1 class="text-2xl border-b border-b-gray-200 pb-3 mb-5">Sample Posts</h1>
    <sample-post-card></sample-post-card>
  </div>
</template>

<script lang="ts">
import { onMounted, onUnmounted, ref } from "vue";
import { useStore } from "vuex";
import { Subscription } from "rxjs";
import sanity from "../client";

// import SamplePostCard from '@/components/molecule/SamplePostCard.vue'
import SamplePostCard from "../components/molecule/SamplePostCard.vue";

export default {
  name: "Sample",
  components: {
    SamplePostCard,
  },
  setup() {
    const subscription = ref(null);
    const store = useStore();

    onMounted(async () => {
      const query =
        "*[_type == 'post']{title, excerpt, 'image': mainImage.asset->url, 'author': author->}";

      await sanity.fetch(query).then((posts) => {
        store.dispatch("sample/setPosts", posts);
      });

      Subscription.value = sanity.listen(query).subscribe((update) => {
        console.info(update.result);
      });
    });

    onUnmounted(() => {
      Subscription.value.unsubscribe();
    });
  },
};
</script>
