<template>
  <div>
    <div class="flex flex-wrap gap-y-5">
      <div v-for="(post, i) in posts" class="w-full md:w-1/3 px-4">
        <div
          class="bg-white rounded-lg overflow-hidden mb-10 border hover:shadow-md cursor-pointer h-full"
        >
          <img
            :src="imageUrlFor(post.image).width(480)"
            alt="image"
            class="w-full"
          />
          <div class="p-8 sm:p-9 md:p-7 xl:p-9 text-center">
            <h3>
              <a
                href="javascript:void(0)"
                class="font-semibold text-dark text-xl sm:text-[22px] md:text-xl lg:text-[22px] xl:text-xl 2xl:text-[22px] mb-4 block hover:text-primary"
              >
                {{ post.title }}
              </a>
            </h3>
            <p class="text-base text-body-color leading-relaxed mb-7">
              {{ post.excerpt }}
            </p>
            <a
              href="javascript:void(0)"
              class="inline-block py-2 px-7 border border-[#E5E7EB] rounded-full text-base text-body-color font-medium hover:border-primary hover:bg-primary hover:text-blue-500 transition"
            >
              View Details
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed } from "vue";
import { useStore } from "vuex";
import sanity from "@/client.ts";
import imageUrlBuilder from "@sanity/image-url";

const imageBuilder = imageUrlBuilder(sanity);

export default {
  name: "SamplePostCard",
  methods: {
    imageUrlFor(source) {
      return imageBuilder.image(source);
    },
  },
  setup() {
    const store = useStore();

    return {
      posts: computed(() => store.getters["sample/posts"]),
    };
  },
};
</script>

<style scoped></style>
