/* eslint-disable @typescript-eslint/no-unsafe-call */
const state = () => ({
  articles: {},
  filteredArticles: {},
});

const actions = {
  setArticles({ commit }: any, data: object) {
    commit("SET_ARTICLES", data);
  },
  setFilteredArticles({ commit }: any, data: object) {
    commit("SET_FILTERED_ARTICLES", data);
  },
};

const mutations = {
  SET_ARTICLES(state: { articles: object }, payload: object) {
    state.articles = payload;
  },
  SET_FILTERED_ARTICLES(state: { filteredArticles: object }, payload: object) {
    state.filteredArticles = payload;
  },
};

const getters = {
  GET_articles: (state: { articles: object }) => state.articles,
  GET_filteredArticles: (state: { filteredArticles: object }) =>
    state.filteredArticles,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
