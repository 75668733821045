import FaqItemRepository from "@/core/infrastructure/repository/faq/FaqItemRepository"
import hasData from "@/core/domain/specification/hasData"

export default class FaqItemService {
    constructor(public faqItem: string = "faq_pricing") {}

    async process() {
        const faqItemRepository = new FaqItemRepository(this.faqItem)
        const { data, totalCount } = await faqItemRepository.fetch()

        if (!hasData(totalCount)) {
            throw new TypeError("No data was provided")
        }

        return data
    }
}
