<template>
  <div class="min-h-screen">
    <header>
      <slot name="header"></slot>
    </header>

    <main>
      <slot name="default"></slot>
    </main>

    <footer>
      <slot name="footer"></slot>
    </footer>
  </div>
</template>
<script lang="ts">
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import SiteSettingService from "@/core/application/service/SiteSettingService";
import SiteSettingModel from "@/core/domain/model/SiteSettingModel";
import PodiemTextIconBlue from "../icons/PodiemTextIconBlue.vue";
import { FulfillingBouncingCircleSpinner } from "epic-spinners";

export default {
  name: "BaseLayout",
  components: {
    PodiemTextIconBlue,
    FulfillingBouncingCircleSpinner,
  },
  setup() {
    const store = useStore();
    const siteSetting = new SiteSettingService();
    const isPageLoading = computed(
      () => store.getters["site_setting/GET_isPageLoading"],
    );
    store.dispatch("navigation/toggleFooterVisibilityStatus", true);

    onMounted(async () => {
      await siteSetting.process().then((data: SiteSettingModel) => {
        const siteSetting = {
          business_phone_number: data.business_phone_number! ?? "",
          copyright_text: data.copyright_text! ?? "",
          facebook_url: data.facebook_url! ?? "",
          instagram_url: data.instagram_url! ?? "",
          linkedin_url: data.linkedin_url! ?? "",
        };
        store.dispatch("site_setting/setSiteSettingInfo", siteSetting);
      });
    });

    return {
      isPageLoading,
    };
  },
};
</script>
<style scoped></style>
