import ContactFooterCallout from "@/core/infrastructure/repository/contact/ContactFooterCallout"
import hasData from "@/core/domain/specification/hasData"

export default class ContactFooterCalloutService {
    protected contactFooterCallout: ContactFooterCallout

    constructor() {
        this.contactFooterCallout = new ContactFooterCallout()
    }

    async process() {
        const { data, totalCount } = await this.contactFooterCallout.fetch()

        if (!hasData(totalCount)) {
            throw new TypeError("No data was provided")
        }

        return data
    }
}
