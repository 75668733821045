import DemoInPageBannerSimple from "@/core/infrastructure/repository/demo/DemoInPageBannerSimple"
import hasData from "@/core/domain/specification/hasData"

export default class DemoInPageBannerSimpleService {
    protected demoInPageBannerSimple: DemoInPageBannerSimple

    constructor() {
        this.demoInPageBannerSimple = new DemoInPageBannerSimple()
    }

    async process() {
        const { data, totalCount } = await this.demoInPageBannerSimple.fetch()

        if (!hasData(totalCount)) {
            throw new TypeError("No data was provided")
        }

        return data
    }
}
