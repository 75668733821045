import FeatureIconTable from "@/core/infrastructure/repository/feature/FeatureIconTable"
import hasData from "@/core/domain/specification/hasData"

export default class FeatureIconTableService {
    protected featureIconTable: FeatureIconTable

    constructor() {
        this.featureIconTable = new FeatureIconTable()
    }

    async process() {
        const { data, totalCount } = await this.featureIconTable.fetch()

        if (!hasData(totalCount)) {
            throw new TypeError("No data was provided")
        }

        return data
    }
}
