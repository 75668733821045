import QueryBuilder from "../QueryBuilder"

export default class ImagePanelRepository {
    constructor(protected contentName: string) {}

    async fetch() {
        const results = await new QueryBuilder()
            .buildQuery(this.contentName, ["imagePanel", "image_alt"])
            .process()

        return results.getResultInfo()
    }
}
