import FeatureThreeColumn from "@/core/infrastructure/repository/feature/FeatureThreeColumn"
import hasData from "@/core/domain/specification/hasData"

export default class FeatureThreeColumnService {
    protected featureThreeColumn: FeatureThreeColumn

    constructor() {
        this.featureThreeColumn = new FeatureThreeColumn()
    }

    async process() {
        const { data, totalCount } = await this.featureThreeColumn.fetch()

        if (!hasData(totalCount)) {
            throw new TypeError("No data was provided")
        }

        return data
    }
}
