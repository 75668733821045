import PricingTestimonial from "@/core/infrastructure/repository/pricing/PricingTestimonial"
import hasData from "@/core/domain/specification/hasData"

export default class PricingTestimonialService {
    pricingTestimonial: PricingTestimonial

    constructor() {
        this.pricingTestimonial = new PricingTestimonial()
    }

    async process() {
        const { data, totalCount } = await this.pricingTestimonial.fetch()

        if (!hasData(totalCount)) {
            throw new TypeError("No data was provided")
        }

        return data
    }
}
