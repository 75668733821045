<template>
  <svg
    width="151"
    height="100"
    viewBox="0 0 151 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1314_15028)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M39.9111 26.1913H64.8472L81.967 91.4435H57.0655L39.9111 26.1913Z"
        fill="#0089FD"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M101.509 -0.0695648H125.338L125.269 99.687H101.439L101.509 -0.0695648Z"
        fill="#0089FD"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M39.9113 26.1913H64.8474L25.6275 99.687H0.691406L39.9113 26.1913Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M100.678 -0.0695648H125.545L81.864 91.4435H56.9971L100.678 -0.0695648Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M125.718 53.1478H149.893L125.683 99.687H101.508L125.718 53.1478Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_1314_15028">
        <rect width="151" height="100" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script lang="ts">
export default {
  name: "PodiemIcon",
};
</script>

<style scoped></style>
