<template>
  <div
    v-if="isOpenConsentBanner"
    class="shadow-2xl fixed bottom-0 left-0 right-0 z-50"
    v-motion
    :initial="{
      y: 100,
      opacity: 0,
    }"
    :enter="{
      y: 0,
      opacity: 1,
      transition: {
        y: {
          duration: 800,
        },
        opacity: {
          duration: 1200,
        },
      },
    }"
  >
    <consent-banner-customize />
    <consent-cookie />
  </div>
</template>
<script lang="ts">
import { onMounted, computed } from "vue";
import { useStore } from "vuex";
import ConsentCookie from "./ConsentCookie.vue";
import ConsentBannerCustomize from "./ConsentBannerCustomize.vue";

export default {
  name: "ConsentBanner",
  components: {
    ConsentCookie,
    ConsentBannerCustomize,
  },
  setup() {
    const store = useStore();
    const isOpenConsentBanner = computed(
      () => store.getters["consent_banner/GET_isOpenConsentBannerStatus"],
    );

    onMounted(() => {
      if (localStorage.getItem("cookie-consent") === null) {
        store.dispatch("consent_banner/openConsentBanner");
      } else {
        store.dispatch("consent_banner/closeConsentBanner");
      }
    });

    return {
      isOpenConsentBanner,
    };
  },
};
</script>
