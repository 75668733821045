import QueryBuilder from "../QueryBuilder"

export default class FooterCalloutRepository {
    constructor(protected contentName: string) {}

    async fetch() {
        const results = await new QueryBuilder()
            .rawQuery(
                `
                *[_id == '${this.contentName}'][0] {
                    bannerText,
                    text_highlight,
                    'image': imagePanel,
                    'cta_details': {
                        cta_enabled,
                        cta_text,
                        cta_redirection
                    }
                }
            `,
            )
            .process()

        return results.getResultInfo()
    }
}
