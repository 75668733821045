/* eslint-disable @typescript-eslint/no-unsafe-call */
const state = () => ({
  isOpenConsentBanner: false,
  isOpenCustomConsent: false,
  analyticsCheckbox: true,
});

const actions = {
  openConsentBanner({ commit, state }: any) {
    commit("SET_CONSENT_BANNER_STATUS", true);
  },
  closeConsentBanner({ commit, state }: any) {
    commit("SET_CONSENT_BANNER_STATUS", false);
  },
  toggleCustomConsentStatus({ commit, state }: any) {
    commit("SET_CUSTOM_CONSENT_STATUS", !state.isOpenCustomConsent);
  },
  toggleAnalyticsCheckboxStatus({ commit, state }: any) {
    commit("SET_ANALYTICS_CHECKBOX_STATUS", !state.analyticsCheckbox);
  },
};

const mutations = {
  SET_CONSENT_BANNER_STATUS(
    state: { isOpenConsentBanner: boolean },
    payload: boolean,
  ) {
    state.isOpenConsentBanner = payload;
  },
  SET_CUSTOM_CONSENT_STATUS(
    state: { isOpenCustomConsent: boolean },
    payload: boolean,
  ) {
    state.isOpenCustomConsent = payload;
  },
  SET_ANALYTICS_CHECKBOX_STATUS(
    state: { analyticsCheckbox: boolean },
    payload: boolean,
  ) {
    state.analyticsCheckbox = payload;
  },
};

const getters = {
  GET_isOpenConsentBannerStatus: (state: { isOpenConsentBanner: boolean }) =>
    state.isOpenConsentBanner,
  GET_isOpenCustomConsentStatus: (state: { isOpenCustomConsent: boolean }) =>
    state.isOpenCustomConsent,
  GET_isOpenAnalyticsCheckboxStatus: (state: { analyticsCheckbox: boolean }) =>
    state.analyticsCheckbox,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
