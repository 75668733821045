/* eslint-disable @typescript-eslint/no-unsafe-call */
import { FeatureItemModel } from "@/store/model/FeatureItemModel";

const state = () => ({
  activeFeatureTab: 0,
  featureItems: {},
});

const actions = {
  setActiveTab({ commit }: any, tabIndex: number) {
    commit("SET_ACTIVE_FEATURE_TAB", tabIndex);
  },
  setFeatureItems({ commit }: any, featureItem: object) {
    commit("SET_FEATURE_ITEMS", featureItem);
  },
};

const mutations = {
  SET_ACTIVE_FEATURE_TAB(state: { activeFeatureTab: number }, payload: number) {
    state.activeFeatureTab = payload;
  },
  SET_FEATURE_ITEMS(state: { featureItems: object }, payload: object) {
    state.featureItems = payload;
  },
};

const getters = {
  GET_activeFeatureTab: (state: { activeFeatureTab: number }) =>
    state.activeFeatureTab,
  GET_featureItem: (state: { featureItems: object }) => state.featureItems,
  GET_activeFeatureItem: (state: {
    activeFeatureTab: number;
    featureItems: FeatureItemModel;
  }) => {
    switch (state.activeFeatureTab) {
      case 0:
        return state.featureItems.xdrs;
      case 1:
        return state.featureItems.sales;
      case 2:
        return state.featureItems.executives;
      default:
        return state.featureItems.xdrs;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
