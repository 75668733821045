<template>
  <div
    @mouseenter="stopAnimation()"
    @mouseleave="startAnimation()"
    ref="scrollWrapper"
    class="card-container"
  >
    <div class="card" v-for="(item, id) in items" :key="id">
      <img
        :src="parseSanityImage(item.imagePanel)"
        :alt="item.imagePanel.alt"
        class="mb-[40px]"
      />
      <short-border>
        <h2 class="text-heading-lg mb-[20px] apercu-bold">
          {{ item.contentTitle }}
        </h2>
        <template v-if="item.simpleBlockContent">
          <p
            v-for="(value, id) in item.simpleBlockContent"
            :key="id"
            class="text-heading-xs text-neutral-2 apercu-light prose"
          >
            <sanity-blocks :blocks="[value]"></sanity-blocks>
          </p>
        </template>
      </short-border>
    </div>
  </div>
</template>
<script lang="ts">
import _ from "lodash";
import { toRefs, ref, Ref, onMounted } from "vue";
import ShortBorder from "@/components/atom/text/ShortBorder.vue";
import { parseSanityImage } from "@/js/composable/parseSanityImage";
import { SanityBlocks } from "sanity-blocks-vue-component";

export default {
  name: "CarouselItem",
  components: {
    ShortBorder,
    SanityBlocks,
  },
  props: {
    items: {
      type: Object,
      default: {},
    },
  },
  setup(props: any) {
    const { items } = toRefs(props);
    const scrollWrapper: Ref<null | HTMLDivElement> = ref(null);
    let scrollAmount = ref(0);
    let animating: any = null;
    let resetTimer: any = null;

    const scrollLeft = () => {
      scrollWrapper.value?.scrollTo({
        left: (scrollAmount.value += 2),
        behavior: "smooth",
      });
    };

    const startAnimation = _.debounce(async () => {
      activateAnimation();
    }, 3000);

    const activateAnimation = () => {
      if (!animating) {
        animating = setInterval(() => {
          scrollLeft();
        }, 100);

        resetTimer = setInterval(() => {
          scrollAmount.value = 0;
        }, 30000);
      }
    };

    const stopAnimation = () => {
      clearInterval(animating);
      clearInterval(resetTimer);
      animating = null;
      resetTimer = null;
    };

    onMounted(async () => {
      startAnimation();
    });

    return {
      items,
      parseSanityImage,
      scrollWrapper,
      startAnimation,
      stopAnimation,
    };
  },
};
</script>
<style scoped>
.card-container > * + * {
  margin-top: 1.25rem;
}
.card-container {
  @apply mt-[20px] lg:mt-0 flex flex-col lg:flex-row lg:overflow-auto lg:pr-[40px] lg:shrink-[2];
  display: -webkit-box;
  display: -moz-box;
  width: fit-content;
  padding-block: 25px;
}

.card-container::-webkit-scrollbar {
  /* display: none; */
}

.card {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 70px 35px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 12px 15px rgba(0, 0, 0, 0.12);
  border-radius: 30px;
}

@media only screen and (min-width: 1024px) {
  .card-container > * + * {
    margin-top: 0;
    margin-left: 1.25rem;
  }
  .card {
    width: 500px;
  }
}
</style>
